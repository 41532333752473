import { Component, EventEmitter, forwardRef, Injectable, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

import { EntryAbstract } from '../entry.abstract';
import { CoreTransfer, TreeNode, TreeRelationship } from '../../../../core/interface/core.interface';
import { NODES_TYPE_CATEGORY, NODES_TYPE_CONNECT, NODES_TYPE_DATE, NODES_TYPE_END, NODES_TYPE_FIELDS, NODES_TYPE_FORM_TAB, NODES_TYPE_IGNORE, NODES_TYPE_NODETYPEGROUP, NODES_TYPE_PROJECT, NODES_TYPE_START, NODES_TYPE_VALUE, NODES_TYPE_VERSION } from '../../../../shared/api/nodes/nodes.models';
import { Datum } from '../../../../shared/utilities/datum';
import { Map, Set } from 'immutable';
import { UUID } from 'angular2-uuid';
import { FormService } from '../../service/form.service';
import { isString } from 'util';
import { Traverser } from '../../../../services/traverser/traverser';
import { CoreService } from '../../../../core/service/core.service';
import { CoreUtilities } from '../../../../core/utilities/core.utilities';
import { IPayload } from '../../../../services/payload/payload.interface';

@Component({
  selector: 'app-form-distribution',
  templateUrl: 'distribution.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DistributionComponent),
      multi: true
    }
  ]
})

@Injectable()
export class DistributionComponent extends EntryAbstract implements OnInit {

  @Input() formNode: TreeNode;
  @Input() treeNode: TreeNode;
  @Input() form: FormGroup;

  @Output() change = new EventEmitter();
  @Output() valid = new EventEmitter<boolean>();
  @Output() changedSubmit = new EventEmitter<{ text: string, callback: Function, callbackScope: any }>();

  /* Configuration */
  public startDateNode: TreeNode;
  public endDateNode: TreeNode;
  public allowedMonths = Set<number>();
  public holidays = Set<string>();
  public countNode: TreeNode;
  public typeNode: TreeNode;
  public nodeType = 0;
  public peripheryNode: TreeNode;
  public nodeDataNode: TreeNode;

  /* Data */
  public startDate: Date;
  public endDate: Date;
  public count: number;
  public selectedType: string;
  public selectedTiming: string;
  public selectedNode: TreeNode;
  public selectedTypeNode: TreeNode;
  public selectedTimingFrameNode: TreeNode;
  public selectedTimingNode: TreeNode;
  public timingCount: number;

  /* Setup */
  public readonly = false;
  public setup: { startDate: Date, endDate: Date, count: number, type: string, timing: string, timingCount: number } = { startDate: undefined, endDate: undefined, type: undefined, count: undefined, timing: undefined, timingCount: undefined };

  /* Core service */
  public coreService: CoreService;

  public constructor(protected formService: FormService, protected coreUtilities: CoreUtilities) {
    super();
  }

  public writeValue(value: any) {}

  public onStartDateChanged(date: any) {
    this.startDate = date;
    this.setup.startDate = date;
    this.updateValidity();
  }

  public onEndDateChanged(date: any) {
    this.endDate = date;
    this.setup.endDate = date;
    this.updateValidity();
  }

  public onCountChanged(countEvent: any) {
    this.count = parseInt(countEvent.target.value);
    this.setup.count = this.count;
    this.updateValidity();
  }

  public onTimingChanged(timingEvent: any) {
    this.timingCount = parseInt(timingEvent.target.value);
    this.setup.timingCount = this.timingCount;
    this.updateValidity();
  }

  public onTypeSelectedEvent(selectedEvent: any) {
    this.onTypeSelected(selectedEvent.target.value);
    this.updateValidity();
  }

  protected onTypeSelected(selected: string) {
    this.selectedTimingNode = undefined;
    if (selected === '') {
      this.selectedTypeNode = undefined;
      this.setup.type = undefined;
    } else {
      this.setup.type = selected;
      const children = this.typeNode.children;
      const count = children.length;
      for (let i = 0; i < count; i++) {
        const child = children[i];
        if (child.formId === selected) {
          this.selectedNode = child;
          this.selectedTypeNode = child.children[0];
        }
      }
    }
  }

  public onTimingSelectedEvent(selectedEvent: any) {
    this.onTimingSelected(selectedEvent.target.value);
    this.updateValidity();
  }

  public onTimingSelected(selected: string) {
    if (selected === '') {
      this.selectedTimingNode = undefined;
      this.selectedTimingFrameNode = undefined;
      this.setup.timing = undefined;
    } else {
      this.setup.timing = selected;
      const children = this.selectedTypeNode.children;
      const count = children.length;
      for (let i = 0; i < count; i++) {
        const child = children[i];
        if (child.formId === selected) {
          this.selectedTimingFrameNode = child;
          this.selectedTimingNode = child.children[0];
        }
      }
    }
  }

  public ngOnInit(): void {

    /* Set the configuration */
    const children = this.formNode.children;
    const count = children.length;
    for (let i = 0; i < count; i++) {
      const child = children[i];
      switch (child.nodeType) {
        case NODES_TYPE_START:
          this.startDateNode = child;
          const start = this.treeNode[this.startDateNode.formFieldId];
          if (start !== undefined && start !== null) {
            this.startDate = new Date(start);
          }
          break;
        case NODES_TYPE_END:
          this.endDateNode = child;
          const end = this.treeNode[this.endDateNode.formFieldId];
          if (end !== undefined && end !== null) {
            this.endDate = new Date(end);
          }
          break;
        case NODES_TYPE_DATE:
          this.allowedMonths = Set(child.children.map(d => new Datum(d.startDate).month));
          break;
        case NODES_TYPE_IGNORE:
          const dates = this.coreUtilities.unescapeHtml(child.description).split(',');
          const holidaysCount = dates.length;
          for (let ih = 0; ih < holidaysCount; ih++) {
            this.holidays = this.holidays.add(new Datum(dates[ih]).toDateString());
          }
          break;
        case NODES_TYPE_VALUE:
          this.countNode = child;
          break;
        case NODES_TYPE_VERSION:
          this.typeNode = child;
          break;
        case NODES_TYPE_FORM_TAB:
          this.changedSubmit.emit({ text: child.name, callback: this.onSubmit, callbackScope: this });
          break;
        case NODES_TYPE_NODETYPEGROUP:
          this.nodeType = child.children.length > 0 ? child.children[0].nodeType : 0;
          break;
        case NODES_TYPE_CONNECT:
          this.peripheryNode = child;
          break;
        case NODES_TYPE_FIELDS:
          this.nodeDataNode = child;
          break;
      }
    }

    /* Get the setup */
    if (this.treeNode.sync !== '') {
      try {
        const setup = JSON.parse(this.treeNode.sync);
        if (setup !== undefined && setup !== null) {
          this.setup = setup;
          this.readonly = this.treeNode.children.filter(child => child.nodeType === this.nodeType).length > 0;
        }
      } catch (e) {}
    }
    /* Set the setup */
    this.setSetup();

    /* Check if valid */
    this.updateValidity();
  }

  public onSubmit(callback: any): void {
    /* The transfer */
    const transfer = this.coreUtilities.getTransfer(this.treeNode.modelId);

    /* Dates */
    let dates = [];

    /* Get the valid days */
    switch (this.selectedNode.formId) {
      case 'manually':
        /* First valid start date */
        dates = this.sortInto(this.selectedTimingFrameNode.formId, this.getValidDay(new Datum(this.startDate)));
        break;
      case 'automatic':
        /* Get the valid days */
        const validDays = this.getValidDaysInTimeframe();
        const calc1 = Math.floor(validDays.length / this.count);
        const count = validDays.length;
        for (let i = 0; i < count; i++) {
          if (i % calc1 === 0 && dates.length < this.count) {
            dates.push(validDays[i]);
          }
        }
        break;
    }

    /* Get further data */
    const data = this.getAdditionalData();

    /* Get the periphery */
    const { parents, children } = this.getPeriphery();

    /* Create nodes with dates set */
    const nodesCount = dates.length;
    for (let i = 0; i < nodesCount; i++) {

      /* Set the date */
      const date = dates[i];

      /* Create a node */
      const node = Object.assign({
        id: UUID.UUID(),
        name: this.buildTheName(i + 1),
        nodeType: this.nodeType
      }, data) as TreeNode;

      /* Add start date */
      node[this.startDateNode.formFieldId] = new Datum(date).toISOString(true);

      /* Add end date */
      node[this.endDateNode.formFieldId] = new Datum(date).toISOString(true);

      /* Connect to initial node */
      (transfer.create.relationships as TreeRelationship[]).push({ id: UUID.UUID(), parentId: this.treeNode.id, childId: node.id } as TreeRelationship);

      /* Connect to periphery */
      let count = parents.length;
      for (let p = 0; p < count; p++) {
        (transfer.create.relationships as TreeRelationship[]).push({ id: UUID.UUID(), parentId: parents[p].id, childId: node.id } as TreeRelationship);
      }
      count = children.length;
      for (let c = 0; c < count; c++) {
        (transfer.create.relationships as TreeRelationship[]).push({ id: UUID.UUID(), parentId: node.id, childId: children[c].id } as TreeRelationship);
      }

      /* Add to nodes array */
      (transfer.create.nodes as TreeNode[]).push(node);
    }

    /* Update the node */
    (transfer.update.nodes as IPayload[]).push({ id: this.treeNode.dataId, data: Map().set('sync', JSON.stringify(this.setup)) });

    /* Return the value */
    callback(transfer);
  }

  protected setSetup() {
    if (this.setup === undefined || this.setup === null) {
      return;
    }
    if (this.setup.startDate !== undefined) {
      this.startDate = this.setup.startDate;
    }
    if (this.setup.endDate !== undefined) {
      this.endDate = this.setup.endDate;
    }
    if (this.setup.count !== undefined) {
      this.count = this.setup.count;
    }
    if (this.setup.type !== undefined) {
      this.selectedType = this.setup.type;
      this.onTypeSelected(this.setup.type);
    }
    if (this.setup.timing !== undefined) {
      this.selectedTiming = this.setup.timing;
      this.onTimingSelected(this.setup.timing);
    }
    if (this.setup.timingCount !== undefined) {
      this.timingCount = this.setup.timingCount;
    }
  }

  protected updateValidity() {
    /* Set values */
    this.treeNode[this.countNode.formFieldId] = this.count;

    /* Emit valid event */
    this.valid.emit(!this.readonly &&
      this.startDate !== undefined &&
      this.count !== undefined &&
      this.selectedNode !== undefined &&
      (this.selectedNode.formId === 'automatic' || (this.selectedNode.formId === 'manually' && this.timingCount !== undefined)) &&
      (this.selectedNode.formId !== 'automatic' || this.endDate !== undefined)
    );
  }

  public getValidDaysInTimeframe(_startDate = this.startDate, _endDate = this.endDate) {
    /* Get all valid days */
    const validDays = [];

    /* Set start and end date */
    const startDate = new Datum(_startDate);
    const endDate = new Datum(_endDate);

    /* Iterate over the days */
    while (startDate.lteq(endDate)) {

      /* Filter */
      if (this.isDayValid(startDate)) {
        validDays.push(startDate.date);
      }

      startDate.addDays(1);
    }

    /* Return */
    return validDays;
  }

  public getValidDay(day: Datum, checkMonths = true, negative = false): Datum {
    if (this.isDayValid(day, checkMonths)) {
      return day;
    }
    return this.getValidDay(day.addDays(negative ? -1 : 1), checkMonths, negative);
  }

  public isDayValid(day: Datum, checkMonths = true) {
    return (!checkMonths || this.allowedMonths.has(day.month)) && !this.holidays.has(day.toDateString()) && (day.date.getDay() !== 6 && day.date.getDay() !== 0);
  }

  public sortInto(type: string, start: Datum, result: Date[] = []) {
    /* Number */
    let number = 0;
    switch (type) {
      case 'week':
        number = start.week;
        break;
      case 'month':
        number = start.month;
        break;
    }
    /* Get the end */
    const endOf = this.getEnd(type, number, start.clone());
    /* Get the days */
    const validDays = this.getValidDaysInTimeframe(start.date, endOf.date);
    const calc1 = Math.floor(validDays.length / this.timingCount);
    const count = validDays.length;
    let current = 0;
    for (let i = 0; i < count; i++) {
      if (i % calc1 === 0 && current < this.timingCount && result.length < this.count) {
        result.push(validDays[i]);
        current++;
      }
    }
    if (result.length < this.count) {
      return this.sortInto(type, endOf.addDays(1), result);
    }
    return result;
  }

  protected getEnd(type: string, number: number, day: Datum): Datum {
    switch (type) {
      case 'week':
        if (day.clone().addDays(1).week !== number) {
          return day;
        }
        return this.getEnd(type, number, day.addDays(1));
      case 'month':
        if (day.clone().addDays(1).month !== number) {
          return day;
        }
        return this.getEnd(type, number, day.addDays(1));
    }
  }

  protected getAdditionalData(): TreeNode {

    const node = {} as TreeNode;

    /* Add the data */
    const children = this.nodeDataNode.children;
    const count = children.length;
    for (let i = 0; i < count; i++) {
      const dataNode = children[i];
      /* Get the value from selected node */
      let value: any;
      if (dataNode.formFieldControlType === 'calculated') {
        value = this.formService.getCalculatedValue(dataNode, this.treeNode);
        if (isString(value)) {
          value = parseFloat(value);
        }
      } else {
        value = this.formService.getValue(dataNode, this.treeNode);
      }
      /* Set the value */
      node[dataNode.formFieldId] = value;
    }

    /* Return the node */
    return node;
  }

  protected getPeriphery() {

    let parents: TreeNode[] = [];
    let children: TreeNode[] = [];

    const nodeChildren = this.peripheryNode.children;
    const count = nodeChildren.length;
    for (let i = 0; i < count; i++) {
      const peripheryNode = nodeChildren[i];
      const peripheryNodes = new Traverser().addToLanes(peripheryNode.children).setRelationshipWeights(this.coreService.getRelationshipWeights()).run([this.treeNode]);

      if (peripheryNode.formFieldId === 'dynamic-parents') {
        parents = parents.concat(peripheryNodes);
      } else {
        children = children.concat(peripheryNodes);
      }

    }

    /* Return the node */
    return { parents, children };
  }

  protected buildTheName(i: number) {
    let name = '' + i + '.';

    /* Project */
    const projects = new Traverser().addToLane(this.treeNode.nodeType).addToLane(NODES_TYPE_PROJECT, true).setRelationshipWeights(this.coreService.getRelationshipWeights()).run([this.treeNode]);
    if (projects.length > 0) {
      name += ' ' + projects[0].name;
    }

    /* Element */
    name += ' ' + this.treeNode.name;

    /* Project */
    const subcategories = new Traverser().addToLane(this.treeNode.nodeType).addToLane(NODES_TYPE_CATEGORY, true).setRelationshipWeights(this.coreService.getRelationshipWeights()).run([this.treeNode]);
    if (subcategories.length > 0) {
      name += ' - ' + subcategories[0].name;
    }

    /* Return name */
    return name;
  }

}
