import { FormFieldDropdownValue, FormFieldShape, SequenceElement, ChatGPTConversation, CoreField, PeripheryToUpdate, TransferOptions, AIServices, GetDocumentsResponse, CoreWidget, ExternalInput } from './../interface/core.interface';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { isArray, isBoolean, isNullOrUndefined, isNumber, isObject, isString } from 'util';
import { List, Map, OrderedMap, Set } from 'immutable';
import { ComponentRef, EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { UUID } from 'angular2-uuid';

import {
  BusinessareaService,
  ModelService,
  HumanResourceService,
  NodeService,
  RelationshipService,
  Businessarea,
  Model,
  HumanResource,
  Node,
  Relationship,
  ActivityService,
  Activity,
  Subset,
  NodeCreate,
  RelationshipCreate,
  RelationshipAction,
  NodeStructureService,
  NodeStructureAction,
  NodeDataService,
  BUSINESSAREA_TYPE_MODULE,
  ModelAction,
  UserService,
  User,
  BusinessareaAction,
  NodeDataAction,
  RequestDiffRecord,
  ActivityAction,
  HumanResourceAction,
  GroupService,
  GroupAction,
  Group,
  OneTimeTokenService,
  Email,
  EmailService, EmailAction, OneTimeTokenAction, NodeStructure, NodeData, UserAction
} from '../../shared';
import { SubscriptionService } from '../../shared/utilities/subscription';
import {
  TreeRelationship,
  TreeNode,
  TreeActivity,
  CoreNodeTypes,
  CoreLabelProvider,
  CoreModel,
  CoreHumanResource,
  CoreMultiTransfer,
  CoreGroup,
  CoreSocketData,
  CoreExportOptions,
  CoreAudit,
  CoreWidgetFilter,
  CoreWidgetFilterCheckbox,
  CoreGlobalFilter,
  ExportExcel,
  CoreWidgets,
  NodeFields,
  FormFieldControlType,
  FormFieldType,
  FormFieldCalculation
} from '../interface/core.interface';
import { CoreTransformer } from '../transformer/core.transformer';
import { CoreFilter, CoreLegend, CoreModule, CoreOptions, CoreTransfer, CoreTransferResult, CoreUser } from '../interface/core.interface';
import { ColorLabelProvider } from '../../services/colorlabelprovider/colorlabelprovider.service';
import { ColorLabelProviderAbstract } from '../../services/colorlabelprovider/providers/colorlabelprovider.service.abstract';
import { RELATIONSHIP_TYPE_DEFAULT } from '../../shared/api/relationships/relationships.models';
import {
  NodeGrouping,
  NODES_TYPE_ACTION,
  NODES_TYPE_ACTIONGROUP,
  NODES_TYPE_ADD, NODES_TYPE_ALTERNATIVE, NODES_TYPE_BY, NODES_TYPE_CHILD, NODES_TYPE_COLORLABELPROVIDER,
  NODES_TYPE_CONNECT, NODES_TYPE_DATASOURCE, NODES_TYPE_DATE,
  NODES_TYPE_DELETE, NODES_TYPE_DIRECT_CHAIN,
  NODES_TYPE_DISCONNECT, NODES_TYPE_END,
  NODES_TYPE_FIELD,
  NODES_TYPE_FIELDS,
  NODES_TYPE_FORM,
  NODES_TYPE_FORM_TAB, NODES_TYPE_FUNCTIONS,
  NODES_TYPE_GROUP,
  NODES_TYPE_HOMEACTION,
  NODES_TYPE_HUMANRESOURCE, NODES_TYPE_IGNORE, NODES_TYPE_LABEL,
  NODES_TYPE_MODEL,
  NODES_TYPE_MODULECONFIGURATION, NODES_TYPE_MY,
  NODES_TYPE_NEXT, NODES_TYPE_NODE, NODES_TYPE_NOT, NODES_TYPE_ONLY_ONE_STACK,
  NODES_TYPE_PARENT, NODES_TYPE_SEQUENCE,
  NODES_TYPE_SETUPACTION, NODES_TYPE_START, NODES_TYPE_TRAVERSER,
  NODES_TYPE_UPDATE, NODES_TYPE_VALUE, NODES_TYPE_WIDGET, NODES_TYPE_WIDGET_ROW,
  NODES_TYPE_WORKFLOW
} from '../../shared/api/nodes/nodes.models';
import { CoreUtilities } from '../utilities/core.utilities';
import { PayloadFactory } from '../../shared/api/shared/payload-factory';
import { Hierarchy, HierarchyAction, HierarchyService } from '../../shared/api/hierarchy';
import { INSTANCE_TYPE_LIBRARY } from '../../shared/api/instances/instances.models';
import { MODEL_TYPE_MCM, ModelCreate } from '../../shared/api/models/models.models';
import { IPayload } from '../../shared/api/shared';
import { WorkflowService } from '../../shared/api/workflow';
import { FormService as FormApiService } from '../../shared/api/form';
import { FormService } from '../../components/form/service/form.service';
import { Version, VersionAction, VersionService } from '../../shared/api/versions';
import { ExportService } from '../../shared/utilities/export-service';
import { FormScreenModalComponent } from '../../screens/form/modal/form.screen.modal';
import { AuditService } from '../../shared/api/audits';
import { CardModalComponent } from '../../components/card/modal/card.modal';
import { FormResult } from '../../components/form/interface/form.interface';
import { EisenhowerService } from '../../components/eisenhower/service/eisenhower.service';
import { NodesAndRelationshipsService } from '../../shared/api/nodes-relationships';
import { Datum } from '../../shared/utilities/datum';
import { AppGlobal } from '../../app.global';
import { GuardianAction, GuardianData, GuardianService } from '../../shared/api/guardian';
import { GuardianResponseData } from '../../shared/api/guardian/guardian.models';
import { HttpErrorResponse } from '@angular/common/http';
import { MyAction, MyService } from '../../shared/api/my';
import { AiAction, AiService } from '../../shared/api/ai';
import { Search, SearchResult, Similarity } from '../../ai/interface/ai.interface';
import { TreeAction, TreeService } from '../../shared/api/tree';
import { ByDateInterface } from '../../widgets/filter-byDate/interface/filter-bydate.widget.interface';
import isSet = Set.isSet;
import { NotificationsService } from '../../components/notifications/service/notifications.service';
import { Router } from '@angular/router';
import { CollectorService } from '../../services/collector/collector.service';
import { ImportAction, ImportService } from '../../shared/api/import';
import { ImportData } from '../../services/data/import.service';
import { AbstractCondition, Bracket, Condition, Operator } from '../../widgets/filter-byConditions/interface/filter-byconditions.widget.interface';
import { AIFindDocumentsPayload } from '../../ai/components/ai.findDocuments.component';
import { Traverser } from 'src/app/services/traverser/traverser';
import { JsonStructureData } from '../../widgets/database-connection/component/database-connection.widget.component';
import { BackendService } from '../../shared/api/connector';
import { GeneralAction, GeneralService } from '../../shared/api/general';
import { DocumentModalComponent } from '../../components/document/modal/document.modal';
import { GPTService } from '../../services/gpt/gpt.service';
import { environment } from '../../../environments/environment';
import { ErrorReporter } from 'src/app/error.reporter';
import { filter, take } from 'rxjs/operators';
import { DistributionComponent } from '../../components/form/entries/distribution/distribution.component';

@Injectable()
export class CoreService implements OnDestroy {

  public constructor(private businessAreaService: BusinessareaService,
                     private modelService: ModelService,
                     private humanResourceService: HumanResourceService,
                     private nodeService: NodeService,
                     private relationshipService: RelationshipService,
                     private nodesAndRelationshipService: NodesAndRelationshipsService,
                     private activityService: ActivityService,
                     private nodeStructureService: NodeStructureService,
                     private nodeDataService: NodeDataService,
                     private colorLabelProviderService: ColorLabelProvider,
                     private hierarchyService: HierarchyService,
                     private userService: UserService,
                     private groupService: GroupService,
                     private workflowService: WorkflowService,
                     private formApiService: FormApiService,
                     private formService: FormService,
                     private coreUtilities: CoreUtilities,
                     private coreTransformer: CoreTransformer,
                     private versionService: VersionService,
                     private oneTimeTokenService: OneTimeTokenService,
                     private exportService: ExportService,
                     private emailService: EmailService,
                     private eisenhowerService: EisenhowerService,
                     private auditService: AuditService,
                     private appGlobal: AppGlobal,
                     private guardianService: GuardianService,
                     private myService: MyService,
                     private treeService: TreeService,
                     private aiService: AiService,
                     private notificationService: NotificationsService,
                     private router: Router,
                     private collectorService: CollectorService,
                     private importService: ImportService,
                     private generalService: GeneralService,
                     private backendService: BackendService,
                     public gptService: GPTService,
                     private distributionComponent: DistributionComponent,
                     private errorReporter: ErrorReporter) {
    this.formService.coreService = this;
    this.coreUtilities.coreService = this;
    this.formService.eisenhowerService = this.eisenhowerService;
    this.notificationService.coreService = this;
    this.collector = this.collectorService;
    this.gptService.setCoreService(this);
    this.distributionComponent.coreService = this;

    let debounce: number;
    this.subscriptionService.add('token', AppGlobal.getToken.subscribe(() => {
      if (debounce !== undefined) {
        window.clearTimeout(debounce);
      }
      debounce = window.setTimeout(() => {
        /* Local Storage */
        const stored = localStorage.getItem('valueminer.mobile-token');
        if (stored === null) {
          this.getMobileToken(token => {
            AppGlobal.tokenObs.next(token);
          }, errors => {
            console.error(errors);
          });
        } else {
          AppGlobal.tokenObs.next(stored);
        }
        debounce = undefined;
      }, 500);
    }));
  }

  /**
   * Get the color label provider instance
   */
  public get colorLabelProviderInstance(): ColorLabelProviderAbstract {
    return this.colorLabelProvider;
  }

  /* Last loaded business area */
  public lastLoadedBusinessArea: string;

  /* Loading */
  public loading = true;

  public useGo = true;
  public useTreeNodes = true;
  public goUpdate = true;

  readonly minX = 70;
  readonly nodeMargin = 100;

  /* Collector */
  public collector: CollectorService;

  /* Public event emitters for global events */
  public openModal = new EventEmitter<string>();

  /* Public event emitters for global loading event */
  public globalLoading = new EventEmitter<boolean>();
  public hasGlobalLoading = false;

  /* Private node types */
  public privateNodeTypes = [];

  /* FormFieldIdMapping */
  public fieldsMapping = this.getFrontToBackMapping();

  /* Dynamic modal function */
  public dynamicModalFunction: (id: string, name: string, resolve: Function) => void;

  /* Unfiltered relationships */
  public treeNodes = new BehaviorSubject<OrderedMap<string, TreeNode>>(undefined);
  public unfilteredTreeRelationshipsObservable = new BehaviorSubject<OrderedMap<string, TreeRelationship>>(undefined);
  public treeRelationships = new BehaviorSubject<OrderedMap<string, TreeRelationship>>(undefined);

  /* The event emitters to register services on */
  private businessArea = new BehaviorSubject<Businessarea>(undefined);
  private models = new BehaviorSubject<OrderedMap<string, Model>>(undefined);
  private subModels = new BehaviorSubject<OrderedMap<string, Model>>(undefined);
  private subSets = new BehaviorSubject<OrderedMap<string, Subset>>(undefined);
  private humanResources = new BehaviorSubject<OrderedMap<string, CoreHumanResource>>(undefined);
  private groups = new BehaviorSubject<OrderedMap<string, CoreGroup>>(undefined);
  private nodes = new BehaviorSubject<OrderedMap<string, Node>>(undefined);
  private relationships = new BehaviorSubject<OrderedMap<string, Relationship>>(undefined);
  private relationshipWeights = new BehaviorSubject<OrderedMap<string, number>>(undefined);
  private activities = new BehaviorSubject<OrderedMap<string, Activity>>(undefined);
  private treeActivities = new BehaviorSubject<OrderedMap<string, TreeActivity>>(undefined);
  private hierarchy = new BehaviorSubject<OrderedMap<string, TreeNode>>(undefined);
  private colorLabelProviderKey = new BehaviorSubject<string>('');
  private modals = new BehaviorSubject<Map<string, any>>(undefined);

  protected revceivedIds = {};

  private modalComponents = Map<string, ComponentRef<any>>();

  private unfilteredTreeRelationships: OrderedMap<string, TreeRelationship>;

  private unChangedHumanResources = OrderedMap<string, CoreHumanResource>();
  private unChangedGroups = OrderedMap<string, CoreGroup>();

  private legend: CoreLegend[] = [];
  private filteredNodeTypes: number[] = [];

  /* Business area id */
  private businessAreaId: string;

  /* MCM */
  private mcm: string;
  private mcmModel: Model;

  /* Checksums */
  private businessAreaCheckSum = undefined;
  private modelsCheckSum = Map<string, string>();
  private humanResourcesCheckSum = undefined;
  private groupsCheckSum = undefined;
  private nodesCheckSum = undefined;
  private relationshipsCheckSum = undefined;
  private activitiesCheckSum = undefined;

  /* Color label provider */
  private colorLabelProvider: ColorLabelProviderAbstract;
  private selectedColorLabelProvider: string;

  /* Positions */
  private positions = Map<number, { min: number, max: number }>();

  /* Toggled legends */
  private toggledLegends: string[] = [];

  /* Subscription service */
  private subscriptionService = new SubscriptionService();

  /* Already called requests */
  private knownRequests = {};

  private isCreatedMap = Map<string, Function>();

  public clearFilter = new EventEmitter<any>();

  private failCallbacks = [];

  private auditMap = {};

  private humanResourcesNodes = {};

  private gfstructureEntryNodeIDs: string[] = [];

  private deletedActivities: string[] = [];
  private deletedNodes: string[] = [];

  private humanResourceHandling = false;
  private humanResourceHandlingParents = true;

  private filteredOutModels: string[] = [];

  private nodeFields = NodeFields;

  public transferInProgress = false;
  public updateAfterTransfer = {
    globalFilter: false,
    nodes: false,
    colorLabelProvider: false,
    relationships: undefined,
    activities: false
  };

  /* Transfers */
  protected afterTransferEmitter = new EventEmitter();
  protected lastTransfer: { create: CoreTransfer, update: CoreTransfer, delete: CoreTransfer };
  protected lastTransferExpectations: { nodeStructures: number, nodeData: number, relationships: number, activities: number };

  /* Responsibles that are created first and then should be updated at a node's responsible */
  protected createdResponsibles = {};

  /* Global filters */
  public globalFilters = OrderedMap<string, CoreGlobalFilter>();
  public globalFilterIds: string[];
  public globalFilterStructureIDs: string[];
  public globalFilterDataIDs: string[];
  private globalFilterIdsEmitter = new BehaviorSubject<any>([]);
  private entryNodesIdsMap = {};

  /* GlobalFactsheet */
  public openGlobalFactSheetfunction;
  public closeGlobalFactsheetfunction;
  private dashboardScope;

  /* Color label providers */
  private colorLabelProviders: { key: string, label: string }[] = [
    { key: '', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.NOTAPPLICABLE' },
    { key: 'heatmap', label: 'Heatmap' },
    { key: 'importance', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.IMPORTANCE' },
    { key: 'nodetypes', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.NODETYPES' },
    { key: 'planned', label: 'LEGEND.PLANNED.PLANNED' },
    { key: 'projectsavailable', label: 'Projects available' },
    { key: 'responsible', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.RESPONSIBLE' },
    { key: 'risk', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.RISK' },
    { key: 'status', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.STATUS' },
    { key: 'targetDate', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.TARGETDATE' },
    { key: 'coloredRelations', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.COLOREDRELATIONS' },
    { key: 'statusField', label: 'Status field' },
    { key: 'comparison', label: 'Comparison' },
    { key: 'structure', label: 'Structure' },
    { key: 'grouping', label: 'Grouping' }
  ];

  private defaultColorLabelProviders: { key: string, label: string }[];

  /* User */
  public currentUser: CoreUser;

  private clpKey = '';

  public getNodeTypes() {
    return CoreNodeTypes;
  }
  public getCoreWidgets() {
    return CoreWidgets;
  }
  public getNodeFields() {
    return this.nodeFields;
  }
  public getFormFieldControlTypes() {
    return FormFieldControlType;
  }

  public getFormFieldCalculation() {
    return FormFieldCalculation;
  }

  public getFormFieldTypes() {
    return FormFieldType;
  }

  public getGlobalfilterStructureEntryIDs () {
    return this.gfstructureEntryNodeIDs;
  }

  public getDropDownFields(type: string) {
    switch (type) {
      case 'formFieldCalculation':
        return FormFieldCalculation;
      case 'formFieldDropdownValue':
        return FormFieldDropdownValue;
      case 'widget':
        return CoreWidgets;
      case 'formFieldId':
        return NodeFields;
      case 'formFieldControlType':
        return FormFieldControlType;
      case 'formFieldType':
        return FormFieldType;
      case 'formFieldShape':
        return FormFieldShape;
      case 'aiServices':
        return AIServices;
      case 'externalInput':
        return ExternalInput;
    }
    return [];
  }

  public ngOnDestroy() {
    this.subscriptionService.remove();
  }

  private clearCache(filter = false) {
    if (filter) {
      AppGlobal.filterCache = [];
    }
    AppGlobal.filterNodesCache = {};
  }
  /**
   * Load the business area
   * @param businessAreaId
   */
  public load(businessAreaId: string) {
    AppGlobal.instanceId = undefined;
    if (this.businessAreaId !== businessAreaId) {
      /* Store the business area id */
      this.businessAreaId = businessAreaId;
      /* Bind listeners */
      this.bindTreeNodesListener();
      /* Initialise */
      this.initialise();
      /* Wait for hierarchy to be loaded */
      this.hierarchyService.all().take(1).subscribe(hierarchy => {
        this.getInstanceByHierarchy(hierarchy, businessAreaId);
        /* Load the user */
        this.userService.load(undefined, ['activities']);
        /* Loading */
        this.loading = true;
        /* Mass load the business area */
        this.treeService.loadByBusinessArea(parseInt(businessAreaId));
      });
    }
  }

  public getMobileToken(success: Function, error?: Function) {
    const user = localStorage.getItem('valueminer.mobile-username');
    const password = localStorage.getItem('valueminer.mobile-password');

    if (user === null || password === null) {
      error(null);
      return;
    }

    const path = localStorage.getItem('valueminer.mobile-path');

    if (AppGlobal.tokenGetsLoaded === false) {
      AppGlobal.tokenGetsLoaded = true;
      const credentials = {
        'user': user,
        'password': password,
        'clientId': environment.auth.mobileClientId,
        'oauth': environment.auth.authorization[path] + '/app/authorisation'
      };

      const domain = this.setMobileDomain(path);

      this.authoriseEntry(domain, credentials).then(token => {
        if (token instanceof HttpErrorResponse) {
          error(token.error);
        } else {
          localStorage.setItem('valueminer.mobile-token', token);
          success(token);
        }
        AppGlobal.tokenGetsLoaded = false;
      });
    }
  }

  public setMobileDomain(path: string) {
    let domain = path;
    if (environment.goPrefix[path] !== undefined) {
      domain = environment.goPrefix[path] + domain;
    }
    if (environment.goSuffix[path] !== undefined) {
      domain += environment.goSuffix[path];
    }
    /* Set the environment */
    AppGlobal.oauthUrl = environment.auth.authorization[path];
    AppGlobal.exportUrl = (environment.isLocal ? 'http://' : 'https://') + ':8100';
    AppGlobal.oauthUrl = environment.auth.authorization[path];
    AppGlobal.imageUrl = environment.auth.authorization[path] + '/profile/?id=';
    AppGlobal.fileupload = environment.auth.authorization[path];
    AppGlobal.go = domain;
    AppGlobal.authurl = environment.auth.authorization[path] + '/oauth/authorize/';
    AppGlobal.tokenurl = environment.auth.authorization[path] + '/oauth/token/';
    AppGlobal.logouturl = environment.auth.authorization[path] + '/wp-login.php?action=logout';
    return domain;
  }

  /**
   * Reloading the ba
   *
   */
  public reloadBusinessArea() {
    /* Loading */
    this.loading = true;
    /* Load */
    this.treeService.loadByBusinessArea(parseInt(this.businessAreaId));
  }

  public loadBusinessArea() {
    return new Promise(resolve => {
      this.subscriptionService.add('load-business-area', this.businessAreaService.diff.subscribe(diff => {
        if (!!diff.action && (diff.action === BusinessareaAction.MASSLOAD_SUCCESS || diff.action === BusinessareaAction.LOAD_FAIL)) {
          this.subscriptionService.remove('load-business-area');
          resolve();
        }
      }));
      if (this.useGo) {
        this.businessAreaService.massGo(this.businessAreaId);
      } else {
        this.businessAreaService.mass(this.businessAreaId);
      }
    });
  }

  public loadBusinessAreas() {
    this.businessAreaService.loadGo();
  }

  public loadActions(id: string, callback?: Function) {
    if (callback !== undefined) {
      this.businessAreaService.diff.pipe(filter(diff => !!diff && (diff.action === BusinessareaAction.LOAD_ACTIONS_SUCCESS || diff.action === BusinessareaAction.LOAD_ACTIONS_FAIL))).subscribe(diff => {
        if (diff.payload !== undefined && diff.payload.id !== undefined && diff.payload.id === id) {
          callback(diff.response);
        }
      });
    }
    this.businessAreaService.loadActions(id);
  }

  /**
   * Clear the module
   */
  public clearModule(): CoreService {
    this.businessAreaId = undefined;
    this.mcm = undefined;
    this.businessAreaCheckSum = undefined;
    this.modelsCheckSum = this.modelsCheckSum.clear();
    this.humanResourcesCheckSum = undefined;
    this.groupsCheckSum = undefined;
    this.nodesCheckSum = undefined;
    this.relationshipsCheckSum = undefined;
    this.activitiesCheckSum = undefined;
    return this;
  }

  public clearLocalStorage(callback) {
    this.coreUtilities.clearAll(true, undefined, callback);
  }

  /**
   * Destroy all listeners and put everything into garbage collection
   */
  public destroy() {
    this.subscriptionService.remove();
  }

  /**
   * Get the configuration node + it's child tree
   */
  public getConfiguration(): Observable<Array<TreeNode>> {
    return this.getNodes({ onlyMCM: true, ignoreGlobalFilter: true }).map(treeNodes => {
      const configurationNodes = treeNodes.filter(treeNode => treeNode.nodeType === NODES_TYPE_MODULECONFIGURATION && '' + treeNode.businessarea === '' + this.businessAreaId);
      if (configurationNodes.length > 0) {
        this.humanResourceHandling = configurationNodes[0].formFieldDropdownValue === 'humanresources';
        this.humanResourceHandlingParents = configurationNodes[0].formFieldId !== 'dynamic-children';
        const fieldsToReplace = {};
        const fieldsNode = configurationNodes[0].children.filter(child => child.nodeType === NODES_TYPE_FIELDS)[0]; // gets the Node with NodeType Fields
        if (fieldsNode !== undefined) {
          const exclusive = fieldsNode.obligatory; // makes a custom list with the only wanted values
          const count = fieldsNode.children.length; // count of the custom fields that we have
          for (let i = 0; i < count; i++) {
            const child = fieldsNode.children[i];
            fieldsToReplace[child.formFieldId] = child;
          }
          if (exclusive) {
            this.nodeFields = Map(fieldsToReplace).map((treeNode: TreeNode) => ({ key: treeNode.formFieldId, value: treeNode.name })).toArray();
          } else {
            this.nodeFields = NodeFields.map(field => {
              field = Map(field).toJS();
              if (fieldsToReplace[field.key] !== undefined) {
                field.value = fieldsToReplace[field.key].name;
              }
              return field;
            });
          }

        } else {
          this.nodeFields = NodeFields;
        }
      }
      return configurationNodes;
    });
  }

  /**
   * Get all modules aka business areas with a specific business area type
   */
  public getModules(): Observable<CoreModule[]> {
    return this.businessAreaService.all().map(businessAreas => {
      return businessAreas.sort((a, b) => this.coreUtilities.sort(a.name, b.name, true)).map(hierarchy => this.coreTransformer.businessAreaToModule(hierarchy)).toArray();
    });
    // return this.getBusinessAreasByHierarchy().map(hierarchies => hierarchies.sort((a, b) => this.coreUtilities.sort(a.name, b.name, true)).map(hierarchy => this.coreTransformer.businessAreaToModule(hierarchy)));
  }

  public import(importData: ImportData, callback: Function) {
    this.importService.diff.filter(diff => !isNullOrUndefined(diff) && (diff.action === ImportAction.IMPORT_SUCCESS || diff.action === ImportAction.IMPORT_FAIL)).subscribe(diff => callback(diff));
    this.importService.import(importData);
  }

  /**
   * Get actions
   */
  public getActions(moduleId?: string): Observable<TreeNode[]> {
    return this.getNodes({ filters: [{ by: 'nodeType', value: NODES_TYPE_ACTION }] }).map((treeNodes) => {
      return treeNodes.sort((a, b) => this.coreUtilities.sort(a.name, b.name));
    });
  }

  /**
   * Get the instance id by hierarchy and moduleId
   *
   * @param hierarchy
   * @param moduleId
   */
  public getInstanceByHierarchy(hierarchy: List<any>, moduleId: string) {
    hierarchy.forEach((instance) => {
      if (instance.attributes.children.filter(attribute => '' + attribute.id === moduleId).length > 0) {
        AppGlobal.businessAreaId = parseInt(moduleId);
        AppGlobal.instanceId = instance.id;
      }
    });
  }

  /**
   * Get all actions
   */
  public getAllActions(): Observable<TreeNode[]> {
    return this.getNodes().map(treeNodes => {
      return this.filterNodes(treeNodes, [{ by: 'nodeType', value: [NODES_TYPE_ACTIONGROUP, NODES_TYPE_SETUPACTION, NODES_TYPE_HOMEACTION] }], false, { ignoreCache: true });
    });
  }

  /**
   * Send alert
   */
  public alert(text: string) {
    return this.notificationService.alert('', text);
  }

  /* Send toast */
  public toast(text: string, style?: string) {
    return this.notificationService.toast(text, style);
  }

  /* Send error */
  public error(text: string, title = '') {
    return this.notificationService.error(title, text);
  }

  /**
   * Get tree nodes map
   */
  public getTreeNodesMap() {
    return this.treeNodes.getValue();
  }

  /**
   * Get nodes with a set of options
   * @param options
   * @param key
   *    filter      (string)  a filter key either a field of TreeRelationship or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   */
  public getNodes(options?: CoreOptions, key?: string): Observable<Array<TreeNode>> {
    return this.getNodesBy(this.treeNodes.map(treeNodes => {
      if (isNullOrUndefined(treeNodes) || (!isNullOrUndefined(options) && options.ignoreBusinessArea === true)) {
        return treeNodes;
      }
      if (!isNullOrUndefined(options) && !isNullOrUndefined(this.mcm) && (options.ignoreMCM === true || options.onlyMCM === true)) {
        return <OrderedMap<string, TreeNode>>treeNodes.filter(treeNode => {
          return (AppGlobal.byToken || '' + treeNode.businessarea === '' + this.businessAreaId) && (options.ignoreMCM ? treeNode.modelId !== this.mcm : treeNode.modelId === this.mcm);
        });
      }
      if (AppGlobal.byToken) {
        return treeNodes;
      }
      return <OrderedMap<string, TreeNode>>treeNodes.filter(treeNode => {
        return '' + treeNode.businessarea === '' + this.businessAreaId;
      });
    }), options, true, key);
  }

  public getInstantNodes(options?: CoreOptions): TreeNode[] {
    return this.treeNodes.getValue().filter(treeNode => {
      let visible = true;
      /* Filter the tree nodes if a filter is specified */
      if (!isNullOrUndefined(options) && !isNullOrUndefined(options.filters) && this.filterNodes([treeNode], options.filters, true, options).length === 0) {
        visible = false;
      }
      /* Return the tree nodes */
      return visible;
    }).toArray();
  }

  public getHistory(requestObj: any)  {
    this.auditService.getHistory(requestObj);
    return this.auditService.getAllHistory();
  }

  public getNodeDataAuditsBy(observable: Observable<TreeNode[]>, options?: CoreOptions): Observable<Map<string, CoreAudit[]>> {
    return this.getNodesBy(observable, options).mergeMap(treeNodes => {
      const ids = treeNodes.map(treeNode => treeNode.dataId);
      this.auditService.loadByIds('nodedata', ids);
      return this.auditService.all().map(audits => {
        let auditsMap = Map<string, CoreAudit[]>();
        audits.forEach(audit => {
          const coreAudit = this.coreTransformer.auditToCoreAudit(audit);
          const coreAudits = auditsMap.has('' + coreAudit.elementId) ? auditsMap.get('' + coreAudit.elementId) : [];
          coreAudits.push(coreAudit);
          auditsMap = auditsMap.set('' + coreAudit.elementId, coreAudits.sort((a, b) => b.updatedAt.timestamp - a.updatedAt.timestamp));
        });
        return auditsMap;
      });
    });
  }

  public getNodeDataAuditsByDataIds(dataIds: string[], callback: (auditsMap: Map<string, any[]>) => void) {
    this.subscriptionService.add('audits', this.getHistory({
      baID: parseInt(this.businessAreaId),
      type: 'multiNode',
      nodeDataIds: dataIds.map(d => parseInt(d))
    }).subscribe(audits => {
      let auditsMap = Map<string, any[]>();
      const count = audits.length;
      for (let i = 0; i < count; i++) {
        const audit = audits[i];
        const coreAudit = {
          updatedAt: new Datum(audit.createdAt.Time),
          elementId: audit.auditableId,
          event: audit.Event,
          delta: audit.delta,
        } as CoreAudit;
        const coreAudits = auditsMap.has('' + audit.auditableId) ? auditsMap.get('' + audit.auditableId) : [];
        coreAudits.push(coreAudit);
        auditsMap = auditsMap.set('' + coreAudit.elementId, coreAudits.sort((a, b) => b.createdAt - a.createdAt));
      }
      callback(auditsMap);
    }));
  }

  public getNodeDataAuditsByNode(treeNode: TreeNode) {
    // TODO Check with Michi map makes sense? Maybe clear cache at some point?
    // reason was that it couldn't be called twice
    if (isNullOrUndefined(this.auditMap[treeNode.id])) {
      this.auditMap[treeNode.id] = new Promise<Map<string, CoreAudit[]>>(resolve => {
        this.subscriptionService.add('audit-diff', this.auditService.diff.subscribe(diff => {
          if (!isNullOrUndefined(diff.payload) && !isNullOrUndefined(diff.payload.query) && diff.payload.query.nodedata.indexOf(treeNode.dataId) !== -1) {
            this.subscriptionService.remove('audit-diff');
            this.auditService.all().take(1).subscribe(audits => {
              let auditsMap = Map<string, CoreAudit[]>();
              audits.forEach(audit => {
                const coreAudit = this.coreTransformer.auditToCoreAudit(audit);
                const coreAudits = auditsMap.has('' + coreAudit.elementId) ? auditsMap.get('' + coreAudit.elementId) : [];
                coreAudits.push(coreAudit);
                auditsMap = auditsMap.set('' + coreAudit.elementId, coreAudits.sort((a, b) => b.updatedAt.timestamp - a.updatedAt.timestamp));
              });
              resolve(auditsMap);
            });
          }
        }));
        this.auditService.loadByIds('nodedata', [treeNode.dataId]);
      });
    }
    return this.auditMap[treeNode.id];

  }

  /**
   * Get nodes in hierarchical order with a set of options
   * @param options
   * @param key
   *    filter      (string)  a filter key either a field of TreeRelationship or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   */
  public getHierarchy(options?: CoreOptions, key?: string): Observable<Array<TreeNode>> {
    return this.getNodesBy(this.hierarchy, options, true, key);
  }

  /**
   * Get the business area
   */
  public getBusinessArea(): Observable<Businessarea> {
    return this.businessArea;
  }

  /**
   * Get the business area instant
   */
  public getBusinessAreaInstant(): Businessarea {
    return this.businessArea.getValue();
  }

  /**
   * Get the business area id
   */
  public getBusinessAreaId(): string {
    return this.businessAreaId;
  }

  public setBusinessAreaId(businessAreaId: string) {
    this.businessAreaId = businessAreaId;
  }

  /**
   * Get instances by hierarchy service
   */
  public getInstancesByHierarchy(): Observable<any[]> {
    return this.hierarchyService.allLoaded(HierarchyAction.LOAD_SUCCESS).map((instances: List<Hierarchy>) => this.coreUtilities.flatArray(instances
      .filter(instance => instance.instanceType !== INSTANCE_TYPE_LIBRARY)
      .toArray()));
  }

  /**
   * Get business areas by hierarchy service
   */
  public getBusinessAreasByHierarchy(): Observable<any[]> {
    return this.hierarchyService.allLoaded(HierarchyAction.LOAD_SUCCESS).map((instances: List<Hierarchy>) => this.coreUtilities.flatArray(instances
      .filter(instance => instance.instanceType !== INSTANCE_TYPE_LIBRARY)
      .map(instance => {
        if (!isNullOrUndefined(instance.children)) {
          return <Hierarchy[]>(<any[]>instance.children);
        } else if (!isNullOrUndefined((<any> instance).attributes.children)) {
          return (<any> instance).attributes.children;
        } else {
          return [];
        }
      }).toArray()));
  }

  /**
   * Get nodes with a set of options
   * @param options
   *    filter      (string)  a filter key either a field of TreeRelationship or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   */
  public getModels(options?: CoreOptions): Observable<Array<Model>> {
    return this.getModelsBy(this.models.map(models => {
      if (isNullOrUndefined(models)) {
        return models;
      }
      return <OrderedMap<string, Model>>models.filter(model => '' + model.relationships.businessarea === '' + this.businessAreaId);
    }), options);
  }

  /**
   * Get instant models
   * @param toArray
   *    filter      (string)  a filter key either a field of TreeRelationship or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   */
  public getInstantModels(toArray = true): Model[] | OrderedMap<string, Model> {
    const models = this.models.getValue().filter(model => '' + model.relationships.businessarea === '' + this.businessAreaId) as OrderedMap<string, Model>;
    return toArray ? models.toArray() : models;
  }

  public getVersions(model: Model): Observable<Array<CoreModel>> {
    return this.getModelsBy(this.models, { filters: [{ by: 'id', value: model.relationships.versions.map(id => '' + id).toArray() }] }).map(models => models.map(m => this.coreTransformer.modelToCoreModel(m)));
  }

  public createVersion(originalModel: CoreModel, name: string) {
    return new Promise(resolve => {
      this.versionService.diff.filter(diff => diff.action === VersionAction.CREATE_SUCCESS).take(1).subscribe(() => resolve());
      this.versionService.create(originalModel.id, { id: '', data: new Version({ 'description': name, 'modelId': originalModel.id }) });
    });
  }

  /**
   * Get models
   * @param observable
   * @param options
   * @param deep
   */
  public getModelsBy(observable: Observable<OrderedMap<string, Model>>, options?: CoreOptions, deep = true): Observable<Array<Model>> {
    return observable.filter(d => !!d).map(modelsMap => {
      /* First of all convert map to array */
      let models = modelsMap.toArray();
      /* Filter the models if a filter is specified */
      if (!isNullOrUndefined(options) && !isNullOrUndefined(options.filters)) {
        models = this.filterModels(models, options.filters, deep);
      }
      /* Return the tree nodes */
      return models;
    });
  }

  /**
   * Get the legends
   */
  public getLegends(modelIds?: string[], key?: string): Observable<CoreLegend[]> {
    const options = !isNullOrUndefined(modelIds) ? { filters: [{ by: 'modelId', value: modelIds }] } : { filters: [] };
    return this.getNodes(options, key).map(nodes => this.getLegend(nodes));
  }

  /* Get the label by label node */
  public getLabelByLabelNode(labelFieldNode: TreeNode, treeNode: TreeNode) {
    const label = [];
    const labels = labelFieldNode.children.sort((a, b) => a.positionX - b.positionX);
    const count = labels.length;
    for (let i = 0; i < count; i++) {
      const labelNode = labels[i];
      label.push(this.formService.getReadableValue(labelNode, treeNode));
    }
    return label.join(labelFieldNode.formId === '' ? ' ' : labelFieldNode.formId);
  }

  /**
   * Get the color label provider
   */
  public getColorLabelProvider(): BehaviorSubject<string> {
    return this.colorLabelProviderKey;
  }

  /**
   * Search by options in given scope
   * @param options
   * @param treeNodes
   * @param deep
   * @param childField
   * @param stopAtSuccess
   * @param doNotCross
   * @param result
   * @param ids
   */
  public searchBy(options: CoreOptions, treeNodes: TreeNode[], deep = true, childField = 'children', stopAtSuccess = false, doNotCross: number[] = [], result = [], ids = []): TreeNode[] {
    if (isNullOrUndefined(treeNodes)) {
      return result;
    }
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = treeNodes[i];
      if (doNotCross.indexOf(treeNode.nodeType) !== -1) {
        continue;
      }
      if (!isNullOrUndefined(treeNode) && ids.indexOf(treeNode.id) === -1) {
        ids.push(treeNode.id);
        let success = false;
        if (this.isNodeVisible(treeNode, options.filters)) {
          success = true;
          result.push(treeNode);
        }
        if (deep && treeNode[childField].length > 0 && treeNode.nodeType !== NODES_TYPE_NEXT && !(success && stopAtSuccess)) {
          result = this.searchBy(options, treeNode[childField], deep, childField, stopAtSuccess, doNotCross, result, ids);
        }
      }
    }
    return result;
  }

  /**
   * Search in tree
   * @param options
   * @param treeNode
   * @param direction
   */
  public searchInTree(options: CoreOptions, treeNode: TreeNode, direction = 'children'): TreeNode[] {
    const result: TreeNode[] = [];
    const tree = direction === 'children' || direction === 'unfilteredChildren' ? this.coreUtilities.getChildren(treeNode[direction]) : this.coreUtilities.getParents(treeNode[direction]);
    const count = tree.length;
    for (let i = 0; i < count; i++) {
      const t = tree[i];
      if (this.isNodeVisible(t, options.filters)) {
        result.push(t);
      }
    }
    return result;
  }

  /**
   * Get nodes in hierarchical order with a set of options
   * @param observable
   * @param options
   *    filter      (string)  a filter key either a field of TreeRelationship or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   * @param deep
   * @param key
   */
  public getNodesBy(observable: Observable<OrderedMap<string, TreeNode> | TreeNode[]>, options?: CoreOptions, deep = true, key?: string): Observable<Array<TreeNode>> {
    return observable.filter(d => !!d).map(treeNodesMap => {
      let checksum: string;
      if (!isNullOrUndefined(key)) {
        /* Get checksum */
        checksum = this.coreUtilities.getOptionsCheckSum(key, options);
        if (!isNullOrUndefined(AppGlobal.filterNodesCache[checksum])) {
          return AppGlobal.filterNodesCache[checksum];
        }
      }
      /* First convert map to array */
      let treeNodes = isArray(treeNodesMap) ? treeNodesMap : treeNodesMap.toArray();
      /* Filter the tree nodes if a filter is specified */
      if (!isNullOrUndefined(options) && !isNullOrUndefined(options.filters)) {
        treeNodes = this.filterNodes(treeNodes, options.filters, deep, options);
      }
      if (!isNullOrUndefined(checksum)) {
        AppGlobal.filterNodesCache[checksum] = treeNodes;
      }
      /* Return the tree nodes */
      return treeNodes;
    });
  }

  /**
   * Get nodes in hierarchical order with a set of options filtered by model node
   * @param observable
   * @param modelNode
   * @param options
   *    filter      (string)  a filter key either a field of TreeRelationship or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   * @param deep
   */
  public getNodesByModelNode(observable: Observable<TreeNode[]>, modelNode: TreeNode, options?: CoreOptions, deep = true): Observable<Array<TreeNode>> {
    return this.getModels({ filters: [{ by: 'type', value: modelNode.children.map(model => this.coreTransformer.nodeTypeToModelType(model.nodeType)) }] }).mergeMap(models => {
      if (isNullOrUndefined(options)) {
        options = { filters: [] };
      }
      options.filters.push({ by: 'modelId', value: models.map(model => model.id) });
      return this.getNodesBy(observable, options);
    });
  }

  /**
   * Get nodes in hierarchical order with a set of options
   * @param observable
   * @param options
   *    filter      (string)  a filter key either a field of TreeRelationship or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   * @param deep
   */
  public getNodesByArray(observable: Observable<TreeNode[]>, options?: CoreOptions, deep = true): Observable<Array<TreeNode>> {
    return observable.filter(d => !!d).map(treeNodes => {
      /* Filter the tree nodes if a filter is specified */
      if (!isNullOrUndefined(options) && !isNullOrUndefined(options.filters)) {
        treeNodes = this.filterNodes(treeNodes, options.filters, deep);
      }
      /* Return the tree nodes */
      return treeNodes;
    });
  }

  /**
   * Filter an array of tree nodes via key and value combination
   * @param treeNodes
   * @param filters
   * @param options
   * @param deep
   */

  public filterNodes(treeNodes: TreeNode[], filters: CoreFilter[], deep = false, options?: CoreOptions) {
    if (this.currentUser !== undefined && !this.currentUser.superUser && this.filteredOutModels.length > 0) {
      treeNodes = treeNodes.filter(treeNode => this.filteredOutModels.indexOf(treeNode.modelId) === -1);
    }
    if (filters.length === 0) {
      return treeNodes;
    }
    const filterKey = this.coreUtilities.getFilterKey(filters, options);
    return this.filterNodesByVisible(this.setVisibleNodes(treeNodes, filters, filterKey, options), filterKey);
  }

  /**
   * Filter nodes by key
   * @param treeNodes
   * @param filterKey
   */
  public filterNodesByVisible(treeNodes: TreeNode[], filterKey) {
    if (isNullOrUndefined(treeNodes)) {
      return [];
    }
    /* Filter the tree node */
    return treeNodes.filter(treeNode => !isNullOrUndefined(treeNode.visible) && treeNode.visible[filterKey]);
  }

  private setVisibleNodes(treeNodes: TreeNode[], filters: CoreFilter[], filterKey: string, options?: CoreOptions) {
    /* Stop processing because the filter is already known */
    if ((options === undefined || options.ignoreCache !== true) && AppGlobal.filterCache.indexOf(filterKey) !== -1) {
      return treeNodes;
    }
    if (options === undefined || options.ignoreCache !== true) {
      AppGlobal.filterCache.push(filterKey);
    }
    const filtersLength = filters.length;
    /* If filter is empty */
    if (filtersLength === 0 || isNullOrUndefined(treeNodes) || treeNodes.length === 0) {
      return treeNodes;
    }
    /* If filter is not empty */
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = treeNodes[i];
      /* Set the visible value */
      if (isNullOrUndefined(treeNode.visible)) {
        treeNode.visible = {};
      }
      if (!isNullOrUndefined(options) && !isNullOrUndefined(options.ignoreGlobalFilter) && options.ignoreGlobalFilter === true) {
        treeNode.visible['global'] = true;
      } else {
        treeNode.visible['global'] = isNullOrUndefined(this.globalFilterIds) || this.globalFilterIds.indexOf(treeNode.id) !== -1;
      }
      treeNode.visible[filterKey] = this.isNodeVisible(treeNode, filters, options);
    }
    return treeNodes;
  }

  /**
   * Filter a node by presets or key value combination
   * @param treeNode
   * @param filters
   * @param options
   */
  public isNodeVisible(treeNode: TreeNode, filters: CoreFilter[], options?: CoreOptions): boolean {
    let visible = true;
    const visibleMap = {};
    const count = filters.length;
    for (let i = 0; i < count; i++) {
      const filter = filters[i];
      if (!isNullOrUndefined(filter.nodeType) && filter.nodeType !== treeNode.nodeType) {
        continue;
      }
      switch (filter.by) {
        case 'colorLabelProvider':
          visible = this.filterByColorLabelProvider(treeNode);
          break;
        case 'nodeTypes':
          visible = this.filterByNodeTypes(treeNode);
          break;
        case 'parent':
          visible = this.coreUtilities.getParents([treeNode]).filter(parent => filter.value.indexOf(parent.dataId) !== -1).length > 0;
          break;
        case 'child':
          visible = this.coreUtilities.getChildren([treeNode]).filter(child => filter.value.indexOf(child.dataId) !== -1).length > 0;
          break;
        case 'children':
        case 'parents':
          visible = treeNode[filter.by].length === filter.value;
          break;
        case 'global':
          if (!isNullOrUndefined(options) && options.ignoreGlobalFilter === true) {
            visible = true;
          } else {
            visible = isNullOrUndefined(this.globalFilterIds) || this.globalFilterIds.indexOf(treeNode.id) !== -1;
          }
          break;
        case 'global-structure':
          visible = isNullOrUndefined(this.globalFilterStructureIDs) || this.globalFilterStructureIDs.length === 0 || this.globalFilterStructureIDs.indexOf(treeNode.id) !== -1;
          break;
        case 'global-data':
          visible = isNullOrUndefined(this.globalFilterDataIDs) || this.globalFilterDataIDs.length === 0 || this.globalFilterDataIDs.indexOf(treeNode.id) !== -1;
          break;
        case 'related':
          visible = isNullOrUndefined(treeNode.related) || this.coreUtilities.arraysShareElements(filter.value, treeNode.related);
          break;
        case 'dynamic-connect':
            const nodeTypeNode = filter.value[0][treeNode.nodeType];
            if (nodeTypeNode === undefined) {
              visible = false;
            } else {
              const traverserResult = nodeTypeNode.run([treeNode]);
              visible = traverserResult.length > 0;
            }
            break;
        case 'startDate':
        case 'targetDate':
        case 'endDate':
        case 'plannedEnddate':
        case 'actualDate':
        case 'actualStartDate':
        case 'creationDate':
          if (filter.range) {
            visible = !isNullOrUndefined(treeNode[filter.by]) && (filter.value[0].start < treeNode[filter.by] && filter.value[0].end > treeNode[filter.by]);
            break;
          }
        default:
          if (filter.child) {
            visible = isArray(filter.value) ? filter.value.filter(value => treeNode.parents.filter(e => e[filter.by] === value).length > 0).length > 0 : treeNode.parents.filter(e => e[filter.by] === filter.value).length > 0;
          } else {
            visible = isArray(filter.value) ? filter.value.filter(value => !!treeNode && treeNode[filter.by] + '' === value + '').length > 0 : treeNode[filter.by] + '' === filter.value + '';
          }
          break;
      }
      if (filter.not) {
        visible = !visible;
      }
      if (filter.or !== undefined && filter.or.length > 0) {
        if (!visible && filter.or.map(orId => visibleMap['' + orId]).filter(rel => rel === true).length > 0) {
          visible = true;
        }
        visibleMap['' + filter.id] = visible;
      }
      if (!visible && (filter.or === undefined || filter.or.length === 0)) {
        break;
      }
    }
    return visible;
  }

  /**
   * Get relationships with a set of options
   * @param options
   *    filter      (string)  a filter key either a field of TreeRelationship or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   */
  public getRelationships(options?: CoreOptions): Observable<Array<TreeRelationship>> {
    return this.getRelationshipsBy(this.treeRelationships, options);
  }

  /**
   * Get relationships with a set of options
   * @param options
   *    filter      (string)  a filter key either a field of TreeRelationship or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   */
  public getInstantRelationships(options?: CoreOptions): Array<TreeRelationship> {
    /* First of all convert map to array */
    let treeRelationships = this.treeRelationships.getValue().toArray();
    /* Filter the tree nodes if a filter is specified */
    if (!isNullOrUndefined(options) && !isNullOrUndefined(options.filters)) {
      treeRelationships = this.filterRelationships(treeRelationships, options.filters);
    }
    /* Check relationships against hidden node ids */
    treeRelationships = treeRelationships.map(treeRelationship => {
      /* Reset connected relationships */
      if (treeRelationship.connected) {
        treeRelationship.parentId = treeRelationship.originalParentId;
        treeRelationship.originalParentId = undefined;
        treeRelationship.connected = false;
      }
      return treeRelationship;
    });
    /* Return the tree nodes */
    return treeRelationships;
  }

  public getRelationshipsWithTRT(nodeIds: string[], trtMap?: any) {
    /* Get relationships */
    const relationships = this.getInstantRelationships({ filters: [{ by: 'parentId', value: nodeIds }, { by: 'childId', value: nodeIds }] });
    /* Use TRT */
    if (trtMap !== undefined) {
      /* Build map from existing relationships */
      const relationshipMap = {};
      let count = relationships.length;
      for (let i = 0; i < count; i++) {
        const relationship = relationships[i];
        if (relationshipMap[relationship.parentId] === undefined) {
          relationshipMap[relationship.parentId] = {};
        }
        relationshipMap[relationship.parentId][relationship.childId] = true;
      }
      /* Identify the TRT relationships that are not part of the map */
      const trtMapMap = Map(trtMap);
      trtMapMap.forEach((trt: any, parentId: string) => {
        count = trt.childIds.length;
        for (let i = 0; i < count; i++) {
            const childId = trt.childIds[i];
            if (relationshipMap[parentId] === undefined || relationshipMap[parentId][childId] === undefined) {
              relationships.push({ id: UUID.UUID(), parentId, childId, weight: 1 } as TreeRelationship);
              relationshipMap[parentId] = {};
              relationshipMap[parentId][childId] = true;
            }
        }
      });
    }
    /* Return relationships */
    return relationships;
  }

  /**
   * Get the library configurations to take over to ba module config map
   */
  public getLibraryConfiguration(load = false, businessAreaType = BUSINESSAREA_TYPE_MODULE, modelType = MODEL_TYPE_MCM) {
    if (load) {
      this.hierarchyService.loadActions();
    }
    /* Listen on hierarchy */
    return this.hierarchyService.all().filter(h => h.size > 0).mergeMap(instances => {
      /* Figure out library instance */
      const library = instances.filter(instance => instance.instanceType === INSTANCE_TYPE_LIBRARY).first();
      if (!isNullOrUndefined(library)) {
        /* Search for the business area */
        const businessArea = library.children.filter(ba => ba.businessAreaType === businessAreaType)[0];
        if (!isNullOrUndefined(businessArea)) {
          /* Search for the configuration model */
          const model = businessArea.children.filter(m => m.modelstype === modelType)[0];
          if (!isNullOrUndefined(model)) {
            this.modelService.mass(model.id);
            return Observable.combineLatest(
              this.nodeService.byModelIds(['' + model.id]),
              this.relationshipService.all()
            ).filter(d => d[0].size > 0).map(d => [[], d[0], d[1], [], []]);
          } else {
            return Observable.empty();
          }
        } else {
          return Observable.empty();
        }
      } else {
        return Observable.empty();
      }
    }).map(d => this.onDataLoaded(d, true));
  }

  /**
   * Get the library configurations to take over to ba module config map
   */
  public getLibraryModules(modelType = MODEL_TYPE_MCM) {
    /* Listen on hierarchy */
    return this.hierarchyService.all().filter(h => h.size > 0).map(instances => {
      let modules = [];
      /* Figure out library instance */
      const library = instances.filter(instance => instance.instanceType === INSTANCE_TYPE_LIBRARY).first();
      if (!isNullOrUndefined(library)) {
        const count = library.children.length;
        for (let i = 0; i < count; i++) {
          const businessArea = library.children[i];
          modules = modules.concat(businessArea.modules);
        }
      }
      return modules;
    });
  }

  /**
   * Filter the models
   * @param models
   * @param filters
   * @param deep
   */
  public filterModels(models: Model[], filters: CoreFilter[], deep = true) {
    return models.filter(model => !isNullOrUndefined(model) && this.filterModel(model, filters));
  }

  /**
   * Filter a model
   * @param model
   * @param filters
   */
  public filterModel(model: Model, filters: CoreFilter[]): boolean {
    let visible = true;
    const count = filters.length;
    for (let i = 0; i < count; i++) {
      const filter = filters[i];
      switch (filter.by) {
        default:
          visible = isArray(filter.value) ? filter.value.filter(value => model[filter.by] === value).length > 0 : model[filter.by] === filter.value;
          break;
      }
      if (!visible) {
        break;
      }
    }
    return visible;
  }

  public getModelTypeMap(nodeTypeNodes: TreeNode[]) {
    const nodeTypes = [];
    const listeners = [];

    if (!isNullOrUndefined(nodeTypeNodes)) {
      const count = nodeTypeNodes.length;
      for (let i = 0; i < count; i++) {
        const nodeTypeNode = nodeTypeNodes[i];
        const modelTypeNode = nodeTypeNode.unfilteredChildren.filter(child => child.nodeType === NODES_TYPE_MODEL)[0];
        if (!isNullOrUndefined(modelTypeNode)) {
          const count2 = modelTypeNode.unfilteredChildren.length;
          for (let i2 = 0; i2 < count2; i2++) {
            const modelType = this.coreTransformer.nodeTypeToModelType(modelTypeNode.unfilteredChildren[i2].nodeType);
            nodeTypes.push(nodeTypeNode.nodeType);
            listeners.push(this.getModels({ filters: [{ by: 'type', value: modelType }] }).map(models => models[0]));
          }
        }
      }
    }

    return combineLatest(listeners).map((data: any[]) => {
      let map = Map<number, string>();
      const dataCount = data.length;
      for (let i = 0; i < dataCount; i++) {
        if (!isNullOrUndefined(data[i])) {
          map = map.set(nodeTypes[i], data[i].id);
        }
      }
      return map;
    });
  }

  public getModelsByModelNode(modelNode: TreeNode) {
    const modelTypes = modelNode.unfilteredChildren.map(nodeTypeNode => this.coreTransformer.nodeTypeToModelType(nodeTypeNode.nodeType));
    return this.getModels({ filters: [{ by: 'type', value: modelTypes }] });
  }

  public getModelByModelNode(modelNode: TreeNode) {
    const nodeTypeNode = modelNode.unfilteredChildren[0];
    if (isNullOrUndefined(nodeTypeNode)) {
      return undefined;
    }
    const modelType = this.coreTransformer.nodeTypeToModelType(nodeTypeNode.nodeType);
    return this.getModels({ filters: [{ by: 'type', value: modelType }] }).map(models => models[0]);
  }

  public getInstantModelByModelNode(modelNode: TreeNode) {
    const nodeTypeNode = modelNode.unfilteredChildren[0];
    if (isNullOrUndefined(nodeTypeNode)) {
      return undefined;
    }
    const modelType = this.coreTransformer.nodeTypeToModelType(nodeTypeNode.nodeType);
    const models = this.models.getValue();
    return this.filterModels(models === undefined ? [] : models.filter(model => '' + model.relationships.businessarea === '' + this.businessAreaId).toArray(), [{ by: 'type', value: modelType }], true);
  }

  /**
   * Generate a transfer object out of a tree
   * @param treeNodes
   * @param parentTreeNode
   * @param transfer
   */
  public generateTransferFromTree(treeNodes: TreeNode[], parentTreeNode?: TreeNode, transfer = <CoreTransfer>{ nodes: [], relationships: [], ids: [] }): CoreTransfer {
    let relMap = Map<string, TreeRelationship>();
    this.treeRelationships.getValue().forEach(treeRelationship => relMap = relMap.set(treeRelationship.parentId + ':' + treeRelationship.childId, treeRelationship));
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = this.duplicateTreeNode(treeNodes[i]);
      if (transfer.ids.indexOf(treeNode.id) === -1) {
        (<TreeNode[]>transfer.nodes).push(treeNode);
        transfer.ids.push(treeNode.id);
      }
      if (!isNullOrUndefined(parentTreeNode)) {
        const key = parentTreeNode.id + ':' + treeNode.id;
        if (relMap.has(key)) {
          (<TreeRelationship[]>transfer.relationships).push(relMap.get(key));
        }
      }
      transfer = this.generateTransferFromTree(treeNode.children, treeNode, transfer);
    }
    return transfer;
  }

  /**
   * Generate a transfer object out of a tree
   * @param treeNode
   * @param parentTreeNode
   * @param transfer
   */
  public generateTransferFromTreeNode(treeNode: TreeNode, parentTreeNode?: TreeNode, transfer = <CoreTransfer>{ nodes: [], relationships: [], ids: [] }): CoreTransfer {
    treeNode = this.duplicateTreeNode(treeNode);
    if (transfer.ids.indexOf(treeNode.id) === -1) {
      (<TreeNode[]>transfer.nodes).push(treeNode);
      transfer.ids.push(treeNode.id);
    }
    if (!isNullOrUndefined(parentTreeNode)) {
      (<TreeRelationship[]>transfer.relationships).push(<TreeRelationship>{ id: UUID.UUID(), parentId: parentTreeNode.id, childId: treeNode.id });
    }
    const count = treeNode.children.length;
    for (let i = 0; i < count; i++) {
      transfer = this.generateTransferFromTreeNode(treeNode.children[i], treeNode, transfer);
    }
    return transfer;
  }

  /**
   * Duplicate a tree node
   * @param treeNode
   * @param set
   */
  public duplicateTreeNode(treeNode: TreeNode, set: { key: string, value: any }[] = [{ key: 'businessarea', value: this.businessAreaId }, { key: 'modelId', value: undefined }]): TreeNode {
    let map = Map(treeNode);
    const count = set.length;
    for (let i = 0; i < count; i++) {
      const replace: { key: string, value: any } = set[i];
      map = map.set(replace.key, replace.value);
    }
    return <TreeNode>map.toJS();
  }

  public uploadDocument(files: FileList, callback: Function) {
    /* Request id */
    const requestId = UUID.UUID();

    /* Subscribe to Diff */
    this.generalService.diff.filter(d => {
      return (!!d && !!d.action && !!d.payload && d.payload === requestId);
    }).take(1).subscribe(diff => {
      callback(diff.response);
    });

    /* Upload the file */
    this.generalService.uploadDocument(requestId, files);
  }

  public getDocuments(keys: string[], callback: Function) {
    /* Request id */
    const requestId = UUID.UUID();

    /* Subscribe to Diff */
    this.generalService.diff.filter(d => {
      return (!!d && !!d.action && !!d.payload && d.payload === requestId);
    }).take(1).subscribe(diff => {
      callback(diff.response);
    });

    /* Upload the file */
    this.generalService.getDocuments(requestId, keys);
  }

  public showDocument(document: GetDocumentsResponse) {
    /* Open modal */
    this.modal('document-modal', (documentModal: DocumentModalComponent) => {
      /* Set the document */
      documentModal.document = document;
      /* Show the modal */
      documentModal.show();
    });
  }

  /**
   * Get the core user
   * @param load
   */
  public getUser(load = false): Observable<CoreUser> {
    if (AppGlobal.byToken) {
      return new BehaviorSubject<CoreUser>(this.currentUser);
    }
    if (load) {
      this.userService.load(undefined, ['activities']);
    }
    return this.userService.find().filter(d => !!d).map((user: User) => {
      this.currentUser = this.coreTransformer.userToCoreUser(user, (!isNullOrUndefined(this.currentUser) ? this.currentUser.hrId : undefined));
      return this.currentUser;
    });
  }

  /**
   * Get current human resource
   */
  public getUserHumanResource(): Promise<CoreHumanResource> {
    return new Promise<CoreHumanResource>(resolve => {
      this.getUser().take(1).subscribe(coreUser => {
        if (AppGlobal.byToken) {
          this.getHumanResources({ filters: [{ by: 'id', value: coreUser.humanResources.map(id => parseInt(id)) }] }).take(1).subscribe(humanResources => {
            resolve(humanResources[0]);
          });
        } else {
          this.getHumanResources({ filters: [{ by: 'id', value: coreUser.humanResources.map(id => parseInt(id)) }, { by: 'instanceId', value: this.getBusinessAreaInstant().relationships.instance }] }).take(1).subscribe(humanResources => {
            resolve(humanResources[0]);
          });
        }
      });
    });
  }

  public getPermissions(load = false) {
    return this.getUser(load).map(user => user.permissions);
  }

  /**
   * Get relationships with a set of options
   * Options:
   *    filter      (string)  a filter key either a field of TreeNode or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   * @param observable
   * @param options
   */
  public getRelationshipsBy(observable: Observable<OrderedMap<string, TreeRelationship>>, options?: CoreOptions): Observable<Array<TreeRelationship>> {
    return observable.filter(d => !!d).map(treeRelationshipsMap => {
      /* First of all convert map to array */
      let treeRelationships = treeRelationshipsMap.toArray();
      /* Filter the tree nodes if a filter is specified */
      if (!isNullOrUndefined(options) && !isNullOrUndefined(options.filters)) {
        treeRelationships = this.filterRelationships(treeRelationships, options.filters);
      }
      /* Check relationships against hidden node ids */
      treeRelationships = treeRelationships.map(treeRelationship => {
        /* Reset connected relationships */
        if (treeRelationship.connected) {
          treeRelationship.parentId = treeRelationship.originalParentId;
          treeRelationship.originalParentId = undefined;
          treeRelationship.connected = false;
        }
        return treeRelationship;
      });
      /* Return the tree nodes */
      return treeRelationships;
    });
  }

  public getRelationshipWeights() {
    return this.relationshipWeights;
  }

  /**
   * Get relationships with a set of options
   * Options:
   *    filter      (string)  a filter key either a field of TreeNode or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   * @param observable
   * @param options
   */
  public getRelationshipsByArray(observable: Observable<TreeRelationship[]>, options?: CoreOptions): Observable<Array<TreeRelationship>> {
    return observable.filter(d => !!d).map(treeRelationships => {
      /* Filter the tree nodes if a filter is specified */
      if (!isNullOrUndefined(options) && !isNullOrUndefined(options.filters)) {
        treeRelationships = this.filterRelationships(treeRelationships, options.filters);
      }
      /* Check relationships against hidden node ids */
      treeRelationships = treeRelationships.map(treeRelationship => {
        /* Reset connected relationships */
        if (treeRelationship.connected) {
          treeRelationship.parentId = treeRelationship.originalParentId;
          treeRelationship.originalParentId = undefined;
          treeRelationship.connected = false;
        }
        return treeRelationship;
      });
      /* Return the tree nodes */
      return treeRelationships;
    });
  }

  /**
   * Filter an array of tree relationships via key and value combination
   * @param treeRelationships
   * @param filters
   */
  public filterRelationships(treeRelationships: TreeRelationship[], filters: CoreFilter[]) {
    return treeRelationships.filter(treeRelationship => {
      if (treeRelationship === undefined) {
        return false;
      }
      let visible = true;
      const count = filters.length;
      for (let i = 0; i < count; i++) {
        const filter = filters[i];
        switch (filter.by) {
          default:
            visible = isArray(filter.value) ? filter.value.filter(value => treeRelationship[filter.by] === value).length > 0 : treeRelationship[filter.by] === filter.value;
            break;
        }
        if (!visible) {
          break;
        }
      }
      return visible;
    });
  }

  /**
   * Get human resources
   * @param options
   */
  public getHumanResources(options?: CoreOptions): Observable<Array<CoreHumanResource>> {
    if (AppGlobal.byToken) {
      return this.getHumanResourcesBy(this.humanResources.filter(a => !!a), options);
    }
    return this.getHumanResourcesBy(this.humanResources.filter(a => !!a).map(humanResources => <OrderedMap<string, CoreHumanResource>>humanResources.filter(humanResource => humanResource.instanceId === this.businessArea.getValue().relationships.instance)), options);
  }

  /**
   * Get instant human resources
   */
  public getInstantHumanResources(returnMap = false): OrderedMap<string, CoreHumanResource> | CoreHumanResource[] {
    const map = this.humanResources.getValue();
    return returnMap ? map : map.toArray();
  }

  /**
   * Get instant groups
   */
  public getInstantGroups(returnMap = false): OrderedMap<string, CoreGroup> | CoreGroup[] {
    const map = this.groups.getValue();
    return returnMap ? map : map.toArray();
  }

  /**
   * Merge nodes with human resources
   * @param observable
   */
  public mergeWithHumanResource(observable: Observable<TreeNode[]>) {
    return observable.mergeMap(nodes => {
      return this.getHumanResources().map(humanResources => nodes.map(node => {
        /* Add responsible data */
        if (!isNullOrUndefined(node.responsibleId) && node.nodeType === NODES_TYPE_HUMANRESOURCE) {
          const humanResource = humanResources.filter(h => '' + h.id === '' + node.responsibleId)[0];
          if (!isNullOrUndefined(humanResource)) {
            node.image = humanResource.image;
            node.name = humanResource.first_name + ' ' + humanResource.last_name;
            node.storypoints = humanResource.storypoints;
            node.email = humanResource.email;
          }
        }
        return node;
      }));
    });
  }

  /**
   * Get the human resources
   * @param observable
   * @param options
   * @param deep
   */
  public getHumanResourcesBy(observable: Observable<OrderedMap<string, CoreHumanResource>>, options?: CoreOptions, deep = true): Observable<Array<CoreHumanResource>> {
    return observable.filter(d => !!d).map(humanResourcesMap => {
      /* First of all convert map to array */
      let humanResources = humanResourcesMap.toArray();
      /* Filter the human resources if a filter is specified */
      if (!isNullOrUndefined(options) && !isNullOrUndefined(options.filters)) {
        humanResources = this.filterHumanResources(humanResources, options.filters, deep);
      }
      /* Return the tree nodes */
      return humanResources;
    });
  }

  /**
   * Filter human resources
   * @param humanResources
   * @param filters
   * @param deep
   */
  public filterHumanResources(humanResources: CoreHumanResource[], filters: CoreFilter[], deep = true) {
    return humanResources.filter(humanResource => !!humanResource && this.filterHumanResource(humanResource, filters));
  }

  /**
   * Filter a human resource by presets or key value combination
   * @param humanResource
   * @param filters
   */
  public filterHumanResource(humanResource: CoreHumanResource, filters: CoreFilter[]): boolean {
    let visible = true;

    const count = filters.length;
    for (let i = 0; i < count; i++) {
      const filter = filters[i];
      switch (filter.by) {
        default:
          visible = isArray(filter.value) ? filter.value.filter(value => humanResource[filter.by] === value).length > 0 : humanResource[filter.by] === filter.value;
          break;
      }
      if (!visible) {
        break;
      }
    }
    return visible;
  }

  /**
   * Get human resources
   * @param options
   */
  public getGroups(options?: CoreOptions): Observable<Array<CoreGroup>> {
    return this.getGroupsBy(this.groups, options);
  }

  /**
   * Get the human resources
   * @param observable
   * @param options
   * @param deep
   */
  public getGroupsBy(observable: Observable<OrderedMap<string, CoreGroup>>, options?: CoreOptions, deep = true): Observable<Array<CoreGroup>> {
    return observable.filter(d => !!d).map(groupsMap => {
      /* First of all convert map to array */
      let groups = groupsMap.toArray();
      /* Filter the human resources if a filter is specified */
      if (!isNullOrUndefined(options) && !isNullOrUndefined(options.filters)) {
        groups = this.filterGroups(groups, options.filters, deep);
      }
      /* Return the tree nodes */
      return groups;
    });
  }

  /**
   * Filter human resources
   * @param groups
   * @param filters
   * @param deep
   */
  public filterGroups(groups: CoreGroup[], filters: CoreFilter[], deep = true) {
    return groups.filter(group => !!group && this.filterGroup(group, filters));
  }

  /**
   * Filter a human resource by presets or key value combination
   * @param group
   * @param filters
   */
  public filterGroup(group: CoreGroup, filters: CoreFilter[]): boolean {
    let visible = true;

    const count = filters.length;
    for (let i = 0; i < count; i++) {
      const filter = filters[i];
      switch (filter.by) {
        default:
          visible = isArray(filter.value) ? filter.value.filter(value => group[filter.by] === value).length > 0 : group[filter.by] === filter.value;
          break;
      }
      if (!visible) {
        break;
      }
    }
    return visible;
  }

  public redrawColorLabelProvider(nodeType: number = -1) {
    this.changeColorLabelProvider(this.colorLabelProviderKey.getValue(), nodeType);
  }

  public getClp() {
    return this.clpKey;
  }

  /**
   * Change the color label provider
   * @param colorLabelProvider
   * @param nodeType
   * @param configuration
   */
  public changeColorLabelProvider(colorLabelProvider: string, nodeType = -1, configuration?: any) {
    this.clpKey = colorLabelProvider;
    /* Store the provider */
    if (colorLabelProvider !== this.selectedColorLabelProvider) {
      this.coreUtilities.saveLocal('core-' + this.businessAreaId + '-color-label-provider', colorLabelProvider);
    }
    /* Set the provider */
    this.setColorLabelProvider(colorLabelProvider, configuration);
    /* Update the tree nodes */
    AppGlobal.treeNodes = <OrderedMap<string, TreeNode>> AppGlobal.treeNodes.map(treeNode => {
      let colors;
      if (isNullOrUndefined(nodeType) && colorLabelProvider !== 'coloredRelations') {
        colors = this.colorLabelProvider.color(treeNode);
      } else {
        colors = this.colorLabelProvider.color(treeNode, nodeType, this.mcm);
      }
      if (!isNullOrUndefined(colors)) {
        treeNode.colors = colors;
      }
      return treeNode;
    });
    /* Update the observable */
    setTimeout(() => this.treeNodes.next(AppGlobal.treeNodes));
    return this.colorLabelProvider;
  }

  public setColorLabelProviderColors(update = true) {
    /* Update the tree nodes */
    AppGlobal.treeNodes = <OrderedMap<string, TreeNode>> AppGlobal.treeNodes.map(treeNode => {
      if (treeNode.modelId !== this.mcm && !isNullOrUndefined(this.colorLabelProvider)) {
        const colors = this.colorLabelProvider.color(treeNode);
        if (!isNullOrUndefined(colors)) {
          treeNode.colors = colors;
        }
      }
      return treeNode;
    });
    /* Update the observable */
    if (update) {
      this.treeNodes.next(AppGlobal.treeNodes);
    }
  }

  /**
   * Toggle the legends
   * @param legend
   */
  public onLegendToggled(legend: CoreLegend) {
    this.toggledLegends = this.coreUtilities.toggleInArray(this.toggledLegends, legend.key);
    this.treeNodes.next(this.treeNodes.getValue());
    this.treeRelationships.next(this.setTRTRelationships(this.treeRelationships.getValue()));
  }

  /**
   * Toggle the node types
   * @param nodeType
   */
  public onNodeTypeToggled(nodeType: number) {
    this.filteredNodeTypes = this.coreUtilities.toggleInArray(this.filteredNodeTypes, nodeType);
    this.treeNodes.next(this.treeNodes.getValue());
    this.treeRelationships.next(this.setTRTRelationships(this.treeRelationships.getValue()));
  }

  /**
   * Set the toggled node types
   * @param nodeTypes
   */
  public setFilteredNodeTypes(nodeTypes: number[]) {
    this.filteredNodeTypes = nodeTypes;
  }

  /**
   * Get the import element
   *
   * @param {string} modelId
   */
  public getImport(modelId: string): ImportData {
    const model = this.getInstantModels().filter(m => m.id === modelId)[0];
    const businessAreaId = model.relationships.businessarea;
    const exportModel = Object.assign(model.toJS(), { treeNodes: [], treeRelationships: [], treeActivities: [], businessareaId: model.relationships.businessarea });
    return {
      key: 'import-model',
      modelId: parseInt(modelId),
      models: [exportModel],
      groups: [],
      groupHumanResources: {},
      humanResources: [],
      businessAreaId: businessAreaId
    } as ImportData;
  }

  /**
   * Get activities with a set of options
   * @param options
   *    filter      (string)  a filter key either a field of TreeActivity or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   */
  public getActivities(options?: CoreOptions): Observable<Array<TreeActivity>> {
    if (isNullOrUndefined(this.businessAreaId)) {
      this.subscriptionService.add('activities', this.activityService.all().subscribe(activities => {
        this.onDataLoaded([List(), List(), List(), activities, List()], false, true);
      }));
    }
    return this.getActivitiesBy(this.treeActivities.map(treeActivities => {
      if (isNullOrUndefined(treeActivities)) {
        return treeActivities;
      }
      return <OrderedMap<string, TreeActivity>>treeActivities.filter(treeActivity => !treeActivity.nodebucket);
    }), options);
  }

  /**
   * Get node buckets
   * @param {CoreOptions} options
   * @returns {Observable<Array<TreeActivity>>}
   */
  public getNodeBuckets(options?: CoreOptions): Observable<Array<TreeActivity>> {
    return this.getActivitiesBy(this.treeActivities.map(treeActivities => {
      if (isNullOrUndefined(treeActivities)) {
        return treeActivities;
      }
      return <OrderedMap<string, TreeActivity>>treeActivities.filter(treeActivity => treeActivity.nodebucket);
    }), options);
  }

  /**
   * Get activities in hierarchical order with a set of options
   * @param observable
   * @param options
   *    filter      (string)  a filter key either a field of TreeActivity or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   * @param deep
   */
  public getActivitiesBy(observable: Observable<OrderedMap<string, TreeActivity> | TreeActivity[]>, options?: CoreOptions, deep = true): Observable<Array<TreeActivity>> {
    return observable.filter(d => !!d).map(treeActivitiesMap => {
      /* First of all convert map to array */
      let treeActivities = isArray(treeActivitiesMap) ? treeActivitiesMap : treeActivitiesMap.toArray();
      /* Filter the tree nodes if a filter is specified */
      if (!isNullOrUndefined(options) && !isNullOrUndefined(options.filters)) {
        treeActivities = this.filterActivities(treeActivities, options.filters);
      }
      /* Return the tree nodes */
      return treeActivities;
    });
  }

  /**
   * Filter an array of tree activities via key and value combination
   * @param treeActivities
   * @param filters
   */
  public filterActivities(treeActivities: TreeActivity[], filters: CoreFilter[]) {
    return treeActivities.filter(treeActivity => !!treeActivity && this.filterActivity(treeActivity, filters));
  }

  /**
   * Filter a node by presets or key value combination
   * @param treeActivity
   * @param filters
   * @param store
   */
  public filterActivity(treeActivity: TreeActivity, filters: CoreFilter[], store = false): boolean {
    let visible = true;
    const count = filters.length;
    for (let i = 0; i < count; i++) {
      const filter = filters[i];
      switch (filter.by) {
        default:
          visible = isArray(filter.value) ? filter.value.filter(value => treeActivity[filter.by] === value).length > 0 : treeActivity[filter.by] === filter.value;
          break;
      }
      if (!visible) {
        break;
      }
    }
    return visible;
  }

  /**
   * Set the structure at the current point in time
   *
   * @param {TreeNode} treeNode
   * @param processed
   * @returns {TreeNode}
   */
  public setStructure(treeNode: TreeNode, processed = {}): TreeNode {
    if (processed[treeNode.id] !== undefined) {
      return processed[treeNode.id];
    }
    /* Get the parents */
    const parents = treeNode.parents;
    const parentsWithoutGF = treeNode.parentsWithoutGF;
    const unfilteredParents = treeNode.unfilteredParents;
    /* Get the children */
    const children = treeNode.children;
    const childrenWithoutGF = treeNode.childrenWithoutGF;
    const unfilteredChildren = treeNode.unfilteredChildren;
    /* Clone tree node */
    const clonedTreeNode = Object.assign({}, treeNode);
    /* Clear the parents */
    clonedTreeNode.parents = [];
    clonedTreeNode.parentsWithoutGF = [];
    clonedTreeNode.unfilteredParents = [];
    clonedTreeNode.parentIds = [];
    clonedTreeNode.unfilteredParentIds = [];
    /* Clear the children */
    clonedTreeNode.children = [];
    clonedTreeNode.childrenWithoutGF = [];
    clonedTreeNode.unfilteredChildren = [];
    clonedTreeNode.childIds = [];
    clonedTreeNode.unfilteredChildIds = [];
    /* Set the parents */
    let count = parents.length;
    for (let i = 0; i < count; i++) {
      clonedTreeNode.parents.push(this.setStructure(parents[i], processed));
    }
    /* Set processed */
    processed[clonedTreeNode.id] = clonedTreeNode;
    /* Set the parents without GF */
    count = parentsWithoutGF.length;
    for (let i = 0; i < count; i++) {
      clonedTreeNode.parentsWithoutGF.push(this.setStructure(parentsWithoutGF[i], processed));
    }
    /* Set processed */
    processed[clonedTreeNode.id] = clonedTreeNode;
    /* Set the unfiltered parents */
    count = unfilteredParents.length;
    for (let i = 0; i < count; i++) {
      clonedTreeNode.unfilteredParents.push(this.setStructure(unfilteredParents[i], processed));
    }
    /* Set processed */
    processed[treeNode.id] = clonedTreeNode;
    /* Set the children */
    count = children.length;
    for (let i = 0; i < count; i++) {
      clonedTreeNode.children.push(this.setStructure(children[i], processed));
    }
    /* Set processed */
    processed[clonedTreeNode.id] = clonedTreeNode;
    /* Set the children without GF */
    count = children.length;
    for (let i = 0; i < count; i++) {
      clonedTreeNode.childrenWithoutGF.push(this.setStructure(childrenWithoutGF[i], processed));
    }
    /* Set processed */
    processed[clonedTreeNode.id] = clonedTreeNode;
    /* Set the unfiltered children */
    count = unfilteredChildren.length;
    for (let i = 0; i < count; i++) {
      clonedTreeNode.unfilteredChildren.push(this.setStructure(unfilteredChildren[i], processed));
    }
    /* Set processed */
    processed[clonedTreeNode.id] = clonedTreeNode;
    /* Return tree node */
    return clonedTreeNode;
  }

  /**
   * Create business area, models, nodes and relationships
   * @param transfer
   * @param callback
   * @param isTransfer
   * @param additionalTransfer
   * @param transferOptions
   */
  public create(transfer: CoreTransfer, callback?: Function, isTransfer = false, additionalTransfer = false, transferOptions?: TransferOptions) {
    let additional: CoreMultiTransfer;
    this.lastTransfer = {create: transfer, update: undefined, delete: undefined};
    /* Modify transfer */
    const mod = this.modifyTransferByType(transfer, NODES_TYPE_ADD, transferOptions);
    transfer = mod.transfer;
    additional = mod.additionalTransfer;
    /* Store the transfer */
    if (!additionalTransfer) {
      this.storeTransfer('create', transfer);
    }
    /* General in progress */
    this.transferInProgress = true;
    /* Callback */
    const _callback = (result?: any) => {
      if (callback !== undefined) {
        callback(result);
      }
    };
    /* New Listener */
    this.afterTransferEmitter.take(1).subscribe(() => _callback());

    /* Check what needs to be created */
    if (!isNullOrUndefined(transfer.businessArea)) {
      this.createByBusinessArea(transfer, result => _callback(result), undefined, additional);
    } else if (!isNullOrUndefined(transfer.models) && transfer.models.length > 0) {
      this.createByModels(transfer, result => _callback(result), undefined, additional);
    } else if (!isNullOrUndefined(transfer.activities) && transfer.activities.length > 0) {
      this.createActivities(transfer, result => _callback(result), additional);
    } else if (!isNullOrUndefined(transfer.humanResources) && transfer.humanResources.length > 0 && !isNullOrUndefined(transfer.groups) && transfer.groups.length > 0) {
      this.createHumanResourcesAndGroupsAndNodes(transfer, result => _callback(result), additional);
    } else if (!isNullOrUndefined(transfer.humanResources) && transfer.humanResources.length > 0) {
      this.createHumanResourcesAndNodes(transfer, result => _callback(result), undefined, additional);
    } else if (!isNullOrUndefined(transfer.groups) && transfer.groups.length > 0) {
      this.createGroupsAndNodes(transfer, result => _callback(result), undefined, additional);
    } else if (!isNullOrUndefined(transfer.nodeStructures) && transfer.nodeStructures.length > 0) {
      this.createGrouping(transfer, result => _callback(result), undefined, additional);
    } else if (!isNullOrUndefined(transfer.nodes) || !isNullOrUndefined(transfer.relationships)) {
      this.createNodesAndRelationshipsByModel(transfer, result => {
        if (additionalTransfer) {
          _callback(result);
        }
      }, additional);
    }
  }

  /**
   * Update nodes and relationships
   * @param data
   * @param isTransfer
   * @param additionalUpdate
   * @param transferOptions
   */
  public update(data: CoreTransfer, isTransfer = false, additionalUpdate = false, transferOptions?: TransferOptions): Promise<any> {
    /* Additional way */
    // this.lastTransfer = {create: undefined, update: data, delete: undefined};
    if (!additionalUpdate) {
      this.storeTransfer('update', data);
    }
    this.transferInProgress = true;
    return new Promise<any>(resolve => {

      /* Subscribe if no additional */
      if (!additionalUpdate) {
        this.afterTransferEmitter.take(1).subscribe(() => resolve());
      }

      /* Transfer after success */
      let transferAfterSuccess: CoreMultiTransfer;

      /* Modify transfer */
      const mod = this.modifyTransferByType(data, NODES_TYPE_UPDATE, transferOptions);
      data = mod.transfer;

      /* Update human resources */
      if (!isNullOrUndefined(data.humanResources)) {
        /* Register listener */
        this.humanResourceService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>data.humanResources, HumanResourceAction.UPDATE_SUCCESS)).take(1).subscribe(() => {
          resolve();
        });
        if (data.humanResources.length > 0) {
          this.humanResourceService.updateGo(<IPayload[]>data.humanResources);
        }
      }

      /* Update groups */
      if (!isNullOrUndefined(data.groups) && data.groups.length > 0) {
        /* Register listener */
        this.groupService.diff.filter(diff => {
          return !!diff && diff.action === GroupAction.UPDATE_SUCCESS;
        }).take(1).subscribe(diff => {
          resolve();
        });
        const groupsPayload = (data.groups as IPayload[]).map(payload => {
          if (payload.data.attributes !== undefined && payload.data.attributes.has('bestCase')) {
            const bestCase = payload.data.attributes.get('bestCase');
            if (isBoolean(bestCase)) {
              payload.data.attributes = payload.data.attributes.set('bestCase', bestCase === true ? 1 : 0);
            }
          }
          return payload;
        });
        this.groupService.update(groupsPayload);
      }

      let dataUpdated = true;
      let structureUpdated = true;

      /* Update Nodes */
      if (!isNullOrUndefined(data.nodes) && data.nodes.length > 0) {
        dataUpdated = false;
        /* Register listener */
        this.nodeDataService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>data.nodes, NodeDataAction.UPDATE_SUCCESS)).take(1).subscribe((diff) => {
          dataUpdated = true;
          if (isNullOrUndefined(data.nodeStructures) || data.nodeStructures.length === 0 || (additionalUpdate && structureUpdated)) {
            if (transferAfterSuccess !== undefined) {
              this.transfer(transferAfterSuccess).then(() => {
                resolve();
              });
            } else {
              resolve();
            }
          } else {
            resolve();
          }
        });

        if (additionalUpdate) {
          this.nodeDataService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>data.nodes, NodeDataAction.UPDATE_FAIL)).take(1).subscribe((diff) => {
            dataUpdated = false;
            resolve();
          });
        }

        if (this.humanResourceHandling && (<IPayload[]> data.nodes).filter(payload => isNullOrUndefined(payload.data.has) ? !isNullOrUndefined(payload.data.responsibleId) : (payload.data as Map<string, any>).has('responsibleId')).length > 0) {
          /* Search for the node */
          const dataIds = (<IPayload[]> data.nodes).map(payload => payload.id);
          const affectedTreeNodes: TreeNode[] = [];
          /* Search for human resource node */
          const humanResourceIds = (<IPayload[]> data.nodes).map(payload => isNullOrUndefined(payload.data.has) ? payload.data.responsibleId : (payload.data as Map<string, any>).get('responsibleId'));
          const humanResourceTreeNodes: TreeNode[] = [];
          /* Iterate over tree nodes */
          const treeNodes = AppGlobal.treeNodes.toArray();
          const count = treeNodes.length;
          for (let i = 0; i < count; i++) {
            const treeNode = treeNodes[i];
            if (dataIds.indexOf(treeNode.dataId) !== -1 && treeNode.nodeType !== NODES_TYPE_HUMANRESOURCE) {
              affectedTreeNodes.push(treeNode);
            }
            if (treeNode.modelId === data.modelId && treeNode.nodeType === NODES_TYPE_HUMANRESOURCE && humanResourceIds.indexOf('' + treeNode.responsibleId) !== -1) {
              humanResourceTreeNodes.push(treeNode);
            }
          }

          /* Check the result */
          if (!additionalUpdate && affectedTreeNodes.length > 0) {
            transferAfterSuccess = this.coreUtilities.getTransfer(data.modelId);
            /* Connect */
            const count1 = affectedTreeNodes.length;
            for (let i1 = 0; i1 < count1; i1++) {
              const affectedTreeNode = affectedTreeNodes[i1];
              /* Find a potential parent */
              if (affectedTreeNode.responsibleId !== null && affectedTreeNode.responsibleId !== undefined) {
                if (this.humanResourceHandlingParents) {
                  const existingHumanResourceParents = affectedTreeNode.parents.filter(parentTreeNode => parentTreeNode.responsibleId === affectedTreeNode.responsibleId && parentTreeNode.nodeType === NODES_TYPE_HUMANRESOURCE).map(parentTreeNode => parentTreeNode.id);
                  transferAfterSuccess.delete.relationships = (transferAfterSuccess.delete.relationships as TreeRelationship[]).concat(this.getInstantRelationships({ filters: [{ by: 'parentId', value: existingHumanResourceParents }, { by: 'childId', value: affectedTreeNode.id }] }));
                } else {
                  const existingHumanResourceChildren = affectedTreeNode.children.filter(childTreeNode => childTreeNode.responsibleId === affectedTreeNode.responsibleId && childTreeNode.nodeType === NODES_TYPE_HUMANRESOURCE).map(childTreeNode => childTreeNode.id);
                  transferAfterSuccess.delete.relationships = (transferAfterSuccess.delete.relationships as TreeRelationship[]).concat(this.getInstantRelationships({ filters: [{ by: 'childId', value: existingHumanResourceChildren }, { by: 'parentId', value: affectedTreeNode.id }] }));
                }
              }
              const count2 = humanResourceTreeNodes.length;
              for (let i2 = 0; i2 < count2; i2++) {
                const humanResourceTreeNode = humanResourceTreeNodes[i2];
                /* Create new relationship */
                if (this.humanResourceHandlingParents) {
                  (transferAfterSuccess.create.relationships as TreeRelationship[]).push({ id: UUID.UUID(), parentId: humanResourceTreeNode.id, childId: affectedTreeNode.id } as TreeRelationship);
                } else {
                  (transferAfterSuccess.create.relationships as TreeRelationship[]).push({ id: UUID.UUID(), parentId: affectedTreeNode.id, childId: humanResourceTreeNode.id } as TreeRelationship);
                }
              }
            }
          }
        }

        this.nodeDataService.updateGo((<IPayload[]> data.nodes).map(payload => {
          payload.data = this.prepareDelta(payload.data);
          return payload;
        }));
      }

      /* Update Node structures */
      if (!isNullOrUndefined(data.nodeStructures) && data.nodeStructures.length > 0) {
        if (additionalUpdate) {
          structureUpdated = false;
          this.nodeStructureService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>data.nodeStructures, NodeStructureAction.UPDATE_SUCCESS)).take(1).subscribe(() => {
            structureUpdated = true;
            if (isNullOrUndefined(data.nodes) || data.nodes.length === 0 || dataUpdated) {
              resolve();
            }
          });
          this.nodeStructureService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>data.nodeStructures, NodeStructureAction.UPDATE_FAIL)).take(1).subscribe(() => {
            resolve();
          });
        }
        this.nodeStructureService.updateGo(<IPayload[]>data.nodeStructures);
      }

      /* Update Relationships */
      if (!isNullOrUndefined(data.relationships) && data.relationships.length > 0) {
        if (additionalUpdate) {
          /* Register listener */
          this.relationshipService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>data.relationships, RelationshipAction.UPDATE_SUCCESS)).take(1).subscribe(() => {
            resolve();
          });
        }
        this.relationshipService.updateGo(<IPayload[]>data.relationships);
      }

      /* Update Activities */
      if (!isNullOrUndefined(data.activities) && data.activities.length > 0) {
        if (additionalUpdate) {
          /* Register listener */
          this.activityService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>data.activities, ActivityAction.UPDATE_SUCCESS)).take(1).subscribe(() => {
            resolve();
          });
          this.activityService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>data.activities, ActivityAction.UPDATE_FAIL)).take(1).subscribe(() => {
            resolve();
          });
        }
        this.activityService.updateGo(<IPayload[]>data.activities);
      }

      /* Update Business area */
      if (!isNullOrUndefined(data.businessArea)) {
        /* Register listener */
        this.businessAreaService.diff.filter(diff => !!diff && diff.action === BusinessareaAction.UPDATE_SUCCESS).take(1).subscribe(() => {
          resolve();
        });
        this.businessAreaService.updateGo(data.businessArea);
      }

      /* Update models */
      if (!isNullOrUndefined(data.models)) {
        /* Register listener */
        this.modelService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>data.models, ModelAction.UPDATE_SUCCESS)).take(1).subscribe(() => {
          resolve();
        });
        this.modelService.updateGo(data.models);
      }

    });
  }

  /**
   * Delete nodes and relationships
   * @param data
   * @param isTransfer
   * @param transfers
   * @param additionalUpdate
   */
  public delete(data: CoreTransfer, isTransfer = false, transfers?: CoreMultiTransfer, additionalUpdate = false) {
    // this.lastTransfer = {create: undefined, update: undefined, delete: data};
    if (!additionalUpdate) {
      this.storeTransfer('delete', data);
    }
    this.transferInProgress = true;
    return new Promise<any>(resolve => {

      if ((isNullOrUndefined(data.nodes) || data.nodes.length === 0) &&
        (isNullOrUndefined(data.relationships) || data.relationships.length === 0) &&
        (isNullOrUndefined(data.activities) || data.activities.length === 0) &&
        (isNullOrUndefined(data.humanResources) || data.humanResources.length === 0) &&
        (isNullOrUndefined(data.models) || data.models.length === 0) &&
        (isNullOrUndefined(data.groups) || data.groups.length === 0)) {
        resolve();
        return;
      }
      const uuid = UUID.UUID();
      this.subscriptionService.add(uuid, this.errorReporter.errorEmitter.pipe(take(1)).subscribe(() => {
        /* Clear it */
        this.updateAfterTransfer = {
          globalFilter: false,
          nodes: false,
          colorLabelProvider: false,
          relationships: undefined,
          activities: false
        };
        this.lastTransfer = undefined;
        /* Send event */
        this.afterTransferEmitter.emit();
        resolve();
      }));
      if (!additionalUpdate) {
        this.afterTransferEmitter.take(1).subscribe(() => {
          this.subscriptionService.remove(uuid);
          resolve();
        });
      }

      /* Modify transfer */
      const mod = this.modifyTransferByType(data, NODES_TYPE_DELETE);
      data = mod.transfer;

      /* Remove Nodes */
      if (!isNullOrUndefined(data.nodes) && data.nodes.length > 0) {
        if (additionalUpdate) {
          /* Register listener */
          this.nodeStructureService.diff.filter(diff => this.requestWasSuccessful(diff, ids, NodeStructureAction.REMOVE_SUCCESS)).take(1).subscribe(() => {
            this.subscriptionService.remove(uuid);
            resolve();
          });
          this.nodeStructureService.diff.filter(diff => this.requestWasSuccessful(diff, ids, NodeStructureAction.REMOVE_FAIL)).take(1).subscribe(() => {
            this.subscriptionService.remove(uuid);
            resolve();
          });
        }
        /* Ids */
        const ids = (<TreeNode[]>data.nodes).map(node => isString(node) ? node : node.id);
        this.nodeStructureService.removeGo(ids);
      }

      let additionalTransfer: CoreMultiTransfer;

      /* Remove Relationships */
      if (!isNullOrUndefined(data.relationships) && data.relationships.length > 0) {
        const ids = (<TreeRelationship[]>data.relationships).map(relationship => isString(relationship) ? relationship : relationship.id);
        if (this.humanResourceHandling) {
          const count = data.relationships.length;
          for (let i = 0; i < count; i++) {
            /* Allow update */
            let allowUpdate = true;
            /* Relationship */
            let relationship = data.relationships[i] as TreeRelationship;
            if (typeof relationship === 'string') {
              relationship = AppGlobal.treeRelationships.get(relationship);
            }
            if (relationship === undefined) {
              continue;
            }
            /* Get the nodes */
            const parent = AppGlobal.treeNodes.get(relationship.parentId);
            const child = AppGlobal.treeNodes.get(relationship.childId);
            /* Get the human resource node */
            const humanResourceNode = parent.nodeType === NODES_TYPE_HUMANRESOURCE ? parent : (child.nodeType === NODES_TYPE_HUMANRESOURCE ? child : undefined);
            const regularNode = parent.nodeType === NODES_TYPE_HUMANRESOURCE ? child : (child.nodeType === NODES_TYPE_HUMANRESOURCE ? parent : undefined);
            if (humanResourceNode === undefined) {
              continue;
            }
            /* Check if the same nodes are affected by create */
            if (transfers !== undefined && transfers.create.relationships !== undefined) {
              /* Iterate over relationships to figure out if one of the same nodes is affected */
              const count2 = transfers.create.relationships.length;
              for (let i2 = 0; i2 < count2; i2++) {
                const createRelationship = (transfers.create.relationships[i2] as TreeRelationship);
                const createParent = AppGlobal.treeNodes.get(createRelationship.parentId);
                const createChild = AppGlobal.treeNodes.get(createRelationship.childId);
                if ((createParent.nodeType === NODES_TYPE_HUMANRESOURCE && createChild.id === child.id) || (createChild.nodeType === NODES_TYPE_HUMANRESOURCE && createParent.id === child.id)) {
                  allowUpdate = false;
                }
              }
            }
            /* If allow update */
            if (allowUpdate && regularNode !== undefined) {
              if (additionalTransfer === undefined) {
                additionalTransfer = this.coreUtilities.getTransfer();
              }
              /* Check if the responsible id is event matching */
              if (regularNode.responsibleId === humanResourceNode.responsibleId) {
                (additionalTransfer.update.nodes as IPayload[]).push({ id: regularNode.dataId, data: Map().set('responsibleId', null) });
              }
            }
          }
        }
        if (additionalUpdate) {
          /* Register listener */
          this.relationshipService.diff.filter(diff => this.requestWasSuccessful(diff, ids, RelationshipAction.REMOVE_SUCCESS)).take(1).subscribe(diff => {
            this.subscriptionService.remove(uuid);
            resolve();
          });
          this.relationshipService.diff.filter(diff => this.requestWasSuccessful(diff, ids, RelationshipAction.REMOVE_FAIL)).take(1).subscribe(() => {
            this.subscriptionService.remove(uuid);
            resolve();
          });
        }
        if (additionalTransfer !== undefined) {
          this.transfer(additionalTransfer, true).then(() => {
            this.relationshipService.removeGo(ids);
          });
        } else {
          this.relationshipService.removeGo(ids);
        }
      }

      /* Remove Activities */
      if (!isNullOrUndefined(data.activities) && data.activities.length > 0) {
        if (additionalUpdate) {
          /* Register listener */
          this.activityService.diff.filter(diff => this.requestWasSuccessful(diff, ids, ActivityAction.REMOVE_SUCCESS)).take(1).subscribe(() => {
            this.subscriptionService.remove(uuid);
            resolve();
          });
          this.activityService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>data.activities, ActivityAction.REMOVE_FAIL)).take(1).subscribe(() => {
            this.subscriptionService.remove(uuid);
            resolve();
          });
        }
        const ids = (<TreeActivity[]>data.activities).map(activity => activity.id);
        this.activityService.removeGo(ids);
      }

      /* Delete human resources */
      if (!isNullOrUndefined(data.humanResources)) {
        /* Register listener */
        this.humanResourceService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>data.humanResources, HumanResourceAction.REMOVE_SUCCESS)).take(1).subscribe(() => {
          this.subscriptionService.remove(uuid);
          resolve();
        });
        this.humanResourceService.removeGo(<string[]>data.humanResources);
      }

      /* Delete groups */
      if (!isNullOrUndefined(data.groups)) {
        /* Register listener */
        this.groupService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>data.groups, GroupAction.REMOVE_SUCCESS)).take(1).subscribe(() => {
          this.subscriptionService.remove(uuid);
          resolve();
        });
        this.groupService.remove(<string[]>data.groups);
      }

      /* Delete models */
      if (!isNullOrUndefined(data.models)) {
        /* Register listener */
        this.modelService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>data.models, ModelAction.REMOVE_SUCCESS)).take(1).subscribe(() => {
          this.subscriptionService.remove(uuid);
          resolve();
        });
        this.modelService.removeGo((data.models as any[]).map(model => '' + model.id));
      }

    });
  }

  public transfer(transfers: CoreMultiTransfer, additionalTransfer = false, transferOptions?: TransferOptions) {
    this.transferInProgress = true;
    return new Promise(resolve => {
      if (AppGlobal.byToken) {
        this.oneTimeTokenService.diff.filter(diff => diff.action === OneTimeTokenAction.TRANSFER_BY_TOKEN_SUCCESS || diff.action === OneTimeTokenAction.TRANSFER_BY_TOKEN_FAIL).take(1).subscribe(diff => {
          resolve();
        });
        transfers.create.modelId = '' + transfers.create.modelId;
        transfers.update.modelId = '' + transfers.update.modelId;
        transfers.delete.modelId = '' + transfers.delete.modelId;
        this.storeTransfer('create', transfers.create);
        this.storeTransfer('update', transfers.update);
        this.storeTransfer('delete', transfers.delete);
        this.oneTimeTokenService.transferByToken(AppGlobal.token, transfers);
        return;
      }

      /* The response count */
      let responseCount = 0;
      /* The calls count */
      const callsCount = this.getCallsCount(transfers);

      const hasCreate = this.checkTransfers(transfers, 'create');
      if (transfers.waitForCreate && !hasCreate) {
        transfers.waitForCreate = false;
      }
      const uuid = UUID.UUID();
      this.subscriptionService.add(uuid, this.errorReporter.errorEmitter.pipe(take(1)).subscribe(() => {
        /* Clear it */
        this.updateAfterTransfer = {
          globalFilter: false,
          nodes: false,
          colorLabelProvider: false,
          relationships: undefined,
          activities: false
        };
        this.lastTransfer = undefined;
        /* Send event */
        this.afterTransferEmitter.emit();
        resolve();
      }));
      /* Create */
      if (hasCreate) {
        this.create(transfers.create, () => {
          responseCount++;
          /* Update */
          if (this.checkTransfers(transfers, 'update') && transfers.waitForCreate === true) {
            this.update(transfers.update, true, additionalTransfer, transferOptions).then(() => {
              responseCount++;
              if (responseCount === callsCount) {
                this.subscriptionService.remove(uuid);
                resolve();
              }
            });
          } else {
            this.subscriptionService.remove(uuid);
            resolve();
          }
        }, true, additionalTransfer, transferOptions);
      }

      /* Update */
      if (this.checkTransfers(transfers, 'update') && transfers.waitForCreate !== true) {
        this.update(transfers.update, false, additionalTransfer, transferOptions).then(() => {
          responseCount++;
          if (responseCount === callsCount) {
            this.subscriptionService.remove(uuid);
            resolve();
          }
        });
      }

      /* Delete */
      if (this.checkTransfers(transfers, 'delete')) {
        this.delete(transfers.delete, true, transfers, additionalTransfer).then(() => {
          responseCount++;
          if (responseCount === callsCount) {
            this.subscriptionService.remove(uuid);
            resolve();
          }
        });
      }
      if (callsCount === 0) {
        this.subscriptionService.remove(uuid);
        resolve();
      }

    });
  }

  private afterTransfer() {
    this.transferInProgress = false;
    this.lastTransfer = undefined;
    this.lastTransferExpectations = undefined;
    if (this.updateAfterTransfer.activities) {
      this.treeActivities.next(AppGlobal.treeActivities);
    }
    if (this.updateAfterTransfer.nodes) {
      this.treeNodes.next(AppGlobal.treeNodes);
    }
    if (this.updateAfterTransfer.relationships !== undefined) {
      this.treeRelationships.next(this.setTRTRelationships(this.updateAfterTransfer.relationships, true));
      if (!this.updateAfterTransfer.nodes) {
        this.treeNodes.next(AppGlobal.treeNodes);
      }
    }
    if (this.updateAfterTransfer.globalFilter) {
      this.updateGlobalFilters();
    }
    if (this.updateAfterTransfer.colorLabelProvider) {
      this.setColorLabelProviderColors();
    }
    /* Clear it */
    this.updateAfterTransfer = {
      globalFilter: false,
      nodes: false,
      colorLabelProvider: false,
      relationships: undefined,
      activities: false
    };
    /* Send event */
    this.afterTransferEmitter.emit();
  }

  public checkTransfers(transfers: CoreMultiTransfer, type: string) {
    if (!isNullOrUndefined(transfers[type].nodes) && transfers[type].nodes.length > 0) {
      return true;
    }
    if (!isNullOrUndefined(transfers[type].nodeStructures) && transfers[type].nodeStructures.length > 0) {
      return true;
    }
    if (!isNullOrUndefined(transfers[type].activities) && transfers[type].activities.length > 0) {
      return true;
    }
    if (!isNullOrUndefined(transfers[type].relationships) && transfers[type].relationships.length > 0) {
      return true;
    }
    if (!isNullOrUndefined(transfers[type].humanResources) && transfers[type].humanResources.length > 0) {
      return true;
    }
    if (!isNullOrUndefined(transfers[type].groups) && transfers[type].groups.length > 0) {
      return true;
    }
    return false;
  }

  public getCallsCount(transfers: CoreMultiTransfer): number {
    let count = 0;
    const types = ['create', 'update', 'delete'];
    for (let i = 0; i < 3; i++) {
      const type = types[i];
      let empty = true;
      if (transfers[type].businessArea !== undefined && transfers[type].businessArea.length > 0) {
        empty = false;
      }
      if (empty && transfers[type].models !== undefined && transfers[type].models.length > 0) {
        empty = false;
      }
      if (empty && transfers[type].nodes !== undefined && transfers[type].nodes.length > 0) {
        empty = false;
      }
      if (empty && transfers[type].activities !== undefined && transfers[type].activities.length > 0) {
        empty = false;
      }
      if (empty && transfers[type].relationships !== undefined && transfers[type].relationships.length > 0) {
        empty = false;
      }
      if (empty && transfers[type].humanResources !== undefined && transfers[type].humanResources.length > 0) {
        empty = false;
      }
      if (empty && transfers[type].groups !== undefined && transfers[type].groups.length > 0) {
        empty = false;
      }
      if (empty && transfers[type].nodeStructures !== undefined && transfers[type].nodeStructures.length > 0) {
        empty = false;
      }
      if (!empty) {
        count++;
      }
    }
    return count;
  }

  public grouping(transfer: CoreTransfer) {
    return new Promise(resolve => this.createNodesAndRelationships(transfer, () => resolve(), true));
  }

  public getWorkflow(workflowId: string, load = false) {
    if (load) {
      this.workflowService.load(workflowId);
    }
    return this.workflowService.all().filter(items => items.filter(item => item instanceof Node && item.workFlowId === workflowId).size > 0).map(items => {
      let nodes = List<Node>();
      let relationships = List<Relationship>();
      items.forEach(item => {
        if (item instanceof Node) {
          nodes = nodes.push(item);
        } else if (item instanceof Relationship) {
          relationships = relationships.push(item);
        }
      });
      return this.onDataLoaded([[], nodes, relationships, [], []], true).treeNodesMap.filter(treeNode => treeNode.nodeType === NODES_TYPE_WORKFLOW).first();
    });
  }

  /**
   * Get the form by id
   * @param formId
   * @param load
   */
  public getForm(formId: string, load = false) {
    if (load) {
      this.formApiService.load(formId);
    }
    return this.formApiService.all().map(items => {
      let nodes = List<Node>();
      let relationships = List<Relationship>();
      items.forEach(item => {
        if (item instanceof Node) {
          nodes = nodes.push(item);
        } else if (item instanceof Relationship) {
          relationships = relationships.push(item);
        }
      });
      const startNode = this.onDataLoaded([[], nodes, relationships, []], true).treeNodesMap.filter(treeNode => treeNode.nodeType === NODES_TYPE_FORM).first();
      const form = { tabs: [] };
      if (!isNullOrUndefined(startNode)) {
        let i = 1;
        startNode.children.sort((a, b) => a.positionX - b.positionX).forEach((tabTreeNode) => {
          if (tabTreeNode.nodeType === NODES_TYPE_FORM_TAB) {
            form.tabs.push({ entry: { key: 'header-' + i++, label: tabTreeNode.name }, children: this.formService.buildFormEntries(tabTreeNode) });
          }
        });
      }
      return form;
    });
  }

  /**
   * Get instances by hierarchy service
   * @param load
   */
  public getInstances(load = false) {
    if (load) {
      this.hierarchyService.loadActions();
    }
    return this.hierarchyService.all().map(hierarchies => hierarchies.filter((hierarchy: Hierarchy) => hierarchy.instanceType !== INSTANCE_TYPE_LIBRARY).map((hierarchy: Hierarchy) => this.coreTransformer.hierarchyToCoreInstance(hierarchy)));
  }

  /**
   * Load the hierarchy
   */
  public loadHierarchy() {
    this.hierarchyService.loadActions();
  }

  /**
   * Get a specific global modal
   * @param type
   */
  public getModal(type: string) {
    return this.modals.filter(modals => !!modals).map(modals => modals.filter((modal, key) => key === type).first());
  }

  /**
   * Set a specific global modal
   * @param type
   * @param modal
   */
  public setModal(type: string, modal: any) {
    let modals = this.modals.getValue();
    if (isNullOrUndefined(modals)) {
      modals = Map<string, any>();
    }
    this.modals.next(modals.set(type, modal));
  }

  public loadModal(id: string, path: string, resolve: Function) {
    this.dynamicModalFunction(id, path, resolve);
  }

  public setModalComponent(id: string, componentRef: ComponentRef<any>) {
    this.modalComponents = this.modalComponents.set(id, componentRef);
  }

  public clearModalComponent(id: string) {
    const componentRef: ComponentRef<any> = this.modalComponents.get(id);
    if (componentRef !== undefined) {
      componentRef.destroy();
    }
    this.modalComponents = this.modalComponents.remove(id);
  }

  /**
   * Add a modal to main component by lazy loading
   * @param type
   */
  public showModal(type: string) {
    this.openModal.emit(type);
  }

  /**
   * Open a modal dynamically
   * @param id
   * @param resolve
   */
  public modal(id: string, resolve) {
    this.getModal(id).filter(_ => !!_).take(1).subscribe((modal: any) => {
      this.removeModal(id);
      resolve(modal);
    });
    this.showModal(id);
  }

  /**
   * Remove the modal from list
   * @param type
   */
  public removeModal(type: string) {
    let modals = this.modals.getValue();
    if (isNullOrUndefined(modals)) {
      modals = Map<string, any>();
    }
    this.modals.next(modals.remove(type));
  }

  /**
   * Get the color label providers
   */
  public getColorLabelProviders(defaultProviders = false): CoreLabelProvider[] {
    return defaultProviders && this.defaultColorLabelProviders !== undefined ? this.defaultColorLabelProviders.concat(this.colorLabelProviders) : this.colorLabelProviders;
  }

  /**
   * Screenshot method
   */
  public screenshot(widget: string, filename = 'valueminer-screenshot', options: CoreExportOptions = { screenCSS: true, indicator: 'body' }, customExport?: string) {
    return new Promise<any>(resolve => {
      const configuration = {
        url: window.location.origin + window.location.pathname + '/print/' + widget + window.location.search,
        type: 'pdf',
        data: options
      };
      switch (customExport) {
        case 'knappmann-aufträge':
          configuration.data.indicator = '.page-manipulated';
          configuration.url += (configuration.url.indexOf('?') !== -1 ? '&' : '?') + 'custom=' + customExport;
          if (options.selected !== undefined) {
            configuration.url += '&selected=' + options.selected.join(',');
            delete options.selected;
          }
          break;
        case 'intep-ergebnis':
          configuration.data.indicator = '.page';
          configuration.url += (configuration.url.indexOf('?') !== -1 ? '&' : '?') + 'custom=' + customExport;
          if (options.selected !== undefined) {
            configuration.url += '&selected=' + options.selected.join(',');
            delete options.selected;
          }
          break;
        case 'save-token':
          configuration.data.indicator = 'body';
          configuration.url += (configuration.url.indexOf('?') !== -1 ? '&' : '?') + 'custom=' + customExport;
          if (options.selected !== undefined) {
            configuration.url += '&selected=' + options.selected.join(',');
            delete options.selected;
          }
      }
      this.exportService.configuration(configuration).filename(filename).puppeteer('.pdf').then(success => resolve(success));
    });
  }

  /**
   * Screenshot method for gantt
   */
  public screenshotGantt(widget: string, filename = 'valueminer-screenshot', width: number, height: number) {
    return new Promise<any>(resolve => {
      const configuration = {
        url: window.location.href + '/print/' + widget,
        type: 'screenshot',
        data: {imageType: 'png', width: width, height: height}
      };
      this.exportService.configuration(configuration).filename(filename).puppeteer('.png').then(success => resolve(success));
    });
  }

  /**
   * Export to Excel method for gantt
   */
  public exportExcel(widget: string, filename: string = 'valueminer-excel', excelConfig?: ExportExcel | any) {
    return new Promise<any>(resolve => {
      this.exportService.configuration({ type: 'excel', }).html(JSON.stringify(excelConfig)).filename(filename).excel().then(success => resolve(success));
    });
  }

  public decodeHtml(html) {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }

  public isHTML(str: string): boolean {
    const doc = new DOMParser().parseFromString(str, 'text/html');
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
  }

  public generateOneTimeToken(id: string | string[], formNodeId: string, showModal = true, actionNodeId?: string, dueDate?: string, restrictedIds?: string[]) {
    return new Promise(resolve => {
      const _id = isArray(id) ? id[0] : id;
      this.oneTimeTokenService.diff.filter(diff => !!diff && !!diff.action && (diff.action === OneTimeTokenAction.LOAD_SUCCESS || diff.action === OneTimeTokenAction.LOAD_FAIL) && '' + diff.response.elementId === '' + _id).subscribe(diff => {
        if (showModal) {
          /* Register listener */
          this.subscriptionService.add('modal', this.getModal('form-screen-modal').filter(_ => !!_).subscribe((modal: FormScreenModalComponent) => {
            this.subscriptionService.remove('modal');
            /* Register listener */
            this.subscriptionService.add('modal-form-screen', modal.cancel.subscribe(() => {
              this.subscriptionService.remove('modal-form-screen');
              resolve();
            }));
            /* Get the form node */
            modal.setFormNode(this.treeNodes.getValue().get(formNodeId));
            modal.show(diff.response);
          }));
          this.showModal('form-screen-modal');
        } else {
          resolve(diff.response);
        }
      });
      /* Call endpoint to get token */
      this.nodeStructureService.getOnetimeAccessToken(id, formNodeId, actionNodeId, dueDate, restrictedIds);
    });
  }

  public loadApp(appId: string, fields: CoreField[], token: string, mobileToken: boolean, callback: Function) {
    this.oneTimeTokenService.diff.filter(diff => diff.action === OneTimeTokenAction.LOAD_APP_SUCCESS || diff.action === OneTimeTokenAction.LOAD_APP_FAIL).take(1).subscribe(diff => {
      if (diff.action === OneTimeTokenAction.LOAD_APP_SUCCESS) {

        if (diff.response.url !== undefined && diff.response.url !== null && diff.response.url !== '') {
          window.location.replace(diff.response.url);
          return;
        }

        /* Set the nodes */
        let treeNodes = OrderedMap<string, TreeNode>();
        if (diff.response.nodes !== undefined) {
          const treeNodesArray = this.coreTransformer.nodesToTreeNodes(diff.response.nodes);
          const count1 = treeNodesArray.length;
          for (let i = 0; i < count1; i++) {
            const treeNode = treeNodesArray[i];
            treeNodes = treeNodes.set('' + treeNode.id, treeNode);
          }
        }
        AppGlobal.treeNodes = treeNodes;

        /* Set the relationships */
        let treeRelationships = OrderedMap<string, TreeRelationship>();
        let treeRelationshipsWeightMap = OrderedMap<string, number>();
        if (diff.response.relationships !== undefined) {
          const treeRelationshipsArray = this.coreTransformer.relationshipsToTreeRelationships(diff.response.relationships);
          const count2 = treeRelationshipsArray.length;
          for (let i = 0; i < count2; i++) {
            const treeRelationship = treeRelationshipsArray[i];
            treeRelationships = treeRelationships.set(treeRelationship.id, treeRelationship);
            treeRelationshipsWeightMap = treeRelationshipsWeightMap.set(treeRelationship.parentId + '-' + treeRelationship.childId, treeRelationship.weight);
          }
        }
        this.relationshipWeights.next(treeRelationshipsWeightMap);
        AppGlobal.treeRelationships = treeRelationships;

        /* Set the models */
        let treeModels = OrderedMap<string, Model>();
        const count = diff.response.models.length;
        for (let i = 0; i < count; i++) {
          const treeModel = this.coreTransformer.treeToModel(diff.response.models[i]);
          treeModels = treeModels.set(treeModel.id, treeModel);
        }
        const models = treeModels.toArray();
        if (models.length > 0) {
          this.onModelsLoaded(models);
        }

        /* Inform the others */
        this.treeNodes.next(treeNodes);
        this.clearCache(true);
        this.treeRelationships.next(this.setTRTRelationships(treeRelationships, true));

        /* Set custom color label provider */
        this.setCustomColorLabelProviders();

        /* Set color label provider */
        this.setColorLabelProvider(this.selectedColorLabelProvider);
        AppGlobal.treeNodes = AppGlobal.treeNodes.map(treeNode => {
          if (treeNode.modelId !== this.mcm && this.colorLabelProvider !== undefined) {
            /* Add color from color label provider */
            const colors = this.colorLabelProvider.color(treeNode);
            if (!isNullOrUndefined(colors)) {
              treeNode.colors = colors;
              treeNode.invertedColors = treeNode.colors.map(color => this.coreUtilities.invertColor(color, true));
            }
          }
          return treeNode;
        }) as OrderedMap<string, TreeNode>;
      }

      /* Store token */
      AppGlobal.token = diff.response.token;

      callback(AppGlobal.token);
    });
    this.oneTimeTokenService.loadApp(appId, fields, token, mobileToken);
  }

  public loadByToken(token: string, diffCallback?: any) {
    this.subscriptionService.add('token-failed', this.oneTimeTokenService.diff.filter(d => !!d).subscribe(response => {
      if (!isNullOrUndefined(diffCallback)) {
        diffCallback(response);
      }
    }));
    this.subscriptionService.add('loadByToken', Observable.combineLatest(
      this.humanResourceService.all(),
      this.nodeService.all(),
      this.relationshipService.all(),
    ).filter(d => d[1].size > 0).map(d => [d[0], d[1], d[2], [], []]).subscribe(data => this.onDataLoaded(data)));
    this.oneTimeTokenService.loadByToken(token);
  }

  public updateByToken(token: string, payload: IPayload, callback?: any) {
    return new Promise(resolve => {
      this.subscriptionService.add('update-token-diff', this.oneTimeTokenService.diff.filter(d => !!d && (d.action === OneTimeTokenAction.UPDATE_BY_TOKEN_SUCCESS || d.action === OneTimeTokenAction.UPDATE_BY_TOKEN_FAIL)).subscribe(response => {
        if (!isNullOrUndefined(callback)) {
          callback(response);
        }
        resolve(response);
      }));
      this.oneTimeTokenService.updateByToken(token, payload);
    });
  }

  public transferByToken(token: string, transfer: CoreMultiTransfer, callback?: any) {
    return new Promise(resolve => {
      this.subscriptionService.add('update-token-diff', this.oneTimeTokenService.diff.filter(d => !!d && (d.action === OneTimeTokenAction.TRANSFER_BY_TOKEN_SUCCESS || d.action === OneTimeTokenAction.TRANSFER_BY_TOKEN_FAIL)).subscribe(response => {
        if (!isNullOrUndefined(callback)) {
          callback(response);
        }
        resolve(response);
      }));
      this.oneTimeTokenService.transferByToken(token, transfer);
    });
  }

  /**
   * Send out an email
   *
   * @param recipient string email Id of the recipient or in the format - Name <emailId>
   * @param subject object containing the translation key and params for getting the subject of the email
   * @param message
   * @param from string email Id of the sender or in the format - Name <emailId>
   * @param cc
   */
  public sendEmail(recipient: string | string[], subject: string, message: string, from?: string, cc?: string[]) {
    return new Promise(resolve => {
      /* Recipient count */
      recipient = isArray(recipient) ? recipient : [recipient];
      const recipientCount = isArray(recipient) ? recipient.length : 1;
      let currentCount = 0;
      /* Response */
      this.subscriptionService.add('send-email', this.emailService.diff.filter(diff => !!diff && (diff.action === EmailAction.SEND_EMAIL_SUCCESS || diff.action === EmailAction.SEND_EMAIL_FAIL)).subscribe(diff => {
        if (recipientCount === ++currentCount) {
          this.subscriptionService.remove('send-email');
          resolve();
        }
      }));
      /* Send email */
      const count = recipient.length;
      for (let i = 0; i < count; i++) {
        /* Send email */
        this.emailService.sendEmail([<Email>{
          recipient: recipient[i],
          message: message,
          subject: subject,
          from: from,
          cc: i > 0 ? undefined : cc,
        }]);
      }
    });
  }

  public socketNodeStructuresUpdated(data: CoreSocketData) {
    this.nodeDataService.autorefreshCreate({ request: {}, response: data.included.filter(datum => datum.type === 'nodedata') });
    this.relationshipService.autorefreshCreate({ request: {}, response: data.included.filter(datum => datum.type === 'relationships') });
    this.nodeStructureService.autorefreshCreate({ request: {}, response: data.data });
  }

  public showHumanResourceCard(formFields: TreeNode[], humanResource = <CoreHumanResource>{ first_name: '', last_name: '', email: '', image: '' }, editable = false, modelId?: string) {
    return new Promise<FormResult>(resolve => {
      this.subscriptionService.add('human-resource-card-modal', this.getModal('card-modal').filter(_ => !!_).subscribe((cardModal: CardModalComponent) => {

        /* Set model id */
        if (humanResource.modelId === undefined && modelId !== undefined) {
          humanResource.modelId = modelId;
        }

        /* Settings */
        cardModal.showImage = true;
        cardModal.showForm = isNullOrUndefined(humanResource.id) || editable;
        cardModal.editMode = editable;
        /* Set form */
        cardModal.formInterface = { tabs: [{ entry: { key: 'header-1', label: '' }, children: this.formService.buildFormEntriesFromTreeNodes(formFields) }] };
        /* Set element */
        cardModal.humanResource = humanResource;

        /* Listen to event */
        this.subscriptionService.add('create-update', cardModal.update.subscribe((result: FormResult) => {
          result = this.coreUtilities.mapFormResult(result);
          // load the default HR image /assets/png/default-person.png
          const toDataURL = url => fetch(url)
            .then(response => response.blob())
            .then(blob => new Promise((resolve_, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve_(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            }));
          const imageData = result.delta.get('image');
          if (!imageData) {
            toDataURL('/assets/png/default-person.png')
              .then(dataUrl => {
                if (dataUrl && humanResource.image !== dataUrl) {
                  result.delta = result.delta.set('image', dataUrl);
                }
                resolve(result);
                cardModal.dismiss();
              });
          } else {
            resolve(result);
            cardModal.dismiss();
          }
        }));

        /* Open modal */
        this.subscriptionService.remove('human-resource-card-modal');
        cardModal.show();
      }));
      this.openModal.emit('card-modal');
    });
  }

  public getWordpressUserIdByEmail(email: string, callback: Function) {
    this.userService.diff.filter(diff => diff.action === UserAction.GET_USER_BY_EMAIL_SUCCESS && diff.payload.data === email).take(1).subscribe(diff => {
      callback(diff.response.data);
    });
    this.userService.getUserByEmail(email);
  }

  public getDirectChain(treeNode: TreeNode, toArray = true, dataId = false, modelId?: string, directChainNode?: TreeNode) {
    let parents = [];
    let children = [];

    if (!isNullOrUndefined(directChainNode)) {
      const parentDirectChainNode = directChainNode.children.filter(child => child.nodeType === NODES_TYPE_PARENT)[0];
      if (!isNullOrUndefined(parentDirectChainNode)) {
        parents = this.coreUtilities.getTreeByTreeStructure(parentDirectChainNode, [treeNode], undefined, true).map(parent => parent.id);
      }
      const childDirectChainNode = directChainNode.children.filter(child => child.nodeType === NODES_TYPE_CHILD)[0];
      if (!isNullOrUndefined(childDirectChainNode)) {
        children = this.coreUtilities.getTreeByTreeStructure(childDirectChainNode, [treeNode], undefined, true).map(child => child.id);
      }
    } else {
      parents = this.getChainIds(treeNode, 'unfilteredParents', dataId, modelId);
      children = this.getChainIds(treeNode, 'unfilteredChildren', dataId, modelId);
    }
    const chain = Set(parents).merge(Set(children));
    return toArray ? chain.toArray() : chain;
  }

  /**
   * Get filters by tree node configuration
   * @param filterNodes
   * @param treeNodes
   */
  public getFilters(filterNodes: TreeNode[], treeNodes?: TreeNode[]): CoreWidgetFilter[] {
    const filters = [];
    const count = filterNodes.length;
    for (let i = 0; i < count; i++) {
      const filterNode = filterNodes[i];
      const filter = <CoreWidgetFilter> { id: filterNode.id, label: filterNode.name, field: '', checkboxes: [] };
      const ids = [];
      switch (filterNode.nodeType) {
        case NODES_TYPE_PARENT:
          filter.field = 'parents';
          const nodeTypes = filterNode.children.map(child => child.nodeType);
          if (!isNullOrUndefined(treeNodes)) {
            const count1 = treeNodes.length;
            for (let i1 = 0; i1 < count1; i1++) {
              const treeNode = treeNodes[i1];
              const count2 = treeNode.unfilteredParents.length;
              for (let i2 = 0; i2 < count2; i2++) {
                const parent = treeNode.unfilteredParents[i2];
                if (nodeTypes.indexOf(parent.nodeType) !== -1 && ids.indexOf(parent.id) === -1) {
                  filter.checkboxes.push(<CoreWidgetFilterCheckbox> { id: UUID.UUID(), label: parent.name, value: parent.id, checked: true, treeNode: parent });
                  ids.push(parent.id);
                }
              }
            }
          }
          break;
        case NODES_TYPE_FIELD:
          /* Get all possible values */
          /* Check if there is priority score field */
          if (filterNode.formFieldControlType === 'priority-score') {
            const fieldNodes = this.searchBy({ filters: [{ by: 'nodeType', value: NODES_TYPE_FIELD }] }, filterNode.children);
            if (fieldNodes.length > 0) {
              this.eisenhowerService.setMatrix(fieldNodes);
            }
            filter.field = 'sorting';
          }
          Set(treeNodes.map(treeNode => this.formService.getReadableValue(filterNode, treeNode))).forEach(value => {
            filter.checkboxes.push(<CoreWidgetFilterCheckbox> { id: UUID.UUID(), label: value, value: value, checked: true, treeNode: value });
          });
          break;
      }
      filters.push(filter);
    }
    return filters;
  }

  public loadGlobalFilter() {
    /* Load the global filters */
    const loadFilters = this.coreUtilities.loadLocal('core-' + this.businessAreaId + '-global-filters', undefined);
    this.appGlobal.globalFilterChainedFilters = this.coreUtilities.loadLocal('filter-chained', this.appGlobal.globalFilterChainedFilters);

    if (loadFilters !== undefined) {
      const keys = Object.keys(loadFilters);
      const count = keys.length;
      for (let i = 0; i < count; i++) {
        const globalFilter = loadFilters[keys[i]];
        if (globalFilter.filterType === 'structure') {
          this.appGlobal.globalFilterSelectedEntries[globalFilter.id] = globalFilter.entryNodeIDs;
        }
        /* Set the global filter */
        this.globalFilters = this.globalFilters.set(globalFilter.id, globalFilter);
      }
      /* Update */
      this.updateGlobalFilters(() => {}, true);
    }
  }

  public addGlobalFilter(filters: CoreGlobalFilter | CoreGlobalFilter[], eventEmitter: EventEmitter<any> = null, callback?: any) {
    if (isNullOrUndefined(this.clearFilter) && !isNullOrUndefined(eventEmitter)) {
      this.clearFilter = eventEmitter;
    }
    if (!isArray(filters)) {
      filters = [filters];
    }
    const count = filters.length;
    for (let i = 0; i < count; i++) {
      /* Filter */
      const filter = filters[i];
      if (filter.filterType === 'structure') {
        this.appGlobal.globalFilterSelectedEntries[filter.id] = filter.entryNodeIDs;
      }
      /* Set the global filter */
      this.globalFilters = this.globalFilters.set(filter.id, filter);
    }
    /* Update global filters */
    this.updateGlobalFilters(callback);
  }

  public removeGlobalFilter(id: string, callback?: any) {
    /* Set the global filter */
    this.globalFilters = this.globalFilters.remove(id);
    /* Set the original relationships */
    this.treeRelationships.next(this.unfilteredTreeRelationships);
    /* Update global filters */
    this.updateGlobalFilters(callback);
  }

  public removeAllGlobalFilter() {
    /* Set the global filter */
    if (!isNullOrUndefined(this.clearFilter)) {
      this.clearFilter.emit(null);
    }
    this.globalFilters = this.globalFilters.clear();
    /* Update global filters */
    this.updateGlobalFilters();
    /* Save */
    this.coreUtilities.clearLocal('core-' + this.businessAreaId + '-global-filters', 'core-' + this.businessAreaId + '-global-filter-keys', 'global-filter-selected');
    /* Update */
    this.globalFilterIdsEmitter.next([]);
  }

  public getGlobalFilterIds() {
    return this.globalFilterIds;
  }

  public getGlobalFilter() {
    return this.globalFilterIdsEmitter;
  }

  public isElementAssigned(dataId: string, treeNode: TreeNode, connectNodeType = NODES_TYPE_CONNECT) {
    return !isNullOrUndefined(this.getElementAssignedNode(dataId, treeNode, connectNodeType));
  }

  public getElementAssignedNode(dataId: string, treeNode: TreeNode, connectNodeType = NODES_TYPE_CONNECT) {
    let result: TreeNode;
    const connectNodes = treeNode.unfilteredChildren.filter(child => child.nodeType === connectNodeType);
    const count = connectNodes.length;
    for (let i = 0; i < count; i++) {
      const connectNode = connectNodes[i];
      if (connectNode.unfilteredParents.filter(parent => parent.dataId === dataId).length > 0) {
        result = connectNode;
        break;
      }
    }
    return result;
  }

  public isCreated(treeNodeId: string, callback: Function) {
    this.isCreatedMap = this.isCreatedMap.set(treeNodeId, callback);
    return this;
  }

  public updateGlobalFilters(callback?: Function, init = false) {
    this.clearCache(true);

    this.globalFilterIdsEmitter.next([]);

    this.gfstructureEntryNodeIDs = [];
    /* Filter tree nodes */
    if (this.globalFilters.size === 0) {
      this.globalFilterIds = undefined;
      this.globalFilterStructureIDs = undefined;
      this.globalFilterDataIDs = undefined;
      /* Update */
    } else {
      const gfObject = this.getTreeNodesByGlobalFilters();
      this.globalFilterIds = gfObject.gfIDs;
      this.globalFilterStructureIDs = gfObject.structureIDs;
      this.globalFilterDataIDs = gfObject.dataIDs;
    }
    /* Update Subjects */
    this.treeNodes.next(this.treeNodes.getValue());
    this.treeRelationships.next(this.setTRTRelationships(this.treeRelationships.getValue()));
    if (!init) {
      /* Save */
      this.coreUtilities.saveLocal('core-' + this.businessAreaId + '-global-filters', this.globalFilters.toJS()).then(() => !!callback ? callback() : {});
    }
  }

  public hasTRT() {
    return AppGlobal.trtMap !== undefined && Object.keys(AppGlobal.trtMap).length > 0;
  }

  public setTRT(trtMap?: any, save = true) {
    /* Store it */
    AppGlobal.trtMap = trtMap;
    /* Save it */
    if (save) {
      this.coreUtilities.saveLocal('trt', AppGlobal.trtMap);
    }
    /* Clear the tree relationships */
    if (trtMap === undefined) {
      this.treeRelationships.next(this.unfilteredTreeRelationships);
    }
  }

  public setTRTRelationships(treeRelationships: OrderedMap<string, TreeRelationship>, store = false) {
    if (store) {
      this.unfilteredTreeRelationships = treeRelationships;
    }
    this.unfilteredTreeRelationshipsObservable.next(this.unfilteredTreeRelationships);

    let trtGfs = 0;
    if (AppGlobal.trtMap !== undefined) {
      trtGfs = this.globalFilters.filter(globalFilter => globalFilter.useTRT).size;
    }

    if (treeRelationships === undefined || AppGlobal.trtMap === undefined || Object.keys(AppGlobal.trtMap).length === 0 || trtGfs === 0) {
      return treeRelationships;
    }

    /* Initial relationships */
    treeRelationships = treeRelationships.clear() as OrderedMap<string, TreeRelationship>;

    const keys = Object.keys(AppGlobal.trtMap);
    const keysCount = keys.length;
    for (let ik = 0; ik < keysCount; ik++) {
      const key = keys[ik];

      /* Map */
      const map = AppGlobal.trtMap[key];

      /* Parents */
      let count = map.parentIds.length;
      for (let i = 0; i < count; i++) {
        const relationship =  <TreeRelationship> { id: UUID.UUID(), parentId: map.parentIds[i], childId: key, weight: 1, isTRT: true, modelId: map.modelId };
        treeRelationships = treeRelationships.set(relationship.id, relationship);
      }

      /* Children */
      count = map.childIds.length;
      for (let i = 0; i < count; i++) {
        const relationship =  <TreeRelationship> { id: UUID.UUID(), parentId: key, childId: map.childIds[i], weight: 1, isTRT: true, modelId: map.modelId };
        treeRelationships = treeRelationships.set(relationship.id, relationship);
      }
    }

    /* Return */
    return treeRelationships;
  }

  public guardian(guardianData: GuardianData) {
    return new Promise<GuardianResponseData | HttpErrorResponse>(resolve => {
      this.subscriptionService.add('guardian-find', this.guardianService.diff.filter(d => !!d && (d.action === GuardianAction.FIND_SUCCESS || d.action === GuardianAction.FIND_FAIL)).subscribe(diff => {
        this.subscriptionService.remove('guardian-find');
        resolve(diff.response);
      }));
      this.guardianService.findInfractions(guardianData);
    });
  }

  public findMyNodeIds() {
    return new Promise<string[] | HttpErrorResponse>(resolve => {
      this.subscriptionService.add('my-find', this.myService.diff.filter(d => !!d && (d.action === MyAction.FIND_SUCCESS || d.action === MyAction.FIND_FAIL)).subscribe(diff => {
        this.subscriptionService.remove('my-find');
        resolve(diff.response);
      }));
      this.myService.findMyNodes(this.businessAreaId);
    });
  }

  public getActivitiesByNodeId(nodeIds: string[]) {
    return new Promise<any>(resolve => {
      const requestId = UUID.UUID();
      this.subscriptionService.add('getActivitiesByNodeId', this.activityService.diff.filter(d => {
        return (!!d && !!d.action && !!d.payload && d.payload.id === requestId);
      }).subscribe(diff => {
        resolve(diff.action === ActivityAction.GET_SUCCESS ? diff.response : false);
      }));
      /* Do the call */
      this.activityService.loadActivityByNodeData(requestId, nodeIds);
    });
  }

  public aiGetSimilarity(input: string[], limit = 0) {
    return new Promise<Similarity[] | boolean>(resolve => {
      /* Define the request id */
      const requestId = UUID.UUID();
      /* Register listener */
      this.subscriptionService.add('aiSimilarityDiff', this.aiService.diff.filter(d => !!d && !!d.action && d.payload === requestId).subscribe(diff => {
        resolve(diff.action === AiAction.SIMILARITY_SUCCESS ? diff.response : false);
      }));
      /* Do the call */
      this.aiService.getSimilarityIndices(requestId, input, limit);
    });
  }

  public connectToDB(json: any) {
    return new Promise<JsonStructureData>(resolve => {
      /* Define the request id */
      const requestId = UUID.UUID();
      /* Register listener */
      this.subscriptionService.add('connectToDB', this.generalService.diff.filter(d => !!d && !!d.action && d.payload === requestId).subscribe(diff => {
        resolve(diff.action === GeneralAction.DATABASE_CONNECTION_SUCCESS ? diff.response : {data: []});
      }));
      /* Do the call */
      this.generalService.connectToDB(requestId, json);
    });
  }

  public runConnector(json: any) {
    return new Promise<any>(resolve => {
      /* Define the request id */
      const requestId = UUID.UUID();
      /* Register listener */
      this.subscriptionService.add('aiRunConnector', this.generalService.diff.filter(d => !!d && !!d.action && d.payload === requestId).subscribe(diff => {
        resolve(diff.action === GeneralAction.RUN_CONNECTOR_SUCCESS ? diff.response : false);
      }));
      /* Do the call */
      this.generalService.runConnector(requestId, json);
    });
  }

  public authoriseEntry(domain: string, json: any) {
    return new Promise<any>(resolve => {
      /* Define the request id */
      const requestId = UUID.UUID();
      /* Register listener */
      this.subscriptionService.add('authEntry', this.generalService.diff.filter(d => !!d && !!d.action && d.payload === requestId).subscribe(diff => {
        if (diff.action === GeneralAction.AUTHORISE_ENTRY_FAILED || diff.action === GeneralAction.AUTHORISE_ENTRY_SUCCESS) {
          resolve(diff.response);
        }
      }));
      /* Do the call */
      this.generalService.authoriseEntry(requestId, domain, json);
    });
  }

  public aiFindDocuments(input: AIFindDocumentsPayload) {
    return new Promise<string>(resolve => {
      /* Define the request id */
      const requestId = UUID.UUID();
      /* Resolve request id */
      resolve(requestId);
      /* Do the call */
      this.aiService.findDocuments(requestId, input);
    });
  }

  public aiStopFindDocuments(id: string) {
    return new Promise<string>(resolve => {
      /* Define the request id */
      const requestId = UUID.UUID();
      /* Resolve request id */
      resolve(requestId);
      /* Do the call */
      this.aiService.stopFindDocuments(requestId, id);
    });
  }

  public aiChatGPTRequest(conversations: ChatGPTConversation | ChatGPTConversation[]) {
    return new Promise(resolve => {
      /* Define the request id */
      const requestId = UUID.UUID();
      /* Register listener */
      this.subscriptionService.add('chatGPTRequest', this.aiService.diff.filter(d => {
        return (!!d && !!d.action && d.payload === requestId);
      }).subscribe(diff => {
        resolve(diff.action === AiAction.CHATGPT_SUCCESS ? diff.response : false);
      }));
      /* Do the call */
      this.aiService.getChatGPTResponse(requestId, conversations);
    });
  }

  public aiSearch(search: Search) {
    return new Promise<SearchResult[]>(resolve => {
      /* Register listener */
      this.subscriptionService.add('aiSearchDiff', this.aiService.diff.filter(d => !!d && !!d.action && (d.action === AiAction.SEARCH_SUCCESS || d.action === AiAction.SEARCH_FAILED)).subscribe(diff => {
        resolve(diff.action === AiAction.SEARCH_SUCCESS ? diff.response : false);
      }));
      /* Do the call */
      this.aiService.doSearch(search);
    });
  }

  public aiIngest(formData: FormData) {
    return new Promise<boolean>(resolve => {
      /* Register listener */
      this.subscriptionService.add('aiIngestDiff', this.aiService.diff.filter(d => !!d && !!d.action && (d.action === AiAction.INGEST_SUCCESS || d.action === AiAction.INGEST_FAILED)).subscribe(diff => {
        if (this.revceivedIds[diff.id] === undefined) {
          this.revceivedIds[diff.id] = true;
          resolve(diff.action === AiAction.INGEST_SUCCESS);
        }
      }));
      /* Do the call */
      this.aiService.ingest(formData, {});
    });
  }

  /**
   * Set the sequence on the widget
   *
   * @param sequenceTreeNode
   * @param currentId
   *
   * @protected
   */
  public setSequence(sequenceTreeNode: TreeNode, currentId: string) {
    /* The sequence elements */
    const sequenceElements = [];

    /* Get the settings */
    const settings = {
      onlyActivateNext: false,
      onlyActivateFirst: false,
      showLabel: true,
      showDot: true,
      visible: true,
      dataSource: false,
      style: sequenceTreeNode.formId
    };

    /* Get the widgets the sequence node is connected to */
    const settingsNodes = sequenceTreeNode.children;
    let count = settingsNodes.length;
    for (let i = 0; i < count; i++) {
      const settingsNode = settingsNodes[i];
      switch (settingsNode.nodeType) {
        case NODES_TYPE_ONLY_ONE_STACK:
          settings.onlyActivateNext = true;
          break;
        case NODES_TYPE_DATASOURCE:
          settings.onlyActivateFirst = true;
          break;
        case NODES_TYPE_LABEL:
          settings.showLabel = true;
          settings.showDot = false;
          break;
        case NODES_TYPE_NODE:
          settings.showLabel = false;
          settings.showDot = true;
          break;
        case NODES_TYPE_ALTERNATIVE:
          settings.visible = false;
          break;
      }
    }

    /* Get the widgets the sequence node is connected to */
    const sequenceParentNodes = sequenceTreeNode.parents.filter(parent => parent.nodeType === NODES_TYPE_WIDGET || parent.nodeType === NODES_TYPE_SEQUENCE).sort((a, b) => a.positionX - b.positionX);

    /* Active index */
    let activeIndex: number;

    /* Build the sequence elements */
    count = sequenceParentNodes.length;
    for (let i = 0; i < count; i++) {
      const sequenceParentNode = sequenceParentNodes[i];

      /* Active */
      const active = currentId === sequenceParentNode.id;

      /* Set index */
      if (active) {
        activeIndex = i;
      }

      /* Disabled */
      let disabled = false;
      if (settings.onlyActivateNext && activeIndex !== undefined && i - 1 > activeIndex) {
        disabled = true;
      }

      if (settings.onlyActivateFirst && activeIndex === 0 && i !== 0) {
        disabled = true;
      }

      let icon: string;
      if (sequenceParentNode.icon !== undefined && sequenceParentNode.icon !== null && sequenceParentNode.icon !== '') {
        icon = sequenceParentNode.icon;
      }

      /* Build sequence element and add it to array */
      sequenceElements.push({
        label: sequenceParentNode.name,
        active,
        disabled,
        showLabel: settings.showLabel,
        showDot: settings.showDot && icon === undefined,
        showIcon: icon !== undefined,
        icon,
        visible: settings.visible,
        treeNode: sequenceParentNode,
        style: settings.style
      } as SequenceElement);
    }
    /* Return sequence elements */
    return sequenceElements;
  }

  public getDashboardSequenceNode(currentSequenceTreeNode: TreeNode, currentId: string) {
    /* The sequence elements */
    let sequenceElements = [];

    /* Get the sequence child node */
    const sequenceTreeNode = currentSequenceTreeNode.children.filter(child => child.nodeType === NODES_TYPE_SEQUENCE)[0];
    if (sequenceTreeNode !== undefined) {
      sequenceElements = this.setSequence(sequenceTreeNode, currentId);
    }

    /* Return sequence elements */
    return sequenceElements;
  }

  private getTreeNodesByGlobalFilters() {
    /* The global filter ids */
    let globalFilterIds = [];
    let structureFilterIDs = [];
    let dataFilterIDs = [];
    /* Get the tree nodes and filter out the mcm nodes */
    const treeNodes = <OrderedMap<string, TreeNode>> AppGlobal.treeNodes.filter(treeNode => treeNode.modelId !== this.mcm);
    /* Sort the filters */
    const sortedFilters = this.globalFilters.toArray().sort((a, b) => a.order - b.order);
    /* Element selected ids */
    const elementSelectedIds = {};
    /* Iterate over filters */
    const count = sortedFilters.length;
    for (let i = 0; i < count; i++) {
      const globalFilter = sortedFilters[i];
      /* Set the element type */
      elementSelectedIds[globalFilter.id] = [];
      /* Decide by type */
      switch (globalFilter.type) {
        case 'byField': {
          const key = globalFilter.key.split(':')[0];
          const globalFilterValue = Set(globalFilter.value).map(a => '' + a);
          const ids = treeNodes.filter(treeNode => {
            if (globalFilter.ignoreNodeType !== undefined && globalFilter.ignoreNodeType[treeNode.nodeType] === true) {
              return true;
            }
            let val = treeNode[key];
            if (val === null || val === undefined) {
              val = globalFilter.defaultValue;
            }
            return globalFilterValue.has('' + val);
          }).map(treeNode => treeNode.id).toArray();
          globalFilterIds.push(ids);
          if (globalFilter.initialValue === undefined) {
            globalFilter.initialValue = globalFilter.value;
          }
          elementSelectedIds[globalFilter.id] = elementSelectedIds[globalFilter.id].concat(globalFilter.initialValue);
          if (globalFilter.filterType === 'structure') {
            structureFilterIDs.push(ids);
          } else {
            dataFilterIDs.push(ids);
          }
          break;
        }
        case 'my':
          globalFilterIds.push(globalFilter.value);
          if (globalFilter.filterType === 'data') {
            dataFilterIDs.push(globalFilter.value);
          } else {
            structureFilterIDs.push(globalFilter.value);
          }
          break;
        case 'date-range': {
          const data: ByDateInterface = globalFilter.value;
          const start: Datum = isNullOrUndefined(data.startValue) ? null : new Datum(data.startValue);
          const end: Datum = isNullOrUndefined(data.endValue) ? null : new Datum(data.endValue);
          if (start === null && end === null) {
            continue;
          }
          /* Iterate over tree nodes to filter out those that match */
          const ids = [];
          treeNodes.forEach(treeNode => {
            /* Visible */
            let visible = false;
            if (globalFilter.ignoreNodeType !== undefined && globalFilter.ignoreNodeType[treeNode.nodeType] === true) {
              visible = true;
            }
            if (!visible) {
              /* Values */
              const startValue = !isNullOrUndefined(treeNode[data.startField]) ? new Datum(treeNode[data.startField]) : undefined;
              const endValue = !isNullOrUndefined(treeNode[data.endField]) ? new Datum(treeNode[data.endField]) : undefined;
              /* Conditions */
              if (!isNullOrUndefined(startValue) && !isNullOrUndefined(endValue) && start !== null && end !== null) {
                /* Both ranges are known */
                visible = startValue.lteq(end) && endValue.gteq(start);
              } else if (!isNullOrUndefined(startValue) && !isNullOrUndefined(endValue) && start !== null && end === null) {
                /* End range is missing */
                visible = startValue.gteq(start) && endValue.gteq(start);
              } else if (!isNullOrUndefined(startValue) && !isNullOrUndefined(endValue) && start === null && end !== null) {
                /* Start range is missing */
                visible = startValue.lteq(end);
              } else if (!isNullOrUndefined(startValue) && isNullOrUndefined(endValue) && start !== null && end !== null) {
                /* No end value set; range is set */
                visible = startValue.gteq(start) && startValue.lteq(end);
              } else if (!isNullOrUndefined(startValue) && isNullOrUndefined(endValue) && start !== null && end === null) {
                /* No end value set; range is only start */
                visible = startValue.gteq(start);
              } else if (!isNullOrUndefined(startValue) && isNullOrUndefined(endValue) && start === null && end !== null) {
                /* No end value set; range is only end */
                visible = startValue.lteq(end);
              } else if (isNullOrUndefined(startValue) && !isNullOrUndefined(endValue) && start !== null && end !== null) {
                /* No start value set; range is set */
                visible = endValue.gteq(start) && endValue.lteq(end);
              } else if (isNullOrUndefined(startValue) && !isNullOrUndefined(endValue) && start !== null && end === null) {
                /* No start value set; range is only start */
                visible = endValue.gteq(start);
              } else if (isNullOrUndefined(startValue) && !isNullOrUndefined(endValue) && start === null && end !== null) {
                /* No start value set; range is only end */
                visible = endValue.lteq(end);
              }
            }
            if (visible) {
              ids.push(treeNode.id);
            }
          });
          globalFilterIds.push(ids);
          dataFilterIDs.push(ids);
          elementSelectedIds[globalFilter.id] = globalFilter.value;
          break;
        }
        case 'conditions':
          const filters: AbstractCondition[] = globalFilter.value;

          const visibleIds = treeNodes.filter(treeNode => {
            let visible = false;
            /* Iterate over filters */
            const count2 = filters.length;
            for (let i2 = 0; i2 < count2; i2++) {
              const filter = filters[i2];
              switch (filter.type) {
                case 'bracket':
                  visible = this.filterByBrackets(treeNode, filter);
                  break;
                case 'operator':
                  visible = this.filterByOperator(treeNode, filter);
                  break;
                case 'condition':
                  visible = this.filterByCondition(treeNode, filter);
                  break;
              }
            }
            return visible;
          }).map(d => d.id).toArray();

          globalFilterIds.push(visibleIds);
          elementSelectedIds[globalFilter.id] = visibleIds;
          break;
        default:
          let value = globalFilter.value;
          if (globalFilter.subtract) {
            /* First get the node types of selected nodes */
            const nodeTypes = Set<number>(value.map(val => treeNodes.has(val) ? treeNodes.get(val).nodeType : undefined).filter(d => d !== undefined));
            value = treeNodes.filter(treeNode => nodeTypes.has(treeNode.nodeType) && value.indexOf(treeNode.id) === -1).map(d => d.id).toArray();
          }
          /* Check if there is a traverser */
          if (globalFilter.traverser && AppGlobal.treeNodes.has(globalFilter.traverser)) {
            const traverser = AppGlobal.treeNodes.get(globalFilter.traverser);
            switch (traverser.nodeType) {
              case NODES_TYPE_CHILD:
                value = this.coreUtilities.getTreeByTreeStructure(traverser, value.map(val => AppGlobal.treeNodes.get(val)).filter(val => !isNullOrUndefined(val))).map(d => d.id);
                break;
              case NODES_TYPE_PARENT:
                value = this.coreUtilities.getTreeByTreeStructure(traverser, value.map(val => AppGlobal.treeNodes.get(val)).filter(val => !isNullOrUndefined(val)), 'unfilteredParents').map(d => d.id);
                break;
            }
          }
          if (!isNullOrUndefined(globalFilter.traverserLaneId)) {
            const traverser = new Traverser();
            const laneNode = AppGlobal.treeNodes.get(globalFilter.traverserLaneId);
            const entryNodes = value.map(id => treeNodes.get(id)).filter(d => !!d);
            value = traverser.setRelationshipWeights(this.getRelationshipWeights()).addToLanes(laneNode).setTRT(globalFilter.useTRT).run(entryNodes).map(e => e.id);
          }
          /* Add the direct parents/ children if it's configured */
          if (globalFilter.directChildren || globalFilter.directParents) {
            const count2 = value.length;
            for (let i2 = 0; i2 < count2; i2++) {
              const treeNode = treeNodes.get(value[i2]);
              if (treeNode !== undefined) {
                if (globalFilter.directChildren) {
                  value = value.concat(treeNode.childIds.map(c => '' + c));
                }
                if (globalFilter.directParents) {
                  value = value.concat(treeNode.parentIds.map(p => '' + p));
                }
              }
            }
          }
          /* Get the tree nodes by global filter value */
          globalFilterIds.push(value);
          if (globalFilter.filterType === 'data') {
            dataFilterIDs.push(globalFilter.value);
          } else {
            this.gfstructureEntryNodeIDs = this.gfstructureEntryNodeIDs.concat(globalFilter.entryNodeIDs);
            structureFilterIDs.push(globalFilter.value);
          }
          if (isArray(value)) {
            elementSelectedIds[globalFilter.id] = elementSelectedIds[globalFilter.id].concat(globalFilter.initialValue);
          } else {
            elementSelectedIds[globalFilter.id].push(globalFilter.initialValue);
          }
      }
    }

    /* Send the ids before further filtering */
    this.globalFilterIdsEmitter.next(elementSelectedIds);

    /* Direct connection */
    if (this.appGlobal.globalFilterChainedObjects.getValue() && globalFilterIds !== undefined) {
      globalFilterIds = globalFilterIds.map(ids => this.coreUtilities.getDirectChain(ids.map(id => AppGlobal.treeNodes.get(id)).filter(d => !!d), false, true).ids);
      structureFilterIDs = structureFilterIDs.map(ids => this.coreUtilities.getDirectChain(ids.map(id => AppGlobal.treeNodes.get(id)).filter(d => !!d), false, true).ids);
      dataFilterIDs = dataFilterIDs.map(ids => this.coreUtilities.getDirectChain(ids.map(id => AppGlobal.treeNodes.get(id)).filter(d => !!d), false, true).ids);
    }
    /* Chained */
    if (this.appGlobal.globalFilterDirectConnection.getValue() && globalFilterIds !== undefined) {
      return {gfIDs: this.coreUtilities.getSameIds(globalFilterIds), structureIDs: this.coreUtilities.getSameIds(structureFilterIDs), dataIDs: this.coreUtilities.getSameIds(dataFilterIDs)};
    }
    /* Merge and return result */
    return {gfIDs: this.coreUtilities.flatArray(globalFilterIds), structureIDs: this.coreUtilities.flatArray(structureFilterIDs), dataIDs: this.coreUtilities.flatArray(dataFilterIDs)};
  }

  private filterByBrackets(treeNode: TreeNode, bracket: Bracket) {
    const bracketValues = bracket.operators.map(operator => this.filterByOperator(treeNode, operator)).concat(bracket.conditions.map(condition => this.filterByCondition(treeNode, condition)));
    return this.operateByOperator(bracketValues, bracket.operation);
  }

  private filterByOperator(treeNode: TreeNode, operator: Operator) {
    const conditions = operator.conditions.map(condition => this.filterByCondition(treeNode, condition));
    return this.operateByOperator(conditions, operator.operation);
  }

  private filterByCondition(treeNode: TreeNode, condition: Condition) {
    let visible = false;
    if (condition.field !== undefined && condition.operation !== undefined) {
      const value = treeNode[condition.field];
      const targetValue = condition.value;
      /* Check if visible */
      visible = this.operateByCondition(value, targetValue, condition.operation);
    }
    return visible;
  }

  private operateByCondition(value: any, targetValue: any, operator: string): boolean {
    switch (operator) {
      case 'IS':
      case '===':
      case '==':
        return value === targetValue;
      case 'IS NOT':
      case '!==':
      case '!=':
        return value !== targetValue;
      case 'GREATER THEN':
      case 'GT':
      case '>':
        return parseFloat('' + value) > parseFloat('' + targetValue);
      case 'GREATER THEN EQUAL':
      case 'GTEQ':
      case '>=':
        return parseFloat('' + value) >= parseFloat('' + targetValue);
      case 'LOWER THEN':
      case 'LT':
      case '<':
        return parseFloat('' + value) < parseFloat('' + targetValue);
      case 'LOWER THEN EQUAL':
      case 'LTEQ':
      case '<=':
        return parseFloat('' + value) <= parseFloat('' + targetValue);
      case 'AFTER':
        return new Datum(value).setHours(0).setMinutes(0).setSeconds(0).gteq(new Datum(targetValue).setHours(0).setMinutes(0).setSeconds(0));
      case 'BEFORE':
        return new Datum(value).setHours(0).setMinutes(0).setSeconds(0).lteq(new Datum(targetValue).setHours(0).setMinutes(0).setSeconds(0));
      case 'IS NULL':
        return value === '' || value === 0 || value === undefined || value === null;
      case 'IS NOT NULL':
        return value !== '' && value !== 0 && value !== undefined && value !== null;
    }
    return false;
  }

  private operateByOperator(values: boolean[], operator: string): boolean {
    switch (operator) {
      case 'AND':
      case '&&':
      case '&':
        return values.filter(value => value === false).length === 0;
      case 'OR':
      case '|':
      case '||':
        return values.filter(value => value === true).length > 0;
    }
    return false;
  }

  private getChainIds(treeNode: TreeNode, direction: string, dataId = false, modelId?: string, ids = []) {
    ids.push(dataId ? treeNode.dataId : treeNode.id);
    const treeNodes = treeNode[direction];
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const child = treeNodes[i];
      if (isNullOrUndefined(modelId) || child.modelId === modelId) {
        ids = this.getChainIds(child, direction, dataId, modelId, ids);
      }
    }
    return ids;
  }

  /**
   * Initialise the core service
   */
  private initialise() {
    /* Initialise the color label provider */
    this.selectedColorLabelProvider = this.coreUtilities.loadLocal('core-' + this.businessAreaId + '-color-label-provider', '');
    this.setColorLabelProvider(this.selectedColorLabelProvider);
    /* Load TRT */
    AppGlobal.trtMap = this.coreUtilities.loadLocal('trt', AppGlobal.trtMap);
  }

  private bindTreeNodesListener() {
    /* Listen to node structure update */
    this.subscriptionService.add('onTreeNodeStructuresLoaded', this.nodeStructureService.all().subscribe(nodeStructures => this.onTreeNodeStructuresLoaded(nodeStructures)));
    /* Listen to node data update */
    this.subscriptionService.add('onTreeNodeDataLoaded', this.nodeDataService.all().subscribe(nodeData => this.onTreeNodeDataLoaded(nodeData)));
    /* Listen relationship updates */
    this.subscriptionService.add('onTreeRelationshipsLoaded', this.relationshipService.all().subscribe(relationships => this.onTreeRelationshipsLoaded(relationships)));
    /* Listen to node structure delete */
    this.subscriptionService.add('onTreeDeleteNodeStructures', this.nodeStructureService.diff.filter(d => d.action === NodeStructureAction.REMOVE_SUCCESS).subscribe(diff => this.onTreeDeleteNodeStructures(diff)));
    /* Listen to relationship delete */
    this.subscriptionService.add('onTreeDeleteRelationship', this.relationshipService.diff.filter(d => d.action === RelationshipAction.REMOVE_SUCCESS).subscribe(diff => this.onTreeDeleteRelationship(diff)));
    /* Listen to activity update */
    this.subscriptionService.add('onTreeActivitiesLoaded', this.activityService.all().subscribe(activities => this.onTreeActivitiesLoaded(activities)));
    /* Listen to activity update */
    this.subscriptionService.add('onTreeActivitiesDeleted', this.activityService.diff.filter(d => d.action === ActivityAction.REMOVE_SUCCESS).subscribe(diff => this.onTreeActivitiesDeleted(diff)));
    /* Listen to human resource update */
    this.subscriptionService.add('onTreeHumanResourcesLoaded', this.humanResourceService.all().subscribe(humanResources => this.onTreeHumanResourcesLoaded(humanResources)));
    /* Listen to human resource delete */
    this.subscriptionService.add('onTreeHumanResourcesDeleted', this.humanResourceService.diff.filter(diff => diff.action === HumanResourceAction.REMOVE_SUCCESS).subscribe(diff => {
      this.onTreeHumanResourcesDeleted(diff.response);
    }));
    /* Listen to Group delete */
    this.subscriptionService.add('onGroupsDeleted', this.groupService.diff.filter(diff => diff.action === GroupAction.REMOVE_SUCCESS).subscribe(diff => {
      this.onGroupsDeleted(diff.response);
    }));
    /* Listen to group update */
    this.subscriptionService.add('onTreeGroupsLoaded', this.groupService.all().subscribe(groups => this.onTreeGroupsLoaded(groups)));

    /* Load all data */
    this.subscriptionService.add('onTreeDataLoaded', this.treeService.getAllByBusinessArea().subscribe((treeData: any[]) => this.onTreeDataLoaded(this.businessAreaId, treeData)));

    /* Fails */
    this.subscriptionService.add('nodeDataService.diff', this.nodeDataService.diff.filter(diff =>
      diff.action === NodeDataAction.LOAD_FAIL ||
      diff.action === NodeDataAction.LOAD_BY_TYPES_FAIL ||
      diff.action === NodeDataAction.LOAD_AUDITS_FAIL ||
      diff.action === NodeDataAction.UPDATE_FAIL
    ).subscribe(diff => this.onFail(diff)));
    this.subscriptionService.add('nodeStructureService.diff', this.nodeStructureService.diff.filter(diff =>
      diff.action === NodeStructureAction.LOAD_FAIL ||
      diff.action === NodeStructureAction.UPDATE_FAIL ||
      diff.action === NodeStructureAction.CREATE_FAIL ||
      diff.action === NodeStructureAction.REASSIGN_FAIL ||
      diff.action === NodeStructureAction.REMOVE_FAIL
    ).subscribe(diff => this.onFail(diff)));
    this.subscriptionService.add('treeService.diff', this.treeService.diff.filter(diff =>
      diff.action === TreeAction.LOAD_FAIL
    ).subscribe(diff => this.onFail(diff, () => {
      this.router.navigate(['preview']);
    })));
    this.subscriptionService.add('relationshipService.diff', this.relationshipService.diff.filter(diff =>
      diff.action === RelationshipAction.LOAD_FAIL ||
      diff.action === RelationshipAction.CREATE_FAIL ||
      diff.action === RelationshipAction.UPDATE_FAIL ||
      diff.action === RelationshipAction.REMOVE_FAIL
    ).subscribe(diff => this.onFail(diff)));
    this.subscriptionService.add('activityService.diff', this.activityService.diff.filter(diff =>
      diff.action === ActivityAction.LOAD_FAIL ||
      diff.action === ActivityAction.CREATE_FAIL ||
      diff.action === ActivityAction.UPDATE_FAIL ||
      diff.action === ActivityAction.REMOVE_FAIL
    ).subscribe(diff => this.onFail(diff, () => {
    })));
    this.subscriptionService.add('humanResourceService.diff', this.humanResourceService.diff.filter(diff =>
      diff.action === HumanResourceAction.LOAD_FAIL ||
      diff.action === HumanResourceAction.CREATE_FAIL ||
      diff.action === HumanResourceAction.UPDATE_FAIL ||
      diff.action === HumanResourceAction.REMOVE_FAIL
    ).subscribe(diff => this.onFail(diff, () => {
      this.humanResources.next(this.unChangedHumanResources);
    })));
    this.subscriptionService.add('groupService.diff', this.groupService.diff.filter(diff =>
      diff.action === GroupAction.LOAD_FAIL ||
      diff.action === GroupAction.CREATE_FAIL ||
      diff.action === GroupAction.UPDATE_FAIL ||
      diff.action === GroupAction.REMOVE_FAIL
    ).subscribe(diff => this.onFail(diff, () => {
      this.groups.next(this.unChangedGroups);
    })));
  }

  private onFail(diff: RequestDiffRecord, callback?: () => void) {
    const error = diff.response as HttpErrorResponse;
    let message = error.error;
    if (error.error instanceof ProgressEvent) {
      message = 'An unknown error occurred';
    }
    this.failCallbacks.push(callback !== undefined ? callback : 'default');
    if (this.failCallbacks.length === 1) {
      this.notificationService.error('An error occurred', message).then(() => {
        const count = this.failCallbacks.length;
        for (let i = 0; i < count; i++) {
          const failCallback = this.failCallbacks[i];
          if (failCallback === 'default') {
            this.treeNodes.next(AppGlobal.treeNodes);
          } else {
            failCallback();
          }
        }
        this.failCallbacks = [];
      });
    }
  }

  private onTreeDeleteNodeStructures(diff: RequestDiffRecord) {
    this.clearCache(true);
    const count = diff.payload.data.length;
    for (let i = 0; i < count; i++) {
      AppGlobal.treeNodes = AppGlobal.treeNodes.remove(diff.payload.data[i]);
      this.deletedNodes.push(diff.payload.data[i]);
    }
    if (count > 0) {
      if (this.transferInProgress) {
        this.updateAfterTransfer.nodes = true;
      } else {
        this.treeNodes.next(AppGlobal.treeNodes);
      }
    }
    /* Check if all has been transferred */
    if (this.isTransferCompleted('nodeStructures')) {
      this.afterTransfer();
    }
  }

  private onTreeDeleteRelationship(diff: RequestDiffRecord) {
    this.clearCache(true);
    const count = diff.response.length;
    let event = false;
    let treeRelationships = this.transferInProgress && !isNullOrUndefined(this.updateAfterTransfer.relationships) ? this.updateAfterTransfer.relationships : this.treeRelationships.getValue();
    if (treeRelationships === undefined) {
      treeRelationships = OrderedMap<string, TreeRelationship>();
    }
    for (let i = 0; i < count; i++) {
      const relationship = diff.response[i];
      if (treeRelationships.has(relationship.id)) {
        treeRelationships = treeRelationships.remove(relationship.id);
      } else {
        treeRelationships = treeRelationships.remove(<any> parseInt(relationship.id));
      }
      const parentId = relationship.relationships.parent.data.id;
      const childId = relationship.relationships.child.data.id;

      const parent = AppGlobal.treeNodes.get(parentId);
      if (!isNullOrUndefined(parent)) {
        parent.childIds = parent.childIds.filter(c => '' + c !== '' + childId);
        if (!isNullOrUndefined(parent.unfilteredChildIds)) {
          parent.unfilteredChildIds = parent.unfilteredChildIds.filter(c => '' + c !== '' + childId);
        }
        AppGlobal.treeNodes = AppGlobal.treeNodes.set(parent.id, parent);
        event = true;
      }

      const child = AppGlobal.treeNodes.get(childId);
      if (!isNullOrUndefined(child)) {
        child.parentIds = child.parentIds.filter(p => '' + p !== '' + parentId);
        if (!isNullOrUndefined(child.unfilteredParentIds)) {
          child.unfilteredParentIds = child.unfilteredParentIds.filter(p => '' + p !== '' + parentId);
        }
        this.onTreeRelationshipsSubLevel(child);
        event = true;
      }
      /* Update global filters */
      if (this.globalFilters.size > 0) {
        this.updateGlobalFilters();
      }
    }

    /* Update CLP */
    if (!this.transferInProgress) {
      this.setColorLabelProviderColors();
    } else {
      this.updateAfterTransfer.colorLabelProvider = true;
    }

    if (!this.transferInProgress) {
      this.treeRelationships.next(this.setTRTRelationships(treeRelationships, true));
      if (event) {
        this.treeNodes.next(AppGlobal.treeNodes);
      }
    } else {
      this.updateAfterTransfer.relationships = treeRelationships;
      if (event) {
        this.updateAfterTransfer.nodes = true;
      }
    }

    /* Check if all has been transferred */
    if (this.isTransferCompleted('relationships')) {
      this.afterTransfer();
    }
  }

  private getMaxSubLevel(treeNode: TreeNode) {
    let max = -1;
    const parents = treeNode.parents;
    const count = parents.length;
    for (let i = 0; i < count; i++) {
      const parent = parents[i];
      if (parent.subLevel > max) {
        max = parent.subLevel;
      }
    }
    return max;
  }

  private onTreeActivitiesLoaded(activities: List<Activity>) {
    activities.forEach(activity => {
      if (this.deletedActivities.indexOf('' + activity.id) === -1) {
        let treeActivity: TreeActivity = AppGlobal.treeActivities.get(activity.id);
        if (isNullOrUndefined(treeActivity)) {
          treeActivity = this.coreTransformer.treeActivityToTreeActivity(activity);
        } else {
          treeActivity = Object.assign(treeActivity, this.coreTransformer.treeActivityToTreeActivity(activity));
        }
        AppGlobal.treeActivities = AppGlobal.treeActivities.set('' + treeActivity.id, treeActivity);
        const treeNode = AppGlobal.treeNodes.filter(d => '' + d.dataId === '' + treeActivity.activitable_id).first();
        if (!isNullOrUndefined(treeNode) && isNullOrUndefined(treeNode.activityIds)) {
          treeNode.activityIds = [parseInt(treeActivity.id)];
          AppGlobal.treeNodes = AppGlobal.treeNodes.set(treeNode.id, treeNode);
        } else if (!isNullOrUndefined(treeNode) && treeNode.activityIds.filter(a => '' + a === '' + treeActivity.id).length === 0) {
          treeNode.activityIds.push(parseInt(treeActivity.id));
          AppGlobal.treeNodes = AppGlobal.treeNodes.set(treeNode.id, treeNode);
        }
      }
    });
    if (this.transferInProgress) {
      this.updateAfterTransfer.activities = true;
      this.updateAfterTransfer.nodes = true;
    } else {
      this.treeActivities.next(AppGlobal.treeActivities);
      this.treeNodes.next(AppGlobal.treeNodes);
    }
    /* Check if all has been transferred */
    if (this.isTransferCompleted('activities')) {
      this.afterTransfer();
    }
  }

  private onTreeActivitiesDeleted(diff: RequestDiffRecord) {
    const count = diff.response.length;
    for (let i = 0; i < count; i++) {
      const activity = diff.response[i];
      this.deletedActivities.push('' + activity.id);
      AppGlobal.treeActivities = AppGlobal.treeActivities.delete(activity.id);
      const treeNode = AppGlobal.treeNodes.filter(d => '' + d.dataId === '' + activity.attributes.activitable_id).first();
      if (!isNullOrUndefined(treeNode) && !isNullOrUndefined(treeNode.activityIds)) {
        treeNode.activityIds = treeNode.activityIds.filter(id => '' + id !== '' + activity.id);
        AppGlobal.treeNodes = AppGlobal.treeNodes.set(treeNode.id, treeNode);
      }
    }
    if (this.transferInProgress) {
      this.updateAfterTransfer.nodes = true;
      this.updateAfterTransfer.activities = true;
    } else {
      this.treeActivities.next(AppGlobal.treeActivities);
      this.treeNodes.next(AppGlobal.treeNodes);
    }
    /* Check if all has been transferred */
    if (this.isTransferCompleted('activities')) {
      this.afterTransfer();
    }
  }

  private onTreeHumanResourcesLoaded(humanResources: List<HumanResource>) {
    let humanResourceMap = this.humanResources.getValue();
    humanResources.forEach(humanResource => {
      humanResourceMap = humanResourceMap.set('' + humanResource.id, this.coreTransformer.humanResourceToCoreHumanResource(humanResource));
    });
    if (humanResourceMap !== undefined) {
      this.unChangedHumanResources = <OrderedMap<string, CoreHumanResource>> humanResourceMap.map(h => Map(h).toJS());
    }
    this.humanResources.next(humanResourceMap);
  }

  private onTreeHumanResourcesDeleted(deletedHumanResources: HumanResource[]) {
    const deletedIds = deletedHumanResources.map(d => '' + d.id);
    const humanResourceMap = this.humanResources.getValue().filter(h => deletedIds.indexOf('' + h.id) === -1) as OrderedMap<string, CoreHumanResource>;
    if (humanResourceMap !== undefined) {
      this.unChangedHumanResources = <OrderedMap<string, CoreHumanResource>> humanResourceMap.map(h => Map(h).toJS());
    }
    this.humanResources.next(humanResourceMap);
  }

  private onGroupsDeleted(deletedGroups: Group[]) {
    const deletedIds = deletedGroups.map(d => '' + d.id);
    const groupsMap = this.groups.getValue().filter(h => deletedIds.indexOf('' + h.id) === -1) as OrderedMap<string, CoreGroup>;
    if (groupsMap !== undefined) {
      this.unChangedGroups = <OrderedMap<string, CoreGroup>> groupsMap.map(h => Map(h).toJS());
    }
    this.groups.next(groupsMap);
  }

  private onTreeGroupsLoaded(groups: List<Group>) {
    let groupMap = this.groups.getValue();
    groups.forEach(group => {
      groupMap = groupMap.set('' + group.id, this.coreTransformer.groupToCoreGroup(group));
    });
    if (groupMap !== undefined) {
      this.unChangedGroups = <OrderedMap<string, CoreGroup>> groupMap.map(g => Map(g).toJS());
    }
    this.groups.next(groupMap);
  }

  private onTreeNodeStructuresLoaded(nodeStructures: List<NodeStructure>) {
    const nodeStructuresLoaded = nodeStructures.toArray().map(nodeStructure => nodeStructure.id).join(',');
    this.clearCache(true);
    let event = false;
    nodeStructures.forEach(nodeStructure => {
      let treeNode: TreeNode = AppGlobal.treeNodes.get('' + nodeStructure.id);
      if (isNullOrUndefined(treeNode)) {
        treeNode = this.coreTransformer.nodeStructureToTreeNode(nodeStructure);
        /* Set the parent and child id */
        if (treeNode.parentIds === undefined) {
          treeNode.parentIds = [];
        }
        if (treeNode.childIds === undefined) {
          treeNode.childIds = [];
        }
        /* Check if there is already an existing data id available */
        const data = AppGlobal.treeNodes.filter(globalTreeNode => globalTreeNode.dataId === treeNode.dataId).first();
        const keys = !isNullOrUndefined(data) ? Object.keys(data) : [];
        const count = keys.length;
        for (let i = 0; i < count; i++) {
          const key = keys[i];
          switch (key) {
            default:
              if (treeNode[key] === undefined) {
                treeNode[key] = data[key];
              }
          }
        }
      } else {
        treeNode = Object.assign(treeNode, this.coreTransformer.nodeStructureToTreeNode(nodeStructure),
          { childIds: treeNode.childIds, parentIds: treeNode.parentIds, unfilteredChildIds: treeNode.unfilteredChildIds, unfilteredParentIds: treeNode.unfilteredParentIds, activityIds: treeNode.activityIds });
        treeNode.reference = isNullOrUndefined(treeNode.reference) || treeNode.reference === '0' || treeNode.reference === '' ? treeNode.id : treeNode.reference;
      }

      if (isString(treeNode.modelId)) {
        treeNode.modelId = parseInt(treeNode.modelId);
      }
      if (isSet(treeNode.models)) {
        treeNode.models = (<any> treeNode.models).toArray();
      }
      if (isSet(treeNode.nodestructures)) {
        treeNode.nodestructures = (<any> treeNode.nodestructures).toArray();
      }
      AppGlobal.treeNodes = AppGlobal.treeNodes.set(treeNode.id, treeNode);
      if (!event && !isNullOrUndefined(treeNode.dataId)) {
        event = true;
      }
    });
    if (event) {
      if (this.transferInProgress) {
        this.updateAfterTransfer.nodes = true;
      } else {
        this.treeNodes.next(AppGlobal.treeNodes);
      }
    }
    /* Check if all has been transferred */
    if (this.isTransferCompleted('nodeStructures')) {
      this.afterTransfer();
    }
  }

  private onTreeNodeDataLoaded(nodeData: List<NodeData>) {
    this.clearCache(true);
    nodeData.forEach(nodeDatum => {
      const nodeStructures = !isNullOrUndefined((<any> nodeDatum.relationships.nodestructures).data) ? (<any> nodeDatum.relationships.nodestructures).data : (<any> nodeDatum.relationships.nodestructures).toArray();
      const count = nodeStructures.length;
      for (let i = 0; i < count; i++) {
        const nodeStructure = isObject(nodeStructures[i]) ? nodeStructures[i].id.split(':')[1] : nodeStructures[i].split(':')[1];
        if (this.deletedNodes.indexOf(nodeStructure) !== -1) {
          continue;
        }
        let treeNode: TreeNode = AppGlobal.treeNodes.get(nodeStructure);
        if (isNullOrUndefined(treeNode)) {
          treeNode = this.coreTransformer.nodeDataToTreeNode(nodeDatum, true);
          treeNode.id = nodeStructure;
        } else {
          const structureId = treeNode.id;
          const previousActivities = treeNode.activityIds;
          const partOfTRT = treeNode.partOfTRT;
          /* In order to keep null values a different merge is used */
          const transformedNodeData = this.coreTransformer.nodeDataToTreeNode(nodeDatum);
          const keys = Object.keys(transformedNodeData);
          const countKeys = keys.length;
          for (let ikey = 0; ikey < countKeys; ikey++) {
            const key = keys[ikey];
            /* Skip if it's null */
            if (treeNode[key] !== null || (transformedNodeData[key] !== 0 && transformedNodeData[key] !== '')) {
              treeNode[key] = transformedNodeData[key];
            }
          }
          /* Add further infos */
          treeNode = Object.assign(treeNode, { dataId: nodeDatum.id, id: structureId, createdAt: treeNode.createdAt, updatedAt: nodeDatum.updatedAt, childIds: treeNode.childIds, parentIds: treeNode.parentIds,
              unfilteredChildIds: treeNode.unfilteredChildIds, unfilteredParentIds: treeNode.unfilteredParentIds, reference: treeNode.reference });
          if (nodeDatum.relationships === undefined || nodeDatum.relationships.activities === undefined) {
            treeNode.activityIds = previousActivities;
          }
          treeNode.formFieldSearchable = nodeDatum.formFieldSearchable;
          treeNode.partOfTRT = partOfTRT;
        }
        if (!isNullOrUndefined(this.colorLabelProvider)) {
          treeNode.colors = this.colorLabelProvider.color(treeNode);
          treeNode.invertedColors = treeNode.colors.map(color => this.coreUtilities.invertColor(color, true));
        }
        if (isString(treeNode.nodeType)) {
          treeNode.nodeType = parseInt(treeNode.nodeType);
        }
        if (treeNode.nodestructures instanceof Set) {
          treeNode.nodestructures = (<any> treeNode.nodestructures).toArray();
        }
        AppGlobal.treeNodes = AppGlobal.treeNodes.set(treeNode.id, treeNode);
      }
      if (count === 0) {
        AppGlobal.treeNodes = AppGlobal.treeNodes.map(treeNode => {
          if (treeNode.dataId === nodeDatum.id) {
            treeNode = Object.assign(treeNode, this.coreTransformer.nodeDataToTreeNode(nodeDatum),
              { dataId: nodeDatum.id, id: treeNode.id, createdAt: treeNode.createdAt, updatedAt: nodeDatum.updatedAt, childIds: treeNode.childIds, parentIds: treeNode.parentIds,
                unfilteredChildIds: treeNode.unfilteredChildIds, unfilteredParentIds: treeNode.unfilteredParentIds, reference: treeNode.reference });
          }
          return treeNode;
        }) as OrderedMap<string, TreeNode>;
      }
    });
    /* Update the software */
    if (!this.transferInProgress || (this.lastTransfer === undefined && this.lastTransferExpectations === undefined)) {
      /* Update global filters */
      if (this.globalFilters.size > 0) {
        this.updateGlobalFilters();
      } else {
        this.treeNodes.next(AppGlobal.treeNodes);
      }
    } else {
      if (this.globalFilters.size > 0) {
        this.updateAfterTransfer.globalFilter = true;
      } else {
        this.updateAfterTransfer.nodes = true;
      }
    }
    /* Check if all has been transferred */
    if (this.isTransferCompleted('nodeData')) {
      this.afterTransfer();
    }
  }

  private onTreeRelationshipsLoaded(relationships: List<Relationship>) {
    this.clearCache(true);
    let event = false;
    let treeRelationships = this.treeRelationships.getValue();
    let treeRelationshipsWeightMap = this.relationshipWeights.getValue();
    if (treeRelationshipsWeightMap === undefined) {
      treeRelationshipsWeightMap = OrderedMap<string, number>();
    }
    relationships.forEach(relationship => {
      /* Parent */
      const parent = AppGlobal.treeNodes.get(relationship.relationships.parent);
      const child = AppGlobal.treeNodes.get(relationship.relationships.child);

      /* Mapping */
      if (!isNullOrUndefined(parent)) {
        if (isNullOrUndefined(parent.childIds)) {
          parent.childIds = [];
        }
        if ((<any> parent.childIds.map(id => '' + id)).indexOf('' + relationship.relationships.child) === -1) {
          (<any> parent.childIds).push(parseInt(relationship.relationships.child));
        }
        if (isNullOrUndefined(parent.unfilteredChildIds)) {
          parent.unfilteredChildIds = [];
        }
        if ((<any> parent.unfilteredChildIds.map(id => '' + id)).indexOf('' + relationship.relationships.child) === -1) {
          (<any> parent.unfilteredChildIds).push(parseInt(relationship.relationships.child));
        }
        AppGlobal.treeNodes.get(parent.id, parent);
        event = true;
      }
      if (!isNullOrUndefined(child)) {
        if (isNullOrUndefined(child.parentIds)) {
          child.parentIds = [];
        }
        if ((<any> child.parentIds.map(id => '' + id)).indexOf('' + relationship.relationships.parent) === -1) {
          (<any> child.parentIds).push(parseInt(relationship.relationships.parent));
        }
        if (isNullOrUndefined(child.unfilteredParentIds)) {
          child.unfilteredParentIds = [];
        }
        if ((<any> child.unfilteredParentIds.map(id => '' + id)).indexOf('' + relationship.relationships.parent) === -1) {
          (<any> child.unfilteredParentIds).push(parseInt(relationship.relationships.parent));
        }

        this.onTreeRelationshipsSubLevel(child);
        event = true;
      }
      /* Add relationship */
      const treeRelationship = this.coreTransformer.relationshipToTreeRelationship(relationship);
      treeRelationship.modelId = <any> parseInt(treeRelationship.modelId);
      treeRelationshipsWeightMap = treeRelationshipsWeightMap.set(treeRelationship.parentId + '-' + treeRelationship.childId, treeRelationship.weight);
      if (treeRelationships !== undefined) {
        treeRelationships = treeRelationships.set(parseInt('' + relationship.id) as any, treeRelationship);
      }
    });

    /* Update CLP */
    if (this.transferInProgress) {
      this.updateAfterTransfer.colorLabelProvider = true;
    } else {
      this.setColorLabelProviderColors();
    }

    /* Update weight map */
    this.relationshipWeights.next(treeRelationshipsWeightMap);

    if (!isNullOrUndefined(treeRelationships) && event) {
      if (this.transferInProgress) {
        this.updateAfterTransfer.relationships = treeRelationships;
        this.updateAfterTransfer.nodes = true;
      } else {
        this.treeRelationships.next(this.setTRTRelationships(treeRelationships, true));
        this.treeNodes.next(AppGlobal.treeNodes);
      }
    }

    /* Update global filters */
    if (this.globalFilters.size > 0) {
      if (this.transferInProgress) {
        this.updateAfterTransfer.globalFilter = true;
      } else {
        this.updateGlobalFilters();
      }
    }

    /* Check if all has been transferred */
    if (this.isTransferCompleted('relationships')) {
      this.afterTransfer();
    }
  }

  private onTreeRelationshipsSubLevel(treeNode: TreeNode, ids = []) {
    if (ids.indexOf(treeNode.id) !== -1) {
      return;
    }
    ids.push(treeNode.id);
    treeNode.subLevel = this.getMaxSubLevel(treeNode) + 1;
    AppGlobal.treeNodes.get(treeNode.id, treeNode);
    const children = treeNode.children;
    const count = children.length;
    for (let i = 0; i < count; i++) {
      this.onTreeRelationshipsSubLevel(children[i], ids);
    }
  }

  /**
   * Called as the business area has been loaded
   * @param businessArea
   */
  private onBusinessAreaLoaded(businessArea: Businessarea) {
    /* Checksum */
    if (this.businessAreaCheckSum !== this.coreUtilities.getCheckSum(businessArea)) {

      /* Update the unfiltered business area */
      this.businessArea.next(businessArea);
    }
  }

  /**
   * Called as the model has been loaded
   * @param models
   */
  private onModelsLoaded(models: Model[]): string[] {
    const ids = [];
    let map = this.models.getValue();
    if (isNullOrUndefined(map)) {
      map = OrderedMap<string, Model>();
    }
    /* Iterate over the models */
    this.filteredOutModels = [];
    const count = models.length;
    let updated = count === 0;
    for (let i = 0; i < count; i++) {
      /* Get the model */
      const model = models[i];
      /* Set the mcm */
      if (model.type === MODEL_TYPE_MCM) {
        if (this.mcm === undefined) {
          this.mcm = model.id;
          this.mcmModel = model;
        } else if (this.mcmModel.onstrategyradar === 1) {
          this.filteredOutModels.push(model.id);
        } else if (model.onstrategyradar === 1) {
          this.filteredOutModels.push(this.mcm);
          this.mcm = model.id;
          this.mcmModel = model;
        }
      }
      /* Add the id to array */
      ids.push(model.id);
      /* Checksum */
      if (this.modelsCheckSum.get(model.id) !== this.coreUtilities.getCheckSum(model)) {
        /* Set updated to true */
        updated = true;
        /* Update unfiltered map */
        map = map.set(model.id, model);
      }
    }
    if (updated) {
      /* Update the unfiltered models */
      this.models.next(map);
    }
    /* Return the ids */
    return ids;
  }

  /**
   * Called as the data (humanResource, nodes, relationships and activities) have been loaded
   * @param businessAreaId
   * @param data
   */
  private onTreeDataLoaded(businessAreaId: string, data: any[]) {
    const oldBusinessArea = this.lastLoadedBusinessArea !== undefined && this.lastLoadedBusinessArea !== businessAreaId;
    this.lastLoadedBusinessArea = businessAreaId;
    if (data.length === 0 || oldBusinessArea) {
      return;
    }
    /* Tree nodes map */
    let treeNodes = OrderedMap<string, TreeNode>();
    const parentIds = {};
    const childIds = {};

    /* Tree relationships map */
    let treeRelationships = OrderedMap<string, TreeRelationship>();

    /* Tree relationships weight map */
    let treeRelationshipsWeightMap = OrderedMap<string, number>();

    /* Tree models */
    let treeModels = OrderedMap<string, Model>();

    /* Tree human resources */
    let treeHumanResources = OrderedMap<string, CoreHumanResource>();

    /* Tree groups */
    let treeGroups = OrderedMap<string, CoreGroup>();

    /* Tree activities */
    let treeActivities = OrderedMap<string, TreeActivity>();

    /* Role selection */
    let roleSelection: TreeNode[];

    const treeNodeInfo: TreeNode[] = [];
    const count = data.length;
    for (let i = 0; i < count; i++) {

      const datum = data[i];
      switch (datum.internalType) {
        case 'treeBusinessArea':
          if ('' + this.businessAreaId === '' + datum.id) {
            this.businessArea.next(this.coreTransformer.treeToBusinessArea(datum));
            AppGlobal.instanceId = datum.instanceId;
            AppGlobal.businessAreaId = datum.id;
          }
          break;
        case 'treeModel':
          if ('' + this.businessAreaId === '' + datum.businessareaId) {
            /* Transform to Model */
            const treeModel = this.coreTransformer.treeToModel(datum);
            /* Add to node map */
            treeModels = treeModels.set(treeModel.id, treeModel);
          }
            break;
        case 'treeHumanResource':
          /* Transform to Human resource */
          const treeHumanResource = this.coreTransformer.treeToHumanResource(datum);

          if (this.currentUser !== undefined && '' + treeHumanResource.foreign_id === '' + this.currentUser.id) {
            if (this.currentUser.hrId !== undefined) {
              console.warn('More then one human resource has been connected to the user');
            }
            this.currentUser.hrId = '' + treeHumanResource.id;
            this.currentUser.humanResources.push('' + treeHumanResource.id);
          }
          /* Add to node map */
          treeHumanResources = treeHumanResources.set('' + treeHumanResource.id, treeHumanResource);
          break;
        case 'treeGroup':
          /* Transform to Group */
          const treeGroup = this.coreTransformer.treeToGroup(datum);
          /* Add to node map */
          treeGroups = treeGroups.set('' + treeGroup.id, treeGroup);
          break;
        case 'treeActivity':
          /* Transform to Activity */
          const treeActivity = this.coreTransformer.treeActivityToTreeActivity(datum);
          /* Add to activity map */
          treeActivities = treeActivities.set('' + treeActivity.id, treeActivity);
          break;
        case 'treeNode':
          /* Transform to TreeNode */
          const treeNode = this.coreTransformer.treeToTreeNode(datum);
          treeNodeInfo.push(treeNode);
          /* Add to node map */
          parentIds[treeNode.id] = treeNode.parentIds;
          childIds[treeNode.id] = treeNode.childIds;
          treeNodes = treeNodes.set(treeNode.id, treeNode);
          break;
        case 'treeRelationship':
          /* Transform to TreeRelationship */
          const treeRelationship = this.coreTransformer.treeToTreeRelationship(datum);
          /* Add to node map */
          if ('' + treeRelationship.id === '0') {
            treeRelationship.id = UUID.UUID();
          }
          treeRelationships = treeRelationships.set(treeRelationship.id, treeRelationship);
          /* Add to relationship map */
          treeRelationshipsWeightMap = treeRelationshipsWeightMap.set(treeRelationship.parentId + '-' + treeRelationship.childId, treeRelationship.weight);
          break;
        case 'roleSelection':
          if (roleSelection === undefined) {
            roleSelection = [];
          }
          /* Transform to Tree node */
          const roleSelectionNode = this.coreTransformer.treeToTreeNode(datum);
          /* Add to role selection */
          roleSelection.push(roleSelectionNode);
          break;
      }

    }

    AppGlobal.treeNodes = treeNodes;
    AppGlobal.treeActivities = treeActivities;
    AppGlobal.parentsMap = parentIds;
    AppGlobal.childrenMap = childIds;
    AppGlobal.roleSelection.next(roleSelection);

    const models = treeModels.toArray();
    if (models.length > 0) {
      this.onModelsLoaded(models);
    }

    /* First run of updating the observables */
    this.relationshipWeights.next(treeRelationshipsWeightMap);
    this.models.next(treeModels);
    this.humanResources.next(treeHumanResources);
    this.unChangedHumanResources = <OrderedMap<string, CoreHumanResource>> treeHumanResources.map(h => Map(h).toJS());
    this.groups.next(treeGroups);
    this.unChangedGroups = <OrderedMap<string, CoreGroup>> treeGroups.map(g => Map(g).toJS());

    /* Inform the others */
    this.clearCache(true);
    this.treeNodes.next(treeNodes);
    this.treeRelationships.next(this.setTRTRelationships(treeRelationships, true));
    this.treeActivities.next(treeActivities);

    /* Loading */
    this.loading = false;

    /* Global loading */
    if (this.hasGlobalLoading) {
      this.globalLoading.emit(false);
    }

    window.setTimeout(() => AppGlobal.loading.next(false), 0);

    /* Load the global filter */
    this.loadGlobalFilter();

    /* Set custom color label provider */
    this.setCustomColorLabelProviders();

    /* Set color label provider */
    this.setColorLabelProvider(this.selectedColorLabelProvider);
    AppGlobal.treeNodes = AppGlobal.treeNodes.map(treeNode => {
      if (treeNode.modelId !== this.mcm && this.colorLabelProvider !== undefined) {
        /* Add color from color label provider */
        if (!isNullOrUndefined(this.colorLabelProvider)) {
          const colors = this.colorLabelProvider.color(treeNode);
          if (!isNullOrUndefined(colors)) {
            treeNode.colors = colors;
            treeNode.invertedColors = treeNode.colors.map(color => this.coreUtilities.invertColor(color, true));
          }
        }
      }
      return treeNode;
    }) as OrderedMap<string, TreeNode>;
  }

  /**
   * Called as the data (humanResource, nodes, relationships and activities) have been loaded
   * @param data
   * @param standalone
   * @param force
   */
  private onDataLoaded(data: any[], standalone = false, force = false) {
    /* Set data */
    const humanResources: List<HumanResource> = data[0];
    const nodes: List<Node> = data[1];
    const relationships: List<Relationship> = data[2];
    const activities: List<Activity> = data[3];
    const groups: List<Group> = data[4];

    /* Set maps for hierarchy & buckets */
    let parentChildMap = OrderedMap<string, OrderedMap<string, string>>();
    let childParentMap = OrderedMap<string, OrderedMap<string, string>>();
    let bucketMap = Map<string, Map<string, TreeActivity>>();

    let nodesCheckSum = '';
    let nodeTypes = Set<number>();
    let nodesMap = OrderedMap<string, Node>();
    let treeNodesMap = OrderedMap<string, TreeNode>();

    let activitiesCheckSum = '';
    let activitiesMap = OrderedMap<string, Activity>();
    let treeActivitiesMap = OrderedMap<string, TreeActivity>();

    let relationshipsCheckSum = '';
    let relationshipsMap = OrderedMap<string, Relationship>();
    let treeRelationshipsMap = OrderedMap<string, TreeRelationship>();

    let humanResourcesCheckSum = '';
    let humanResourceMap = OrderedMap<string, CoreHumanResource>();

    let groupsCheckSum = '';
    let groupsMap = OrderedMap<string, CoreGroup>();

    /* Activities */
    activities.forEach(activity => {
      const treeActivity = this.coreTransformer.activityToTreeActivity(activity);
      activitiesCheckSum += this.coreUtilities.getCheckSum(activity);
      activitiesMap = activitiesMap.set('' + activity.id, activity);
      treeActivitiesMap = treeActivitiesMap.set('' + activity.id, treeActivity);
      /* Sort into map */
      if (activity.nodebucket) {
        bucketMap = bucketMap.set(activity.relationships.nodedata, (bucketMap.has(activity.relationships.nodedata) ? bucketMap.get(activity.relationships.nodedata) : Map<string, TreeActivity>()).set(activity.id, treeActivity));
      }
    });

    /* Nodes */
    const nodesArray = nodes.toArray();
    let count = nodesArray.length;

    this.selectedColorLabelProvider = this.colorLabelProviderKey.getValue();
    /* Now set the used node types */
    if (this.selectedColorLabelProvider === 'nodetypes') {
      for (let i = 0; i < count; i++) {
        const node = nodesArray[i];
        nodeTypes = nodeTypes.add(node.nodeType);
      }
      this.setColorLabelProvider(this.selectedColorLabelProvider);
    }

    for (let i = 0; i < count; i++) {
      /* Node */
      const node = nodesArray[i];

      /* Add node to map */
      nodesMap = nodesMap.set(node.id, node);

      /* Transform to TreeNode */
      const treeNode = this.coreTransformer.nodeToTreeNode(node);

      /* Add color from color label provider */
      if (!isNullOrUndefined(this.colorLabelProvider)) {
        treeNode.colors = this.colorLabelProvider.color(treeNode);
        treeNode.invertedColors = treeNode.colors.map(color => this.coreUtilities.invertColor(color, true));
      }

      /* Checksum */
      nodesCheckSum += this.coreUtilities.getCheckSum(node);

      /* Add node buckets if related */
      if (bucketMap.has(node.relationships.nodedata)) {
        treeNode.activities = bucketMap.get(node.relationships.nodedata).toArray();
      }
      /* Add TreeNode to map */
      treeNodesMap = treeNodesMap.set(node.id, treeNode);
      /* Set used node type */
      nodeTypes = nodeTypes.add(treeNode.nodeType);
    }

    /* Relationships */
    const relationshipsArray = relationships.toArray();
    count = relationshipsArray.length;

    for (let i = 0; i < count; i++) {

      /* Relationship */
      const relationship = relationshipsArray[i];
      if (!relationship || !treeNodesMap.has(relationship.relationships.parent) || !treeNodesMap.has(relationship.relationships.child)) {
        continue;
      }

      if (!isNullOrUndefined(relationship.deletedAt)) {
        relationshipsMap = relationshipsMap.remove('' + relationship.id);
        treeRelationshipsMap = treeRelationshipsMap.remove('' + relationship.id);
        continue;
      }

      relationshipsCheckSum += this.coreUtilities.getCheckSum(relationship);

      /* Map */
      relationshipsMap = relationshipsMap.set('' + relationship.id, relationship);
      treeRelationshipsMap = treeRelationshipsMap.set('' + relationship.id, this.coreTransformer.relationshipToTreeRelationship(relationship));

      /* Sort into map */
      if (relationship.type === RELATIONSHIP_TYPE_DEFAULT) {
        const parentId = relationship.relationships.parent;
        const childId = relationship.relationships.child;
        parentChildMap = parentChildMap.set(parentId, (parentChildMap.has(parentId) ? parentChildMap.get(parentId) : OrderedMap<string, string>()).set(childId, childId));
        childParentMap = childParentMap.set(childId, (childParentMap.has(childId) ? childParentMap.get(childId) : OrderedMap<string, string>()).set(parentId, parentId));
      }
    }

    /* Human resources */
    humanResources.forEach(humanResource => {
      humanResourceMap = humanResourceMap.set('' + humanResource.id, this.coreTransformer.humanResourceToCoreHumanResource(humanResource));
      humanResourcesCheckSum += this.coreUtilities.getCheckSum(humanResource);
    });

    /* Groups */
    if (!isNullOrUndefined(groups)) {
      groups.forEach(group => {
        groupsMap = groupsMap.set('' + group.id, this.coreTransformer.groupToCoreGroup(group));
        groupsCheckSum += this.coreUtilities.getCheckSum(group);
      });
    }

    /* Hierarchy */
    const filteredTreeNodesMap = <OrderedMap<string, TreeNode>> treeNodesMap.filter(treeNode => {
      return !childParentMap.has(treeNode.id);
    });
    const result = this.setChildren(filteredTreeNodesMap, parentChildMap, treeNodesMap);

    /* If it's a standalone task quit here */
    if (standalone) {
      return result;
    }

    /* Diffs */
    const diffHumanResources = force || humanResourcesCheckSum !== this.humanResourcesCheckSum;
    const diffGroup = force || groupsCheckSum !== this.groupsCheckSum;
    const diffRelationships = force || relationshipsCheckSum !== this.relationshipsCheckSum;
    const diffActivities = force || activitiesCheckSum !== this.activitiesCheckSum;
    const diffNodes = force || nodesCheckSum !== this.nodesCheckSum;

    /* Trigger the events */
    if (diffHumanResources) {
      this.humanResources.next(humanResourceMap);
      this.formService.setHumanResources(humanResourceMap);
    }
    if (diffGroup) {
      this.groups.next(groupsMap);
    }
    if (diffRelationships) {
      this.relationships.next(relationshipsMap);
      this.treeRelationships.next(this.setTRTRelationships(treeRelationshipsMap, true));
    }
    if (diffActivities) {
      this.activities.next(activitiesMap);
      this.treeActivities.next(treeActivitiesMap);
    }
    if (diffNodes) {
      this.nodes.next(nodesMap);
    }
    if (diffNodes || diffRelationships || diffActivities) {
      /* Set the color label provider again */
      const updateClp = !isNullOrUndefined(this.colorLabelProvider) ? this.colorLabelProvider.complex : false;
      this.treeNodes.next(result.treeNodesMap);
      this.hierarchy.next(updateClp ? <OrderedMap<string, TreeNode>> result.treeNodes.map(treeNode => {
        treeNode.colors = this.colorLabelProvider.color(treeNode);
        treeNode.invertedColors = treeNode.colors.map(color => this.coreUtilities.invertColor(color, true));
        return treeNode;
      }) : result.treeNodes);
      /* Update global filters */
      if (this.globalFilters.size > 0) {
        this.updateGlobalFilters();
      }
    }

    /* Set the checksums */
    this.humanResourcesCheckSum = humanResourcesCheckSum;
    this.relationshipsCheckSum = relationshipsCheckSum;
    this.activitiesCheckSum = activitiesCheckSum;
    this.nodesCheckSum = nodesCheckSum;

    /* Global loading */
    this.globalLoading.emit(false);

  }

  /**
   * Set the children for hierarchy
   * @param treeNodes
   * @param parentChildMap
   * @param treeNodesMap
   * @param subLevelMap
   * @param parentTreeNode
   */
  private setChildren(treeNodes: OrderedMap<string, TreeNode>,
                      parentChildMap: OrderedMap<string, OrderedMap<string, string>>,
                      treeNodesMap: OrderedMap<string, TreeNode>,
                      subLevelMap = Map<string, number>(),
                      parentTreeNode?: TreeNode): { treeNodes: OrderedMap<string, TreeNode>, treeNodesMap: OrderedMap<string, TreeNode>, subLevelMap: Map<string, number> } {

    const treeNodesArray = treeNodes.toArray();

    const count = treeNodesArray.length;
    for (let i = 0; i < count; i++) {

      /* Get tree node */
      const treeNode = treeNodesArray[i];

      /* Parent */
      if (!isNullOrUndefined(parentTreeNode) && treeNode.parentIds.indexOf(parentTreeNode.id) === -1) {
        treeNode.parents.push(parentTreeNode);
        treeNode.unfilteredParents.push(parentTreeNode);
        treeNode.parentIds.push(parentTreeNode.id);
      }

      /* Set sub level */
      let subLevel = treeNode.subLevel;
      if (!isNullOrUndefined(parentTreeNode)) {
        let _subLevel = parentTreeNode.subLevel + 1;
        const storedSubLevel = subLevelMap.get(treeNode.id);
        if (!isNullOrUndefined(storedSubLevel) && storedSubLevel > _subLevel) {
          _subLevel = storedSubLevel;
        }
        subLevel = _subLevel;
      }
      subLevelMap = subLevelMap.set(treeNode.id, subLevel);
      treeNode.subLevel = subLevel;

      /* Continue with children */
      if (parentChildMap.has(treeNode.id)) {
        const childrenResult = this.setChildren(<OrderedMap<string, TreeNode>>parentChildMap.get(treeNode.id).map(id => treeNodesMap.get(id)), parentChildMap, treeNodesMap, subLevelMap, treeNode);

        /* Merge the tree nodes map */
        treeNodesMap = treeNodesMap.merge(childrenResult.treeNodesMap);
        treeNode.children = childrenResult.treeNodes.toArray();
        treeNode.unfilteredChildren = childrenResult.treeNodes.toArray();
        subLevelMap = childrenResult.subLevelMap;
      }

      /* Add the node to map */
      treeNodes = treeNodes.set(treeNode.id, treeNode);
      treeNodesMap = treeNodesMap.set(treeNode.id, treeNode);
    }
    /* Return map */
    return { treeNodes: treeNodes, treeNodesMap: treeNodesMap, subLevelMap: subLevelMap };
  }

  public request(method: string, url: string, body: {} = {}, stringify = true, token?: string, responseType: 'json' | 'blob' | 'arraybuffer' | 'text' = 'json', options = {}) {
    return this.backendService.request(method, url, body, stringify, token, responseType, options);
  }

  public gpt(url: string | 'completion' | 'ingested' | 'ingest/file' | 'ingest/text' | 'ingest/delete' | 'autogen' | 'create' | 'session', data: any, options?: any) {
    /* The event emitter to return */
    const eventEmitter = new EventEmitter();

    /* Subscribe to diff */
    this.subscriptionService.add('gpt', this.aiService.diff.filter(diff => diff.response !== undefined).subscribe(diff => {
      eventEmitter.emit(diff.response);
    }));

    /* Do the GPT call */
    switch (url) {
      case 'health':
      case 'completion':
      case 'ingested':
      case 'ingest/text':
      case 'ingest/delete':
      case 'autogen':
      case 'create':
      case 'session':
        this.aiService.gptRequest('POST', url, data, options);
        break;
      default:
        this.aiService.gpt(url, data, options);
    }

    /* Return event emitter */
    return eventEmitter;
  }

  /**
   * Set the color label provider
   * @param colorLabelProvider
   */
  private setColorLabelProvider(colorLabelProvider: string, configuration?) {
    /* Store the key */
    this.colorLabelProviderKey.next(colorLabelProvider);
    /* Set the color label provider */
    this.colorLabelProvider = this.getColorLabelProviderInstance(colorLabelProvider);
    if (!isNullOrUndefined(configuration)) {
      this.colorLabelProvider.modifyConfiguration(configuration);
    }
  }

  /**
   * Get color label provider instance
   * @param colorLabelProviderKey
   * @param colorLabelProviderNode
   */
  public getColorLabelProviderInstance(colorLabelProviderKey: string, colorLabelProviderNode?: TreeNode) {
    const treeNodes = this.treeNodes.getValue();
    if (!isNullOrUndefined(treeNodes) /* || (colorLabelProviderKey === 'structure' && isNullOrUndefined(colorLabelProviderNode)) */) {
      const configurationNode = treeNodes.filter(treeNode => treeNode.nodeType === NODES_TYPE_MODULECONFIGURATION && treeNode.businessarea === this.businessAreaId && treeNode.modelId === this.mcm).first();
      if (!isNullOrUndefined(configurationNode)) {
        if ((colorLabelProviderKey === 'structure' && isNullOrUndefined(colorLabelProviderNode))) {
          const clpNode = configurationNode.children.filter(child => child.nodeType === NODES_TYPE_COLORLABELPROVIDER)[0];
          if (!isNullOrUndefined(clpNode)) {
            this.colorLabelProviderService.setConfigurationNode(clpNode);
          }
        } else {
          const colorLabelProviderNode2 = configurationNode.children.filter(child => child.nodeType === NODES_TYPE_COLORLABELPROVIDER)[0];
          if (!isNullOrUndefined(colorLabelProviderNode2)) {
            this.colorLabelProviderService.setConfigurationNode(colorLabelProviderNode2);
          }
        }
      }
    } else if (!isNullOrUndefined(colorLabelProviderNode)) {
      this.colorLabelProviderService.setConfigurationNode(colorLabelProviderNode);
    }
    /* Set the color label provider */
    switch (colorLabelProviderKey) {
      case 'models':
        return this.colorLabelProviderService.models(this.models.getValue());
      case 'submodels':
        return this.colorLabelProviderService.submodels(this.subModels.getValue());
      case 'subsets':
        return this.colorLabelProviderService.subsets(this.subSets.getValue());
      case 'targetDate':
        return this.colorLabelProviderService.targetDate();
      case 'status':
        return this.colorLabelProviderService.status();
      case 'sidestep':
        return this.colorLabelProviderService.sidestep();
      case 'responsible':
        return this.colorLabelProviderService.responsible(this.humanResources.getValue());
      case 'difference':
        return this.colorLabelProviderService.difference();
      case 'importance':
        return this.colorLabelProviderService.importance();
      case 'risk':
        return this.colorLabelProviderService.risk();
      case 'qstatus.q1':
        return this.colorLabelProviderService.qstatus('executive');
      case 'qstatus.q2':
        return this.colorLabelProviderService.qstatus('program');
      case 'qstatus.q3':
        return this.colorLabelProviderService.qstatus('functional');
      case 'qstatus.q4':
        return this.colorLabelProviderService.qstatus('resource');
      case 'relatedstatus':
        return this.colorLabelProviderService.relatedstatus(this.treeNodes.getValue());
      case 'nodestype':
        return this.colorLabelProviderService.basic();
      // const businessArea = this.businessArea.getValue();
      // return this.colorLabelProviderService.nodestype(this.nodeTypes, this.models.getValue(), this.subModels.getValue(), this.subSets.getValue(),
      //   <OrderedMap<string, CoreHumanResource>>this.humanResources.getValue().filter(humanResource => humanResource.instanceId === businessArea.relationships.instance), this.treeNodes.getValue());
      case 'nodetypes':
        return this.colorLabelProviderService.nodetypes(CoreNodeTypes);
      case 'levels':
        return this.colorLabelProviderService.levels();
      case 'planned':
        return this.colorLabelProviderService.planned();
      case 'projectsavailable':
        return this.colorLabelProviderService.projectsAvailable(this.formService);
      case 'validated':
        return this.colorLabelProviderService.validated();
      case 'positive':
        return this.colorLabelProviderService.positive();
      case 'heatmap':
        return this.colorLabelProviderService.heatmap();
      case 'coloredRelations':
        return this.colorLabelProviderService.coloredRelation();
      case 'statusField':
        return this.colorLabelProviderService.statusField();
      case 'comparison':
        return this.colorLabelProviderService.comparison();
      case 'structure':
        return this.colorLabelProviderService.structure(this);
      case 'grouping':
        return this.colorLabelProviderService.grouping();
      default:
        /* Custom color label providers */
        if (colorLabelProviderKey !== undefined && colorLabelProviderKey.indexOf('custom') !== -1) {
          this.colorLabelProviderService.addConfigurationDispatcher(colorLabelProviderKey);
          return this.colorLabelProviderService.custom(colorLabelProviderKey);
        }
        return this.colorLabelProviderService.basic();
    }
  }

  private getLegend(nodes = this.treeNodes.getValue().toArray()) {
    this.legend = this.colorLabelProvider.legend(nodes).map((legend: CoreLegend) => {
      legend.selected = this.toggledLegends.indexOf(legend.key) === -1;
      return legend;
    });
    return this.legend;
  }

  private createByBusinessArea(transfer: CoreTransfer, finished?: Function, result?: CoreTransferResult, additional?: CoreMultiTransfer) {
    /* Business area */
    this.businessAreaService.diff.filter(d => !!d && d.action === BusinessareaAction.CREATE_SUCCESS && d.payload.data.id === transfer.businessArea.id).take(1).subscribe(diff => {
      transfer.businessAreaId = diff.response.id;
      this.createByModels(transfer, () => {
        if (isNullOrUndefined(result)) {
          result = { businessAreaId: transfer.businessAreaId, modelIds: [], nodeIds: [], relationshipIds: [] };
        } else {
          result.businessAreaId = transfer.businessAreaId;
        }
        if (!isNullOrUndefined(finished)) {
          finished(result);
        }
      }, result, additional);
    });
    if (this.useGo && this.goUpdate) {
      this.businessAreaService.createGo(transfer.instanceId, <IPayload>{ id: transfer.businessArea.id, data: transfer.businessArea });
    } else {
      this.businessAreaService.create(transfer.instanceId, <IPayload>{id: transfer.businessArea.id, data: transfer.businessArea});
    }
  }

  private createByModels(transfer: CoreTransfer, finished?: Function, result?: CoreTransferResult, additional?: CoreMultiTransfer) {
    let finishedCount = 0;
    const count = transfer.models.length;
    /* If it's empty */
    if (count === 0) {
      finished();
    }
    const ids = [];
    /* Subscription */
    this.subscriptionService.add('model', this.modelService.diff.filter(d => !!d && d.action === ModelAction.CREATE_SUCCESS && ids.indexOf(d.payload.data.id) !== -1).subscribe(diff => {
      const modelId = diff.response.id;
      if (transfer.nodes !== undefined && transfer.relationships !== undefined) {
        (<TreeNode[]>transfer.nodes) = (<TreeNode[]>transfer.nodes).map(treeNode => {
          if (treeNode.workFlowModel === diff.payload.data.id) {
            treeNode.workFlowModel = modelId;
          }
          return treeNode;
        });
        const nodes = (<TreeNode[]>transfer.nodes).filter(node => node.modelId === diff.payload.data.id);
        const relationships = (<TreeRelationship[]>transfer.relationships).filter(relationship => relationship.modelId === diff.payload.data.id);
        /* Call the next action */
        this.createNodesAndRelationships(<CoreTransfer>{ nodes: nodes, relationships: relationships, modelId: modelId }, () => {
          if (++finishedCount === count) {
            if (!isNullOrUndefined(finished)) {
              finished();
            }
          }
        }, undefined, additional);
      } else {
        if (++finishedCount === count) {
          if (!isNullOrUndefined(finished)) {
            finished();
          }
        }
      }
    }));

    /* Iterate over models */
    for (let i = 0; i < count; i++) {
      const model = transfer.models[i];
      ids.push(model.id);
      this.modelService.createOnBusinessarea(transfer.businessAreaId, <IPayload>{ id: model.id, data: model });
    }
  }

  private createActivities(transfer: CoreTransfer, finished?: Function, additional?: CoreMultiTransfer) {
    /* Register listener */
    let createCounts = transfer.activities.length;
    this.activityService.diff.filter(diff => !!diff && diff.action === ActivityAction.CREATE_SUCCESS).subscribe(diff => {
      createCounts = createCounts - diff.response.length;
      if (createCounts === 0) {
        if (transfer.nodes.length > 0 || transfer.relationships.length > 0) {
          this.createNodesAndRelationshipsByModel(transfer, finished, additional);
        } else {
          finished();
        }
      }
    });
    /* Transform */
    transfer = this.prepareDataForGoApi(transfer);
    if (transfer.activities.length > 0) {
      this.activityService.createOnNodeDataGo(transfer.activities as Activity[]);
    }
  }

  private createHumanResourcesAndNodes(transfer: CoreTransfer, finished?: Function, continueFn?: Function, additional?: CoreMultiTransfer) {
    /* Register listener */
    let createCounts = transfer.humanResources.length;
    this.subscriptionService.add('create-human-resources-and-nodes-human-resources', this.humanResourceService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>transfer.humanResources, HumanResourceAction.CREATE_SUCCESS)).subscribe(diff => {
      const responseCount = diff.response.length;
      for (let i = 0; i < responseCount; i++) {
        this.createdResponsibles[diff.response[i].attributes.creationId] = parseInt(diff.response[i].id);
      }
      if (--createCounts === 0) {
        this.subscriptionService.remove('create-human-resources-and-nodes-human-resources');
        const nodesCount = transfer.nodes.length;
        if (nodesCount > 0) {
          transfer.nodes = (<any[]>transfer.nodes).map((node: NodeCreate | TreeNode) => {
            for (let i = 0; i < responseCount; i++) {
              if (diff.response[i].attributes.creationId === node.id) {
                if (node instanceof NodeCreate) {
                  node = <NodeCreate>node.set('responsibleId', parseInt(diff.response[0].id));
                } else {
                  node.responsibleId = parseInt(diff.response[0].id);
                }
              }
            }
            return node;
          });
          if (!isNullOrUndefined(continueFn)) {
            continueFn(transfer, finished);
          } else {
            this.createNodesAndRelationshipsByModel(transfer, finished, additional);
          }
        } else {
          finished();
        }
      }
    }));
    this.subscriptionService.add('create-human-resources-and-nodes-human-resources-failed', this.humanResourceService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>transfer.humanResources, HumanResourceAction.CREATE_FAIL)).subscribe(diff => {
      finished();
    }));
    /* Run all create methods */
    const instanceId = this.businessArea.getValue().relationships.instance;
    const count = transfer.humanResources.length;
    for (let i = 0; i < count; i++) {
      const humanResource: any = transfer.humanResources[i];
      if (isNullOrUndefined(humanResource['permissions'])) {
         humanResource['permissions'] = null;
      }
      this.humanResourceService.createOnInstanceGo(instanceId, <IPayload>{ id: humanResource.id, data: humanResource });
    }
  }

  private createGroupsAndNodes(transfer: CoreTransfer, finished?: Function, continueFn?: Function, additional?: CoreMultiTransfer) {
    /* Register listener */
    let createCounts = transfer.groups.length;
    this.subscriptionService.add('create-groups-and-nodes-groups', this.groupService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>transfer.groups, GroupAction.CREATE_SUCCESS)).subscribe(diff => {
      if (--createCounts === 0) {
        this.subscriptionService.remove('create-groups-and-nodes-groups');
        const nodesCount = transfer.nodes.length;
        if (nodesCount > 0) {
          transfer.nodes = (<TreeNode[]>transfer.nodes).map((node: TreeNode) => {
            if (node.id === diff.payload.id) {
              node.groupId = parseInt(diff.response.id);
            }
            return node;
          });
          if (!isNullOrUndefined(continueFn)) {
            continueFn(transfer, finished);
          } else {
            this.createNodesAndRelationshipsByModel(transfer, finished, additional);
          }
        } else {
          finished();
        }
      }
    }));
    this.subscriptionService.add('create-groups-and-nodes-groups-fail', this.groupService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>transfer.groups, GroupAction.CREATE_FAIL)).subscribe(diff => {
      finished();
    }));
    /* Run all create methods */
    const instanceId = this.businessArea.getValue().relationships.instance;
    const count = transfer.groups.length;
    for (let i = 0; i < count; i++) {
      const group = <CoreGroup>transfer.groups[i];
      this.groupService.createOnInstance(instanceId, <IPayload>{ id: '' + group.id, data: group });
    }
  }

  // public createGroup(name: string, humanresources: number[]) {
  //   // this.groupService.createGo()
  //   return new Promise( resolve => {
  //     const instanceId = this.businessArea.getValue().relationships.instance;
  //     const id = UUID.UUID();
  //     const group = <CoreGroup>{
  //       id: '' + id,
  //       name: name,
  //       humanResourcesGo: humanresources
  //     };
  //     this.subscriptionService.add('create-group', this.groupService.diff.filter(diff => {
  //       if (isNullOrUndefined(diff.payload)) {
  //         return false;
  //       }
  //       return diff.action === GroupAction.CREATE_SUCCESS && diff.payload.id === id}).subscribe(diff => {
  //       resolve(diff.response.id);
  //     }));
  //     this.groupService.createOnInstance(instanceId, <IPayload>{ id: '' + group.id, data: group });
  //   })
  //
  // }

  private createHumanResourcesAndGroupsAndNodes(transfer: CoreTransfer, finished?: Function, additional?: CoreMultiTransfer) {
    this.createHumanResourcesAndNodes(transfer, finished, (transfer1, finished1) => {
      this.createGroupsAndNodes(transfer1, finished1, (transfer2, finished2) => {
        this.createNodesAndRelationshipsByModel(transfer2, finished2, additional);
      }, additional);
    }, additional);
  }

  private createGrouping(transfer: CoreTransfer, finished?: Function, continueFn?: Function, additional?: CoreMultiTransfer) {
    /* Register listener */
    this.subscriptionService.add('create-node-structures', this.nodeStructureService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>transfer.nodeStructures, NodeStructureAction.CREATE_SUCCESS)).subscribe(diff => {
      this.subscriptionService.remove('create-node-structures');
      const count = diff.response.length;
      for (let i = 0; i < count; i++) {
        const response = diff.response[i];
        /* Check if there is a relationship where we should convert the ids */
        transfer.relationships = (<any[]> transfer.relationships).map(treeRelationship => {
          if (treeRelationship instanceof RelationshipCreate) {
            if (treeRelationship.parent === response.creationId) {
              treeRelationship  = treeRelationship.set('parent', response.id) as RelationshipCreate;
            }
            if (treeRelationship.child === response.creationId) {
              treeRelationship = treeRelationship.set('child', response.id) as RelationshipCreate;
            }
          } else {
            if (treeRelationship.parentId === response.creationId) {
              treeRelationship.parentId = response.id;
            }
            if (treeRelationship.childId === response.creationId) {
              treeRelationship.childId = response.id;
            }
          }
          return treeRelationship;
        });
      }
      if (transfer.nodes.length > 0 || transfer.relationships.length > 0) {
        if (!isNullOrUndefined(continueFn)) {
          continueFn(transfer, finished);
        } else {
          this.createNodesAndRelationshipsByModel(transfer, finished, additional);
        }
      } else {
        finished();
      }
    }));
    /* Prepare */
    // @ts-ignore
    transfer.nodeStructures = (<TreeNode[]> transfer.nodeStructures).map(treeNode => {
      const nodeGrouping = this.coreTransformer.treeNodeToNodeGrouping(<TreeNode>treeNode);
      transfer.ids.push(treeNode.id);
      return nodeGrouping;
    }) as NodeGrouping[];
    /* Run all create methods */
    this.nodeStructureService.createGo(transfer.modelId, PayloadFactory.fromArray(transfer.nodeStructures));
  }

  private createNodesAndRelationshipsByModel(transfer: CoreTransfer, finished?: Function, additionalTransfer?: CoreMultiTransfer) {
    /* Map the nodes and relationships by model */
    let modelMap = Map<string, CoreTransfer>();
    let count = transfer.nodes.length;
    for (let i = 0; i < count; i++) {
      const nodeCreate = <NodeCreate>(transfer.nodes[i] instanceof NodeCreate ? transfer.nodes[i] : this.coreTransformer.treeNodeToNodeCreate(<TreeNode>transfer.nodes[i]));
      let modelId = transfer.modelId;
      if (!isNullOrUndefined(nodeCreate.modelId) && nodeCreate.modelId !== '0') {
        modelId = nodeCreate.modelId;
      }
      if (!isNullOrUndefined(modelId)) {
        const modelTransfer = modelMap.has('' + modelId) ? modelMap.get('' + modelId) : { nodes: [], relationships: [], modelId: '' + modelId };
        (<NodeCreate[]>modelTransfer.nodes).push(nodeCreate);
        modelMap = modelMap.set('' + modelId, modelTransfer);
      }
      if (this.humanResourceHandling && !isNullOrUndefined(nodeCreate.responsibleId)) {
        /* Search for human resource node */
        const humanResourceTreeNodes: TreeNode[] = [];
        /* Iterate over tree nodes */
        const treeNodes = AppGlobal.treeNodes.toArray();
        const count2 = treeNodes.length;
        for (let i2 = 0; i2 < count2; i2++) {
          const treeNode = treeNodes[i2];
          if ('' + treeNode.modelId === '' + modelId && treeNode.nodeType === NODES_TYPE_HUMANRESOURCE && '' + treeNode.responsibleId === '' + nodeCreate.responsibleId) {
            humanResourceTreeNodes.push(treeNode);
          }
        }
        const count3 = humanResourceTreeNodes.length;
        /* Check the result */
        if (count3 > 0) {
          /* Add the relationship */
          for (let i3 = 0; i3 < count3; i3++) {
            const humanResourceTreeNode = humanResourceTreeNodes[i3];
            /* Create new relationship */
            if (this.humanResourceHandlingParents) {
              (transfer.relationships as TreeRelationship[]).push({ id: UUID.UUID(), parentId: humanResourceTreeNode.id, childId: nodeCreate.id } as TreeRelationship);
            } else {
              (transfer.relationships as TreeRelationship[]).push({ id: UUID.UUID(), parentId: nodeCreate.id, childId: humanResourceTreeNode.id } as TreeRelationship);
            }
          }
        }
      }
    }

    count = transfer.relationships.length;
    for (let i = 0; i < count; i++) {
      const relationshipCreate = <RelationshipCreate>(transfer.relationships[i] instanceof RelationshipCreate ? transfer.relationships[i] : this.coreTransformer.treeRelationshipToRelationshipCreate(<TreeRelationship>transfer.relationships[i]));
      /* Check if one node is human resource */
      if (this.humanResourceHandling) {
        const parentNode = AppGlobal.treeNodes.get(relationshipCreate.parent);
        const childNode = AppGlobal.treeNodes.get(relationshipCreate.child);

        if (parentNode !== undefined && childNode !== undefined) {
          if (parentNode.nodeType === NODES_TYPE_HUMANRESOURCE && childNode.responsibleId !== parentNode.responsibleId) {
            if (additionalTransfer === undefined) {
              additionalTransfer = this.coreUtilities.getTransfer();
            }
            (additionalTransfer.update.nodes as IPayload[]).push({ id: childNode.dataId, data: Map().set('responsibleId', parentNode.responsibleId) });
          }
          if (childNode.nodeType === NODES_TYPE_HUMANRESOURCE && parentNode.responsibleId !== childNode.responsibleId) {
            if (additionalTransfer === undefined) {
              additionalTransfer = this.coreUtilities.getTransfer();
            }
            (additionalTransfer.update.nodes as IPayload[]).push({ id: parentNode.dataId, data: Map().set('responsibleId', childNode.responsibleId) });
          }
        }
      }

      let modelId = transfer.modelId;
      if (!isNullOrUndefined(relationshipCreate.model) && relationshipCreate.model !== '0') {
        modelId = '' + relationshipCreate.model;
      }
      if (!isNullOrUndefined(relationshipCreate.version_id) && relationshipCreate.version_id !== 0) {
        modelId = '' + relationshipCreate.version_id;
      }
      if (!isNullOrUndefined(modelId)) {
        const modelTransfer = modelMap.has('' + modelId) ? modelMap.get('' + modelId) : { nodes: [], relationships: [], modelId: '' + modelId };
        (<RelationshipCreate[]>modelTransfer.relationships).push(relationshipCreate);
        modelMap = modelMap.set('' + modelId, modelTransfer);
      }
    }

    const ids = modelMap.keySeq().toArray();
    /* If it's empty */
    if (ids.length === 0) {
      finished();
    }
    const call = () => {
      /* Get the id */
      const modelId = ids.shift();
      /* Do the call */
      this.createNodesAndRelationships(modelMap.get(modelId), () => {
        if (ids.length === 0) {
          if (additionalTransfer !== undefined) {
            this.transfer(additionalTransfer, true).then(() => {
              finished();
            });
          } else {
            finished();
          }
        } else {
          call();
        }
      });
    };
    call();
  }

  private createNodesAndRelationships(transfer: CoreTransfer, finished?: Function, grouping = false, additional?: CoreMultiTransfer) {
    if (isNullOrUndefined(transfer)) {
      if (!isNullOrUndefined(finished)) {
        finished();
      }
      return;
    }
    this.createNodesAndRelationsByGo(transfer, finished, grouping);
  }

  private createNodesAndRelationsByGo(transfer: CoreTransfer, finished?: Function, grouping = false) {
    /* Prepare the data for calls */
    const prepared = this.prepareDataForGoApi(transfer, grouping);
    if (finished !== undefined) {
      this.relationshipService.diff.filter(diff => diff.action === RelationshipAction.CREATE_SUCCESS || diff.action === RelationshipAction.LOAD_SUCCESS).take(1).subscribe(diff => {
        finished();
      });
    }
    /* Create */
    if (prepared.relationships.length > 0) {
      if (prepared.nodes.length > 0) {
        this.nodesAndRelationshipService.create({ nodes: prepared.nodes, relationships: prepared.relationships, modelId: transfer.modelId });
      } else {
        this.relationshipService.createGo(transfer.modelId, PayloadFactory.fromArray(prepared.relationships));
      }
    } else {
      /* Create the nodes as the first step */
      if (grouping) {
        this.nodeStructureService.create(transfer.modelId, PayloadFactory.fromArray(prepared.nodes));
      } else {
        this.nodeService.createGo(transfer.modelId, PayloadFactory.fromArray(prepared.nodes));
      }
    }
  }

  /**
   * Prepare data for API call
   * @param data
   * @param grouping
   */
  private prepareDataForApi(data: CoreTransfer, grouping = false): { nodes: NodeCreate[], relationships: RelationshipCreate[], ids: string[] } {
    const prepared = { nodes: [], relationships: [], ids: [] };

    if (isNullOrUndefined(data)) {
      return prepared;
    }

    /* Get the tree nodes */
    const treeNodes = this.treeNodes.getValue();

    /* Set the position map as we iterate over the relationships */
    let positionMap = Map<string, number>();

    let count = data.relationships.length;
    for (let i = 0; i < count; i++) {
      const treeRelationship = data.relationships[i];
      if (treeRelationship instanceof RelationshipCreate) {
        prepared.relationships.push(treeRelationship);
      } else {
        /* Check if the parent node is known so we can get the perfect position for a potential child */
        if (!isNullOrUndefined(treeNodes) && treeNodes.has((<TreeRelationship>treeRelationship).parentId)) {
          let positionX: number;
          const parentTreeNode = treeNodes.get((<TreeRelationship>treeRelationship).parentId);
          const childrenCount = parentTreeNode.children.length;
          if (childrenCount > 0) {
            /* If the parent has children search for the node with the highest x position */
            for (let j = 0; j < childrenCount; j++) {
              const child = parentTreeNode.children[0];
              if (isNullOrUndefined(positionX) || child.positionX > positionX) {
                positionX = child.positionX;
              }
            }
          } else {
            /* The parent has no children so we take the node's x position */
            positionX = parentTreeNode.positionX;
          }
          if (!isNullOrUndefined(positionX)) {
            positionMap = positionMap.set((<TreeRelationship>treeRelationship).childId, positionX);
          }
        }

        /* Convert the tree relationship to relationship create */
        prepared.relationships.push(this.coreTransformer.treeRelationshipToRelationshipCreate((<TreeRelationship>treeRelationship)));
      }
    }

    /* Now prepare nodes */
    count = data.nodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode: any = data.nodes[i];
      if (treeNode instanceof NodeCreate) {
        prepared.ids.push(treeNode.id);
        prepared.nodes.push(treeNode);
      } else {
        /* Store the id */
        prepared.ids.push(treeNode.id);

        /* Get the x position */
        if (isNullOrUndefined((<TreeNode>treeNode).positionX) || (<TreeNode>treeNode).positionX === 0) {
          if (positionMap.has(treeNode.id)) {
            (<TreeNode>treeNode).positionX = positionMap.get(treeNode.id);
          } else if (this.positions.has((<TreeNode>treeNode).level)) {
            (<TreeNode>treeNode).positionX = this.positions.get((<TreeNode>treeNode).level).max + this.nodeMargin;
          } else {
            (<TreeNode>treeNode).positionX = this.minX;
          }
        }

        /* Transform the node and add it to array */
        prepared.nodes.push(grouping ? this.coreTransformer.treeNodeToNodeGrouping(<TreeNode>treeNode) : this.coreTransformer.treeNodeToNodeCreate((<TreeNode>treeNode)));
      }
    }

    /* Return */
    return prepared;
  }

  /**
   * Prepare the data for an GO API call
   * @param data
   * @param grouping
   */
  private prepareDataForGoApi(data: CoreTransfer, grouping = false): { nodes: NodeCreate[], relationships: RelationshipCreate[], ids: string[] } {
    const prepared = { nodes: [], relationships: [], activities: [], ids: [] };

    if (isNullOrUndefined(data)) {
      return prepared;
    }

    let count = data.relationships.length;
    for (let i = 0; i < count; i++) {
      let treeRelationship = data.relationships[i];
      if (treeRelationship instanceof RelationshipCreate) {
        if (!isNullOrUndefined(data.modelId)) {
          treeRelationship = <RelationshipCreate> (<RelationshipCreate> treeRelationship).set('version_id', parseInt(data.modelId));
        } else if (!isNullOrUndefined(treeRelationship.model)) {
          treeRelationship = <RelationshipCreate> (<RelationshipCreate> treeRelationship).set('version_id', parseInt(treeRelationship.model));
        }
        prepared.relationships.push(treeRelationship);
      } else {
        if (!isNullOrUndefined(data.modelId)) {
          (<TreeRelationship> treeRelationship).version_id = parseInt(data.modelId);
        } else if (!isNullOrUndefined((<TreeRelationship> treeRelationship).modelId)) {
          (<TreeRelationship> treeRelationship).version_id = parseInt((<TreeRelationship> treeRelationship).modelId);
        }
        /* Convert the tree relationship to relationship create */
        prepared.relationships.push(this.coreTransformer.treeRelationshipToRelationshipCreate((<TreeRelationship>treeRelationship)));
      }
    }



    /* Now prepare nodes */
    count = data.nodes.length;
    for (let i = 0; i < count; i++) {
      let treeNode: any = data.nodes[i];
      if (treeNode instanceof NodeCreate) {
        if (!isNullOrUndefined(treeNode.nodeType) && this.privateNodeTypes.indexOf(treeNode.nodeType) !== -1) {
          if (isNullOrUndefined(this.humanResourcesNodes[data.modelId])) {
            this.getNodes({ onlyMCM: false, ignoreGlobalFilter: true, filters: [{by: 'modelId', value: parseInt(data.modelId)}, {by: 'nodeType', value: NODES_TYPE_HUMANRESOURCE}] }).take(1).subscribe(treeNodes => {
              this.humanResourcesNodes[data.modelId] = treeNodes;
            });
          }
          /* Set the user */
          const responsibleNode = this.humanResourcesNodes[data.modelId].filter(e => '' + e.responsibleId === '' + this.currentUser.hrId)[0];
          if (!isNullOrUndefined(responsibleNode)) {
            prepared.relationships.push((<RelationshipCreate> {id: UUID.UUID(), parent: responsibleNode.id, child: treeNode.id, version_id: parseInt(data.modelId)}));
          }
          treeNode = treeNode.set('private', parseInt(this.currentUser.id));
        }
        if (!isNullOrUndefined(data.modelId)) {
          treeNode = treeNode.set('version_id', parseInt(data.modelId));
        } else if (!isNullOrUndefined(treeNode.modelId)) {
          treeNode = treeNode.set('version_id', parseInt(treeNode.modelId));
        }
        if (treeNode.responsibleId === '' || treeNode.responsibleId === '0' || treeNode.responsibleId === 0 || treeNode.responsibleId === 'null') {
          treeNode = treeNode.set('responsibleId', null);
        }
        if (isString(treeNode.responsibleId)) {
          treeNode = treeNode.set('responsibleId', parseInt('' + treeNode.responsibleId));
        }
        if (isNumber(treeNode.reference)) {
          treeNode = treeNode.set('reference', '' + treeNode.reference);
        }
        if (!isNumber(treeNode.benefitBudget)) {
          treeNode = treeNode.set('benefitBudget', parseFloat(treeNode.benefitBudget));
        }
        if (!isNumber(treeNode.benefitActual)) {
          treeNode = treeNode.set('benefitActual', parseFloat(treeNode.benefitActual));
        }
        if (!isNumber(treeNode.benefitRemaining)) {
          treeNode = treeNode.set('benefitRemaining', parseFloat(treeNode.benefitRemaining));
        }
        if (!isNumber(treeNode.costBudget)) {
          treeNode = treeNode.set('costBudget', parseFloat(treeNode.costBudget));
        }
        if (!isNumber(treeNode.costActual)) {
          treeNode = treeNode.set('costActual', parseFloat(treeNode.costActual));
        }
        if (!isNumber(treeNode.costRemaining)) {
          treeNode = treeNode.set('costRemaining', parseFloat(treeNode.costRemaining));
        }
        if (!isNumber(treeNode.investBudget)) {
          treeNode = treeNode.set('investBudget', parseFloat(treeNode.investBudget));
        }
        if (!isNumber(treeNode.investActual)) {
          treeNode = treeNode.set('investActual', parseFloat(treeNode.investActual));
        }
        if (!isNumber(treeNode.investRemaining)) {
          treeNode = treeNode.set('investRemaining', parseFloat(treeNode.investRemaining));
        }
        if (!isNumber(treeNode.numberField1)) {
          treeNode = treeNode.set('numberField1', parseInt(treeNode.numberField1));
        }
        if (!isNumber(treeNode.numberField2)) {
          treeNode = treeNode.set('numberField2', parseInt(treeNode.numberField2));
        }
        if (!isNumber(treeNode.numberField3)) {
          treeNode = treeNode.set('numberField3', parseInt(treeNode.numberField3));
        }
        if (!isNumber(treeNode.numberField4)) {
          treeNode = treeNode.set('numberField4', parseInt(treeNode.numberField4));
        }
        if (!isNumber(treeNode.numberField5)) {
          treeNode = treeNode.set('numberField5', parseInt(treeNode.numberField5));
        }
        if (!isNumber(treeNode.numberField6)) {
          treeNode = treeNode.set('numberField6', parseInt(treeNode.numberField6));
        }
        if (!isNumber(treeNode.numberField7)) {
          treeNode = treeNode.set('numberField7', parseInt(treeNode.numberField7));
        }
        if (!isNumber(treeNode.numberField8)) {
          treeNode = treeNode.set('numberField8', parseInt(treeNode.numberField8));
        }
        if (!isNumber(treeNode.numberField9)) {
          treeNode = treeNode.set('numberField9', parseInt(treeNode.numberField9));
        }
        if (!isNumber(treeNode.numberField10)) {
          treeNode = treeNode.set('numberField10', parseInt(treeNode.numberField10));
        }
        if (treeNode.targetDate === '') {
          treeNode = treeNode.set('targetDate', null);
        }
        if (treeNode.actualDate === '') {
          treeNode = treeNode.set('actualDate', null);
        }
        if (treeNode.startDate === '') {
          treeNode = treeNode.set('startDate', null);
        }
        if (treeNode.actualStartDate === '') {
          treeNode = treeNode.set('actualStartDate', null);
        }
        if (isObject(treeNode.sync)) {
          treeNode = treeNode.set('sync', JSON.stringify(treeNode.sync));
        }
        /* Get the x position */
        if (isNullOrUndefined(treeNode.positionX) || treeNode.positionX === 0) {
          treeNode = treeNode.set('positionX', this.minX);
        }
        treeNode = treeNode.set('positionX', Math.round(treeNode.positionX));
        /* Get the delta */
        let delta = this.coreUtilities.getDelta(treeNode, new NodeCreate());
        /* Set the level */
        if (!delta.has('level') || isNullOrUndefined(delta.get('level'))) {
          delta = delta.set('level', 0);
        }
        prepared.ids.push(treeNode.id);
        prepared.nodes.push(this.prepareDelta(delta));
      } else {
        if (!isNullOrUndefined(treeNode.nodeType) && this.privateNodeTypes.indexOf(treeNode.nodeType) !== -1) {
          /* Set the user */
          treeNode.private = parseInt(this.currentUser.id);
        }
        if (!isNullOrUndefined(data.modelId)) {
          treeNode.version_id = parseInt(data.modelId);
        } else if (!isNullOrUndefined(treeNode.modelId)) {
          treeNode.version_id = parseInt(treeNode.modelId);
        }
        if (treeNode.responsibleId === '' || treeNode.responsibleId === '0' || treeNode.responsibleId === 0 || treeNode.responsibleId === 'null') {
          treeNode.responsibleId = null;
        }
        if (isNumber(treeNode.reference)) {
          treeNode.reference = '' + treeNode.reference;
        }
        /* Store the id */
        prepared.ids.push(treeNode.id);
        /* Get the x position */
        if (isNullOrUndefined((<TreeNode>treeNode).positionX) || (<TreeNode>treeNode).positionX === 0) {
          (<TreeNode>treeNode).positionX = this.minX;
        }

        const transformed = grouping ? this.coreTransformer.treeNodeToNodeGrouping(<TreeNode>treeNode) : this.coreTransformer.treeNodeToNodeCreate((<TreeNode>treeNode));
        let delta = grouping ? this.coreUtilities.getDelta(transformed, new NodeGrouping()) : this.coreUtilities.getDelta(transformed, new NodeCreate());
        /* Set the level */
        if (!delta.has('level') || isNullOrUndefined(delta.get('level'))) {
          delta = delta.set('level', 0);
        }

        /* Transform the node and add it to array */
        prepared.nodes.push(this.prepareDelta(delta));
      }
    }

    /* Now prepare activities */
    count = data.activities === undefined ? 0 : data.activities.length;
    for (let i = 0; i < count; i++) {
      const datum: any = data.activities[i];
      let activity = datum.data as Activity;
      if (activity.node === undefined || activity.node === null) {
        activity = activity.set('node', datum.id) as Activity;
      }
      activity = activity.remove('id') as Activity;
      if (activity.start !== undefined) {
        activity = activity.set('start', new Datum(activity.start).toGoLang(true)) as Activity;
      }
      if (activity.startActual !== undefined) {
        activity = activity.set('startActual', new Datum(activity.startActual).toGoLang(true)) as Activity;
      }
      if (activity.end !== undefined) {
        activity = activity.set('end', new Datum(activity.end).toGoLang(true)) as Activity;
      }
      if (activity.endActual !== undefined) {
        activity = activity.set('endActual', new Datum(activity.endActual).toGoLang(true)) as Activity;
      }
      if (activity.budget !== undefined && activity.budget !== 0) {
        activity = activity.set('budget', parseFloat('' + activity.budget)) as Activity;
      }
      if (activity.budgetPlan !== undefined && activity.budgetPlan !== 0) {
        activity = activity.set('budgetPlan', parseFloat('' + activity.budgetPlan)) as Activity;
      }
      if (activity.cb_benefit_actual !== undefined && activity.cb_benefit_actual !== 0) {
        activity = activity.set('cb_benefit_actual', parseFloat('' + activity.cb_benefit_actual)) as Activity;
      }
      if (activity.cb_benefit_budget !== undefined && activity.cb_benefit_budget !== 0) {
        activity = activity.set('cb_benefit_budget', parseFloat('' + activity.cb_benefit_budget)) as Activity;
      }
      if (activity.cb_benefit_remaining !== undefined && activity.cb_benefit_remaining !== 0) {
        activity = activity.set('.cb_benefit_remaining ', parseFloat('' + activity.cb_benefit_remaining)) as Activity;
      }
      if (activity.cb_cost_actual !== undefined && activity.cb_cost_actual !== 0) {
        activity = activity.set('cb_cost_actual', parseFloat('' + activity.cb_cost_actual)) as Activity;
      }
      if (activity.cb_cost_remaining !== undefined && activity.cb_cost_remaining !== 0) {
        activity = activity.set('cb_cost_remaining', parseFloat('' + activity.cb_cost_remaining)) as Activity;
      }
      if (activity.cb_cost_budget !== undefined && activity.cb_cost_budget !== 0) {
        activity = activity.set('cb_cost_budget', parseFloat('' + activity.cb_cost_budget)) as Activity;
      }
      if (activity.cb_invest_actual !== undefined && activity.cb_invest_actual !== 0) {
        activity = activity.set('cb_invest_actual', parseFloat('' + activity.cb_invest_actual)) as Activity;
      }
      if (activity.cb_invest_remaining !== undefined && activity.cb_invest_remaining !== 0) {
        activity = activity.set('cb_invest_remaining', parseFloat('' + activity.cb_invest_remaining)) as Activity;
      }
      if (activity.cb_invest_budget !== undefined && activity.cb_invest_budget !== 0) {
        activity = activity.set('cb_invest_budget', parseFloat('' + activity.cb_invest_budget)) as Activity;
      }
      if (activity.benefitActual !== undefined && activity.benefitActual !== 0) {
        activity = activity.set('benefitActual', parseFloat('' + activity.benefitActual)) as Activity;
      }
      if (activity.benefitBudget !== undefined && activity.benefitBudget !== 0) {
        activity = activity.set('benefitBudget', parseFloat('' + activity.benefitBudget)) as Activity;
      }
      if (activity.benefitRemaining !== undefined && activity.benefitRemaining !== 0) {
        activity = activity.set('benefitRemaining', parseFloat('' + activity.benefitRemaining)) as Activity;
      }
      if (activity.costActual !== undefined && activity.costActual !== 0) {
        activity = activity.set('costActual', parseFloat('' + activity.costActual)) as Activity;
      }
      if (activity.costRemaining !== undefined && activity.costRemaining !== 0) {
        activity = activity.set('costRemaining', parseFloat('' + activity.costRemaining)) as Activity;
      }
      if (activity.costBudget !== undefined && activity.costBudget !== 0) {
        activity = activity.set('costBudget', parseFloat('' + activity.costBudget)) as Activity;
      }
      if (activity.investActual !== undefined && activity.investActual !== 0) {
        activity = activity.set('investActual', parseFloat('' + activity.investActual)) as Activity;
      }
      if (activity.investBudget !== undefined && activity.investBudget !== 0) {
        activity = activity.set('investBudget', parseFloat('' + activity.investBudget)) as Activity;
      }
      if (activity.investRemaining !== undefined && activity.investRemaining !== 0) {
        activity = activity.set('investRemaining', parseFloat('' + activity.investRemaining)) as Activity;
      }
      if (activity.crossReference !== undefined) {
        activity = activity.set('cross_reference',  activity.crossReference) as Activity;
      }
      prepared.activities.push(activity);
    }

    /* Return */
    return prepared;
  }

  /**
   * Prepare delta to convert values if needed
   * @param delta
   * @private
   */
  private prepareDelta(delta: Map<string, any>) {
    if (!(delta instanceof Map)) {
      delta = Map(delta);
    }
    return <Map<string, any>> delta.map((value: any, key: string) => {
      switch (key) {
        case 'targetDate':
        case 'actualDate':
        case 'startDate':
        case 'actualStartDate':
          if (!isNullOrUndefined(value)) {
            value = new Datum(value).toGoLang();
          }
          break;
        case 'formFieldSearchable':
          if (value === '') {
            value = 0;
          }
          break;
        case 'responsibleId':
          if (this.createdResponsibles[value] !== undefined) {
            value = this.createdResponsibles[value];
          }
          break;
        case 'nodeType':
          if (isNullOrUndefined(value)) {
            value = 0;
          } else {
            value = parseInt(value);
          }
          break;
        case 'description1':
        case 'description2':
        case 'description3':
        case 'description4':
        case 'applicants':
        case 'costDescription':
        case 'qualityDescription':
        case 'executiveDescription':
        case 'programDescription':
        case 'functionalDescription':
        case 'resourceDescription':
        case 'organizationalDescription':
        case 'technicalDescription':
        case 'timeDescription':
          if (isNullOrUndefined(value) || (!isString(value) && isNaN(value))) {
            value = '';
          }
          break;
        case 'benefitBudget':
        case 'benefitActual':
        case 'benefitRemaining':
        case 'storypoints':
          value = parseFloat(value);
          break;
        case 'bestCase':
          value = value === true ? 1 : 0;
          break;
      }
      return value;
    });
  }

  private filterByColorLabelProvider(treeNode: TreeNode) {
    let visible = true;
    const legends = this.legend.filter(legend => !legend.selected);
    const count = legends.length;
    for (let i = 0; i < count; i++) {
      const legend = legends[i];
      if (visible) {
        switch (legend.field) {
          case 'difference':
            visible = !('' + this.colorLabelProviderService.difference().calculate(treeNode) === '' + legend.value);
            break;
          case 'sidestep':
            visible = !('' + this.colorLabelProviderService.sidestep().calculate(treeNode) === '' + legend.value);
            break;
          case 'targetDate':
            visible = !('' + this.colorLabelProviderService.targetDate().calculate(treeNode) === '' + legend.value);
            break;
          case 'model':
          case 'subModel':
            visible = treeNode.models.indexOf(legend.value) === -1;
            break;
          case 'subSet':
            visible = treeNode.subsets.indexOf(legend.value) === -1;
            break;
          default:
            visible = !(treeNode[legend.field] === legend.value);
        }
      } else {
        break;
      }
    }
    return visible;
  }

  private filterByNodeTypes(treeNode: TreeNode) {
    return this.filteredNodeTypes.indexOf(treeNode.nodeType) === -1;
  }

  private findTopMostNotFiltered(treeNode: TreeNode, filterKey?: string, result?: TreeNode): TreeNode {
    if (!!filterKey ? treeNode.visible[filterKey] : treeNode.visible) {
      result = treeNode;
    }
    if (isNullOrUndefined(result)) {
      const count = treeNode.parents.length;
      for (let i = 0; i < count; i++) {
        result = this.findTopMostNotFiltered(treeNode.parents[i], filterKey, result);
        if (!isNullOrUndefined(result)) {
          break;
        }
      }
    }
    return result;
  }

  private requestWasSuccessful(diff: RequestDiffRecord, payloads: IPayload[] | string[], successAction: string, dataArray?: string) {
    if (isNullOrUndefined(diff.action) || diff.action !== successAction) {
      return false;
    }
    if (!!this.knownRequests[diff.id]) {
      return false;
    }
    this.knownRequests[diff.id] = true;
    let outputIds = [];
    if (!isNullOrUndefined(diff.payload.data)) {
      if (isArray(diff.payload.data)) {
        const count = isNullOrUndefined(diff.payload.data) ? 0 : diff.payload.data.length;
        for (let i = 0; i < count; i++) {
          const datum = diff.payload.data[i];
          outputIds.push(isNullOrUndefined(datum.id) ? datum : datum.id);
        }
      } else if (dataArray !== undefined && diff.payload.data[dataArray] !== undefined) {
        const dd = diff.payload.data[dataArray];
        outputIds = outputIds.concat(dd.map(d => d.get('id')).filter(d => !!d));
      } else {
        outputIds.push(isNullOrUndefined(diff.payload.data.id) ? diff.payload.data : diff.payload.data.id);
      }
    }
    let inputIds = [];
    if (isString(payloads[0])) {
      inputIds = payloads;
    } else {
      const count = payloads.length;
      for (let i = 0; i < count; i++) {
        const datum: any = payloads[i];
        inputIds.push(isNullOrUndefined(datum.id) ? datum : datum.id);
      }
    }
    return outputIds.sort((a, b) => a - b).join('-') === inputIds.sort((a, b) => a - b).join('-');
  }

  private modifyTransferByType(transfer: CoreTransfer, type: number, transferOptions?: TransferOptions) {
    /* Additional transfer */
    // tslint:disable-next-line:prefer-const
    let additionalTransfer: CoreMultiTransfer;
    /* Iterate over nodes */
    const count = transfer.nodes === undefined ? 0 : transfer.nodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = (<TreeNode[]>transfer.nodes)[i];
      /* Switch by type of action */
      switch (type) {
        case NODES_TYPE_ADD:
          break;
        case NODES_TYPE_UPDATE:
          transfer = this.modifyTransferByUpdate(treeNode, transfer, transferOptions);
          break;
        case NODES_TYPE_DELETE:
          transfer = this.modifyTransferByDelete(treeNode, transfer);
          break;
        case NODES_TYPE_CONNECT:
          break;
        case NODES_TYPE_DISCONNECT:
          break;
      }
    }
    if (type === NODES_TYPE_ADD) {
      const mod = this.modifyTransferByCreate(transfer, transferOptions, additionalTransfer);
      transfer = mod.transfer;
      additionalTransfer = mod.additionalTransfer;
    }
    /* Return transfer */
    return { transfer, additionalTransfer };
  }

  private peripheryCreate(transfer: CoreTransfer, peripheriesToUpdate: PeripheryToUpdate[], additionalTransfer: CoreMultiTransfer) {
    const count = peripheriesToUpdate.length;
    for (let i = 0; i < count; i++) {
      const peripheryToCreate = peripheriesToUpdate[i];

      /* Check if it's the correct model */
      if (peripheryToCreate.modelId !== transfer.modelId || peripheryToCreate.distribution) {
        continue;
      }

      /* Target Node Types */
      const lanes = this.coreUtilities.getChildren(peripheryToCreate.traverser.getLanes());
      const targetNodeTypes = Set<number>(lanes.filter(lane => lane.hideWidget === false).map(lane => lane.nodeType));

      /* Payloads */
      let payloads = Map<string, IPayload>();

      /* Transfer value */
      let value: any;

      /* Check the relationships */
      const count2 = transfer.relationships.length;
      for (let i2 = 0; i2 < count2; i2++) {
        const relationship = transfer.relationships[i2] as TreeRelationship;

        /* Get the parent nodes from the create statement */
        let createParentNode: TreeNode;
        let createChildNode: TreeNode;
        const count3 = transfer.nodes.length;
        for (let i3 = 0; i3 < count3; i3++) {
          const node = transfer.nodes[i3] as TreeNode;
          if (node.id === relationship.parentId) {
            createParentNode = node;
          }
          if (node.id === relationship.childId) {
            createChildNode = node;
          }
        }

        /* Get the parent */
        const parentNode = createParentNode !== undefined ? createParentNode : AppGlobal.treeNodes.get(relationship.parentId);
        if (parentNode === undefined) {
          continue;
        }

        /* Get the child */
        const childNode = createChildNode !== undefined ? createChildNode : AppGlobal.treeNodes.get(relationship.childId);
        if (childNode === undefined) {
          continue;
        }

        /* Check if there is a periphery setup */
        if (peripheryToCreate.nodeType !== parentNode.nodeType && peripheryToCreate.nodeType !== childNode.nodeType) {
          continue;
        }

        /* Who is the boss ? */
        const parentIsBoss = parentNode.nodeType === peripheryToCreate.nodeType;
        const bossNode = parentIsBoss ? parentNode : (childNode.nodeType === peripheryToCreate.nodeType ? childNode : undefined);
        const minionNode = parentIsBoss ? childNode : (childNode.nodeType === peripheryToCreate.nodeType ? parentNode : undefined);
        if (bossNode === undefined || minionNode === undefined) {
          continue;
        }

        /* Set the value */
        value = bossNode[peripheryToCreate.field];
        if (value === null) {
          continue;
        }

        /* The target field */
        const targetField = this.getTargetField(peripheryToCreate, bossNode);

        /* Check if we need to update distance 1 */
        if (targetNodeTypes.has(minionNode.nodeType)) {
          const payload = payloads.has(minionNode.dataId) ? payloads.get(minionNode.dataId) : { id: minionNode.dataId, data: Map() } as IPayload;
          payload.data = payload.data.set(targetField, value);
          if (minionNode.dataId !== null && minionNode.dataId !== undefined) {
            payloads = payloads.set(minionNode.dataId, payload);
          }
        }

        /* Update directly in a node to be created */
        if (parentIsBoss && createChildNode !== undefined && targetNodeTypes.has(createChildNode.nodeType)) {
          createChildNode[targetField] = value;
        }
        if (!parentIsBoss && createParentNode !== undefined && targetNodeTypes.has(createParentNode.nodeType)) {
          createParentNode[targetField] = value;
        }

        /* Now run the "normal" traverser to update periphery */
        const traverserNodes = peripheryToCreate.traverser.run([bossNode]).filter(node => node[targetField] !== value);
        const count4 = traverserNodes.length;
        for (let i4 = 0; i4 < count4; i4++) {
          const traverserNode = traverserNodes[i4];
          const payload = payloads.has(traverserNode.dataId) ? payloads.get(traverserNode.dataId) : { id: traverserNode.dataId, data: Map() } as IPayload;
          payload.data = payload.data.set(targetField, value);
          if (traverserNode.dataId !== null && traverserNode.dataId !== undefined) {
            payloads = payloads.set(traverserNode.dataId, payload);
          }
        }

        /* Find the entry of the traverser */
        const entry = lanes.filter(lane => lane.nodeType === minionNode.nodeType)[0];
        if (entry !== undefined) {
          const traverserChildren = new Traverser().addToLanes(entry).setRelationshipWeights(this.relationshipWeights).run([minionNode]);
          const count6 = traverserChildren.length;
          for (let i6 = 0; i6 < count6; i6++) {
            const traverserChild = traverserChildren[i6];
            const payload = payloads.has(traverserChild.dataId) ? payloads.get(traverserChild.dataId) : { id: traverserChild.dataId, data: Map() } as IPayload;
            payload.data = payload.data.set(targetField, value);
            if (traverserChild.dataId !== null && traverserChild.dataId !== undefined) {
              payloads = payloads.set(traverserChild.dataId, payload);
            }
          }
        }
      }

      if (payloads.size > 0) {
        if (additionalTransfer === undefined) {
          additionalTransfer = this.coreUtilities.getTransfer(transfer.modelId);
        }
        additionalTransfer.update.nodes = payloads.toArray();
      }
    }

    return { transfer, additionalTransfer };
  }

  private peripheryUpdate(payload: IPayload, transfer: CoreTransfer, peripheriesToUpdate: PeripheryToUpdate[]) {
    const count = peripheriesToUpdate.length;

    const data = Map(payload.data.toJS());

    let updateMap = Map<string, Map<string, any>>();

    for (let i = 0; i < count; i++) {
      const peripheryToUpdate = peripheriesToUpdate[i];

      /* Check if the field is part of the update */
      if (data.has(peripheryToUpdate.field) || peripheryToUpdate.conditionalTarget !== undefined) {

        /* Get the node structures */
        const nodes = this.getInstantNodes({ filters: [{ by: 'modelId', value: peripheryToUpdate.modelId }] }).filter(treeNode => treeNode.dataId === payload.id);

        /* Run the traverser */
        const traversedNodes = peripheryToUpdate.traverser.run(nodes);

        const count2 = traversedNodes.length;
        for (let i2 = 0; i2 < count2; i2++) {

          /* The target node */
          const traversedNode = traversedNodes[i2];

          /* The target field */
          const targetField = this.getTargetField(peripheryToUpdate, nodes[0], traversedNode);

          /* Update distribution */
          if (peripheryToUpdate.distribution) {

            /* Get the current distribution from traversed node */
            try {

              /* The setup */
              const setup = JSON.parse(traversedNode.sync);

              /* Get the configuration */
              const configuration = peripheryToUpdate.fieldNode.children[0];

              /* Variables */
              let startDateNode: TreeNode;
              let endDateNode: TreeNode;
              let setEndDate = true;
              let holidays = Set<string>();

              /* Set the configuration */
              const children = configuration.children;
              const count3 = children.length;
              for (let i3 = 0; i3 < count3; i3++) {
                const child = children[i3];
                switch (child.nodeType) {
                  case NODES_TYPE_START:
                    startDateNode = child;
                    break;
                  case NODES_TYPE_END:
                    endDateNode = child;
                    setEndDate = !child.hideWidget;
                    break;
                  case NODES_TYPE_IGNORE:
                    const dates = this.coreUtilities.unescapeHtml(child.description).split(',');
                    const holidaysCount = dates.length;
                    for (let ih = 0; ih < holidaysCount; ih++) {
                      holidays = this.distributionComponent.holidays = holidays.add(new Datum(dates[ih]).toDateString());
                    }
                    break;
                }
              }

              if (startDateNode !== undefined && endDateNode !== undefined) {

                /* Check if transfer has start */
                const hasStart = payload.data.has(startDateNode.formFieldId);
                const hasEnd = payload.data.has(endDateNode.formFieldId);

                /* Get the new dates to identify the original diff */
                const hasBeenMoved = hasStart && hasEnd;

                /* Get the minimum date to search for affected nodes */
                const minimumDate = nodes[0][endDateNode.formFieldId];

                /* Get all elements */
                const affected = traversedNode.children.filter(child => {
                  return (child.nodeType === peripheryToUpdate.nodeType) && (minimumDate < child[startDateNode.formFieldId]);
                }).sort((a, b) => this.coreUtilities.sort(a[startDateNode.formFieldId], b[startDateNode.formFieldId], true));
                const affectedCount = affected.length;

                /* New start */
                const newDate = new Datum(payload.data.get(targetField));
                /* Days diff */
                const daysDiff = new Datum(nodes[0][targetField]).daysDiff(newDate);

                /* Alarm */
                let alarm: string;

                /* Check if start or end has been changed */
                if (targetField === startDateNode.formFieldId) {
                  /* Start has been changed */

                  /* End */
                  const diff = new Datum(nodes[0][startDateNode.formFieldId]).daysDiff(new Datum(nodes[0][endDateNode.formFieldId]));

                  /* Set the end date in accordance */
                  // if (setEndDate) {
                  //   (transfer.nodes as IPayload[])[0].data = (transfer.nodes as IPayload[])[0].data.set(endDateNode.formFieldId, newDate.addDays(diff).toISOString(true));
                  // }

                  // let previousOriginalEndDate = new Datum(nodes[0][endDateNode.formFieldId]);
                  // let previousNewEndDate = newDate.addDays(diff);

                  for (let ia = 0; ia < affectedCount; ia++) {
                    /* Affected node */
                    const affectedNode = AppGlobal.treeNodes.get(affected[ia].id);

                    /* Get the data */
                    const startDate = affectedNode[startDateNode.formFieldId];
                    const endDate = affectedNode[endDateNode.formFieldId];

                    const currentDiff = new Datum(startDate).validDaysDiff(new Datum(endDate), (d: Datum) => this.distributionComponent.isDayValid(d, false));
                    /* The diff */
                    const resultDiff = currentDiff - diff;

                    /* Add days to start date  */
                    const start = this.distributionComponent.getValidDay(new Datum(startDate).addDays(daysDiff), false, daysDiff < 0);

                    /* TODO: Currently deactivated as it's not needed in the easy version */
                    /* Check if clocking is kept */
                    // if (previousOriginalEndDate !== undefined && previousNewEndDate !== undefined) {
                    //   let clocking = previousOriginalEndDate.daysDiff(new Datum(startDate));
                    //   if (clocking > 0) {
                    //     clocking -= 1;
                    //   }
                    //   /* Now calculate the actual clocking */
                    //   let actualClocking = previousNewEndDate.daysDiff(start);
                    //   if (actualClocking > 0) {
                    //     actualClocking -= 1;
                    //   }
                    //   if (actualClocking !== clocking) {
                    //     const clockingDaysToAdd = clocking - actualClocking;
                    //     start = this.distributionComponent.getValidDay(start.clone().addDays(clockingDaysToAdd), false, clockingDaysToAdd < 0);
                    //   }
                    // }

                    /* Add days */
                    let addDays = (!setEndDate ? resultDiff : resultDiff - 1) - 1;
                    if (resultDiff === 0 || addDays > currentDiff || hasBeenMoved) {
                      addDays = currentDiff - 1;
                    }
                    if (!hasBeenMoved && addDays < 0) {
                      addDays = Math.abs(addDays) - 1;
                    }
                    if (hasStart && !hasBeenMoved && currentDiff > addDays && daysDiff < 0) {
                      addDays = currentDiff - 1;
                    }
                    if (hasStart && !hasBeenMoved && daysDiff > 0 && currentDiff === 0) {
                      addDays = 0;
                    }
                    if (daysDiff < 0 && !hasBeenMoved) {
                      addDays += 1;
                    }

                    /* Add valid days */
                    let end = start.clone();
                    for (let a = 0; a < addDays; a++) {
                      end = this.distributionComponent.getValidDay(end.addDays(1), false);
                    }
                    if (end.gt(new Datum(traversedNode[endDateNode.formFieldId]))) {
                      alarm = this.coreUtilities.unescapeHtml(peripheryToUpdate.fieldNode.description);
                    }

                    // /* Set previous */
                    // previousOriginalEndDate = new Datum(endDate);
                    // previousNewEndDate = end;

                    /* Add to transfer */
                    (transfer.nodes as IPayload[]).push({ id: affectedNode.dataId, data: Map().set(startDateNode.formFieldId, start.toISOString(true)).set(endDateNode.formFieldId, end.toISOString(true)) } as IPayload);
                  }
                } else if (!hasStart) {
                  /* End has been changed */

                  /* Get the length of the moved element */
                  const startDate = new Datum(nodes[0][startDateNode.formFieldId]);
                  const endDate = new Datum(payload.data.get(targetField));
                  const oldEndDate = new Datum(nodes[0][endDateNode.formFieldId]);
                  const oldDiff = startDate.validDaysDiff(oldEndDate, (d: Datum) => this.distributionComponent.isDayValid(d, false));
                  const diff = startDate.validDaysDiff(endDate, (d: Datum) => this.distributionComponent.isDayValid(d, false));
                  const direction = diff - oldDiff;

                  for (let ia = 0; ia < affectedCount; ia++) {
                    /* Affected node */
                    const affectedNode = AppGlobal.treeNodes.get(affected[ia].id);

                    /* Get the data */
                    const affectedStartDate = new Datum(affectedNode[startDateNode.formFieldId]);
                    const affectedEndDate = new Datum(affectedNode[endDateNode.formFieldId]);
                    let affectedDiff = affectedStartDate.validDaysDiff(affectedEndDate, (d: Datum) => this.distributionComponent.isDayValid(d, false));
                    if (affectedDiff === 0) {
                      affectedDiff = 1;
                    }

                    /* The diff */
                    const resultDiff = direction < 0 ? affectedDiff - diff : diff - affectedDiff;

                    /* Skip if the affected node is shorter then the moved one */
                    if (setEndDate && resultDiff <= 0) {
                      continue;
                    }

                    /* Get the new end date */
                    let addDays = diff - 1;
                    if (daysDiff < 0 && affectedDiff === 0) {
                      addDays = 0;
                    }
                    if (daysDiff > 0 && affectedDiff > addDays) {
                      addDays = affectedDiff - 1;
                    }

                    let end = affectedStartDate.clone();
                    for (let a = 0; a < addDays; a++) {
                      end = this.distributionComponent.getValidDay(end.addDays(1), false);
                    }
                    /* Check if the end date is later then original one  */
                    if (end.gt(new Datum(traversedNode[endDateNode.formFieldId]))) {
                      alarm = this.coreUtilities.unescapeHtml(peripheryToUpdate.fieldNode.description);
                    }

                    /* Add to transfer */
                    (transfer.nodes as IPayload[]).push({ id: affectedNode.dataId, data: Map().set(endDateNode.formFieldId, end.toISOString()) } as IPayload);
                  }
                }

                /* Send alarm */
                if (alarm !== undefined) {
                  this.notificationService.alert(alarm, '');
                }
              }
            } catch (e) {
              console.error(e);
            }
            continue;
          }

          /* Now set the field for the node as well */
          if (payload.data.has(peripheryToUpdate.field)) {
            let up = updateMap.has(traversedNode.dataId) ? updateMap.get(traversedNode.dataId) : Map<string, any>();
            up = up.set(targetField, payload.data.get(peripheryToUpdate.field));
            updateMap = updateMap.set(traversedNode.dataId, up);
          } else {

            /* Get the data conditions */
            const affectedByNodes = this.coreUtilities.unique(peripheryToUpdate.conditionalTarget.children.filter(child => child.nodeType === NODES_TYPE_BY && payload.data.has(child.formFieldId)), 'formFieldId');
            const counta = affectedByNodes.length;
            for (let ia = 0; ia < counta; ia++) {
              const affectedByNode = affectedByNodes[ia];
              const newValue = payload.data.get(affectedByNode.formFieldId);
              const previousField = this.getTargetField(peripheryToUpdate, nodes[0], traversedNode);
              const newField = this.getTargetField(peripheryToUpdate, Map(nodes[0]).set(affectedByNode.formFieldId, newValue).toJS(), traversedNode);
              let up = updateMap.has(traversedNode.dataId) ? updateMap.get(traversedNode.dataId) : Map<string, any>();
              up = up.set(previousField, null);
              up = up.set(newField, nodes[0][peripheryToUpdate.field]);
              updateMap = updateMap.set(traversedNode.dataId, up);
            }
          }
        }
      }
    }

    updateMap.forEach((map, id) => {
      (transfer.nodes as IPayload[]).push({ id, data: map } as IPayload);
    });

    return transfer;
  }

  private getTargetField(peripheryToUpdate: PeripheryToUpdate, parentNode: TreeNode, childNode?: TreeNode) {
    /* Set the target field */
    let targetField = peripheryToUpdate.field;

    /* Check if there is an alternative target field */
    if (peripheryToUpdate.target !== undefined) {
      targetField = peripheryToUpdate.target;
    }

    /* Check if there also conditions */
    if (peripheryToUpdate.conditionalTarget !== undefined) {
      /* Get the by nodes */
      const byNodes = peripheryToUpdate.conditionalTarget.children.filter(child => child.nodeType === NODES_TYPE_BY);
      const useSourceNode = peripheryToUpdate.conditionalTarget.children.filter(child => child.nodeType === NODES_TYPE_CHILD).length === 0;
      const filtersBy = this.getFiltersBy(byNodes, [], false, { modelIds: [peripheryToUpdate.modelId] } as CoreWidget);
      /* Iterate over filters */
      const count3 = filtersBy.filters.length;
      for (let i3 = 0; i3 < count3; i3++) {
        const filter = filtersBy.filters[i3];
        /* Get the source node */
        const sourceNode = filter.child || !useSourceNode ? childNode : parentNode;
        /* Now check if a condition matches and if there are actions involved */
        if (sourceNode !== undefined && filter.functionsNodes !== undefined && this.isNodeVisible(sourceNode, [filter])) {
          /* Run over actions */
          const count4 = filter.functionsNodes.length;
          for (let i4 = 0; i4 < count4; i4++) {
            const functionsNode = filter.functionsNodes[i4];
            switch (functionsNode.nodeType) {
              case NODES_TYPE_FIELD:
                targetField = functionsNode.formFieldId;
                break;
            }
          }
        }

      }

    }

    /* Return */
    return targetField;
  }

  private modifyTransferByUpdate(payload: IPayload, transfer: CoreTransfer, transferOptions?: TransferOptions) {
    /* Transfer Options */

    /* Periphery Updates */
    if (transferOptions !== undefined && transferOptions.peripheryToUpdate !== undefined) {
      transfer = this.peripheryUpdate(payload, transfer, transferOptions.peripheryToUpdate);
    }

    if (this.useTreeNodes) {
      return transfer;
    }
    const nodeData = this.nodes.getValue().filter(node => node.relationships.nodedata === payload.id).first();
    if (!isNullOrUndefined(nodeData)) {
      const nodeType = nodeData.nodeType;
      switch (nodeType) {
        case NODES_TYPE_GROUP:
          const group = this.groups.getValue().get('' + nodeData.groupId);
          if (!isNullOrUndefined(group)) {
            let groupDelta = Map<string, any>();
            payload.data.forEach((value, key) => {
              if (!isNullOrUndefined(group[key]) && group[key] !== value) {
                groupDelta = groupDelta.set(key, value);
              }
            });
            if (groupDelta.size > 0) {
              if (isNullOrUndefined(transfer.groups)) {
                transfer.groups = [];
              }
              (<IPayload[]>transfer.groups).push(<IPayload>{id: '' + group.id, data: groupDelta});
            }
          }
          break;
        case NODES_TYPE_HUMANRESOURCE:
          const humanResource = this.humanResources.getValue().get('' + nodeData.responsibleId);
          if (!isNullOrUndefined(humanResource)) {
            let humanResourceDelta = Map<string, any>();
            payload.data.forEach((value, key) => {
              if (!isNullOrUndefined(humanResource[key]) && humanResource[key] !== value) {
                humanResourceDelta = humanResourceDelta.set(key, value);
                if (key === 'name') {
                  const nameSplit = value.split(' ');
                  const firstName = nameSplit[0];
                  nameSplit.shift();
                  const lastName = nameSplit.join(' ');
                  humanResourceDelta = humanResourceDelta.set('first_name', firstName);
                  humanResourceDelta = humanResourceDelta.set('last_name', lastName);
                }
              }
            });
            if (humanResourceDelta.size > 0) {
              if (isNullOrUndefined(transfer.humanResources)) {
                transfer.humanResources = [];
              }
              (<IPayload[]> transfer.humanResources).push(<IPayload> { id: '' + humanResource.id, data: humanResourceDelta });
            }
          }
          break;
      }
    }

    return transfer;
  }

  private modifyTransferByDelete(treeNode: TreeNode, transfer: CoreTransfer) {
    return transfer;
  }

  private modifyTransferByCreate(transfer: CoreTransfer, transferOptions?: TransferOptions, additionalTransfer?: CoreMultiTransfer) {

    /* Periphery Updates */
    if (transferOptions !== undefined && transferOptions.peripheryToUpdate !== undefined) {
      const mod = this.peripheryCreate(transfer, transferOptions.peripheryToUpdate, additionalTransfer);
      transfer = mod.transfer;
      additionalTransfer = mod.additionalTransfer;
    }

    return { transfer, additionalTransfer };
  }

  public getFiltersBy(byNodes: TreeNode[], filters: CoreFilter[], preFilterHasTraverser: boolean, widget: CoreWidget, sourceNodeType?: number) {
    const byCount = byNodes.length;
    for (let i = 0; i < byCount; i++) {
      const byNode = byNodes[i];
      let by = byNode.formFieldId;
      if (by === '') {
        by = byNode.name;
      }
      let values = [];
      const valueNodes = byNode.children.filter(byChild => (byChild.nodeType === NODES_TYPE_VALUE || byChild.nodeType === NODES_TYPE_TRAVERSER || by === 'id' || by === 'dataId' || by === 'parent' || by === 'child') &&
        byChild.nodeType !== NODES_TYPE_DIRECT_CHAIN && byChild.nodeType !== NODES_TYPE_CHILD && byChild.nodeType !== NODES_TYPE_PARENT);
      const child = byNode.children.filter(byChild => byChild.nodeType === NODES_TYPE_CHILD).length > 0;
      const directChainNode = byNode.children.filter(byChild => byChild.nodeType === NODES_TYPE_DIRECT_CHAIN)[0];
      const directChain = !byNode.formFieldEditable;
      const myNode = byNode.children.filter(byChild => byChild.nodeType === NODES_TYPE_MY)[0];
      const functionsNode = byNode.children.filter(byChild => byChild.nodeType === NODES_TYPE_FUNCTIONS)[0];
      const functionsNodes = functionsNode !== undefined ? functionsNode.children : [];
      const not = byNode.children.filter(byChild => byChild.nodeType === NODES_TYPE_NOT)[0] !== undefined;
      let forcedNot = false;
      const valueCount = valueNodes.length;
      let range = false;
      for (let j = 0; j < valueCount; j++) {
        const valueNode = valueNodes[j];
        let value = valueNode[by];
        if (by === 'targetDate' || by === 'startDate' || by === 'endDate' || by === 'plannedEnddate' || by === 'actualDate' || by === 'actualStartDate' ||  by ===  'creationDate') {
          /* checks if the date is searched by a date-range */
          if (byNode.fullscreen) {
            range = true;
            if (!isNullOrUndefined(valueNode.startDate) && !isNullOrUndefined(valueNode.targetDate)) {
              value = {start: valueNode.startDate, end: valueNode.targetDate};
              values.push(value);
              continue;
            }
            console.warn('you forgot to add start date or planned enddate');
          }
        }
        if (by === 'parent' || by === 'child') {
          value = valueNode.dataId;
        }
        if (by === 'targetDate') {
          value = valueNode.formId;
        }
        if (by === 'partOfTRT') {
          value = valueNode.formId === 'true' || valueNode.formId === '1';
        }
        if (by === 'dynamic-connect') {
          value = {};
          const children = valueNode.children;
          const count = children.length;
          for (let i2 = 0; i2 < count; i2++) {
            const child1 = children[i2];
            value[child1.nodeType] = new Traverser().addToLanes(child1).setRelationshipWeights(this.relationshipWeights);
          }
        }
        if (valueNode.nodeType === NODES_TYPE_TRAVERSER) {
          preFilterHasTraverser = true;
          const groupedNodeDataIds = valueNodes.filter(e => e.nodeType !== NODES_TYPE_TRAVERSER).map(e => e.dataId);
          /* Force the not */
          forcedNot = true;
          /* Set the by to id */
          by = 'id';
          /* Set the value */
          value = [];
          /* Get the first node type as the node types to check for */
          const firstNodeTypes = valueNode.children.map(c => c.nodeType);
          /* Get all nodes and let them run through traverser */
          const treeNodes = (groupedNodeDataIds.length === 0) ? AppGlobal.treeNodes.filter(treeNode => widget.modelIds.indexOf(treeNode.modelId) !== -1 && firstNodeTypes.indexOf(treeNode.nodeType) !== -1).toArray() :
            AppGlobal.treeNodes.filter(treeNode => widget.modelIds.indexOf(treeNode.modelId) !== -1 && groupedNodeDataIds.indexOf(treeNode.dataId) !== -1).toArray();

          if (groupedNodeDataIds.length > 0) {
            value = new Traverser().addToLanes(valueNode.children).setRelationshipWeights(this.getRelationshipWeights()).run(treeNodes).map(e => e.id);
          } else {
            /* Iterate over the tree nodes */
            const count1 = treeNodes.length;
            for (let i1 = 0; i1 < count1; i1++) {
              const treeNode = treeNodes[i1];
              const traverser = new Traverser().addToLanes(valueNode.children).setRelationshipWeights(this.getRelationshipWeights()).run([treeNode]);
              /* Check if the traverser found something */
              if (traverser.length > 0) {
                /* Add the id of the node + direct chain */
                value.push(treeNode.id);
              }
            }
            value = Set(value).toArray();
          }

        }
        /* Check if it's a default value */
        if (new Node()[by] === value && valueNode.formId !== '') {
          value = valueNode.formId;
        }
        if (!isNullOrUndefined(value) && !Array.isArray(value)) {
          values.push(value);
        } else if (!isNullOrUndefined(value)) {
          values = values.concat(value);
        }
        /* 499066: Extension with regular values as well */
        if (by !== '' && value === null && valueNode.formId !== '') {
          values.push(valueNode.formId);
        }
      }
      if (values.length > 0) {
        filters.push(<CoreFilter> { by: by, value: values, functionsNodes, nodeType: sourceNodeType, directChain: directChainNode, range: range, chain: directChain, child: child, not, forcedNot });
      }
      if (!isNullOrUndefined(myNode) && !isNullOrUndefined(this.currentUser)) {
        filters.push(<CoreFilter> { by: by, value: this.currentUser.humanResources, functionsNodes, chain: directChain, child: child, not, forcedNot });
      }
    }
    return { filters, preFilterHasTraverser };
  }

  private setCustomColorLabelProviders() {
    /* 1. Get the module node */
    const moduleNode = AppGlobal.treeNodes.filter(treeNode => treeNode.nodeType === NODES_TYPE_MODULECONFIGURATION && treeNode.modelId === this.mcm).first();
    if (!isNullOrUndefined(moduleNode)) {
      /* 2. Get the color label provider node */
      const colorLabelProviderNode = moduleNode.unfilteredChildren.filter(child => child.nodeType === NODES_TYPE_COLORLABELPROVIDER)[0];
      if (!isNullOrUndefined(colorLabelProviderNode)) {
        /* 2.1 Check if it should kick out all clps */
        if (colorLabelProviderNode.formFieldFilterable) {
          this.defaultColorLabelProviders = this.colorLabelProviders;
          this.colorLabelProviders = [];
        }
        /* 3. Get the custom color label providers */
        const colorLabelProviders = colorLabelProviderNode.unfilteredChildren.filter(child => child.formId === '');
        /* 4. Iterate over providers */
        const count = colorLabelProviders.length;
        for (let i = 0; i < count; i++) {
          const colorLabelProvider = colorLabelProviders[i];
          if (colorLabelProvider.hideWidget) {
            continue;
          }
          /* Get the key */
          const key = !isNullOrUndefined(colorLabelProvider.colorLabelProvider) && colorLabelProvider.colorLabelProvider !== '' ? colorLabelProvider.colorLabelProvider : 'custom-' + colorLabelProvider.id;
          /* 5. Add to the list of color label providers */
          if (this.colorLabelProviders.filter(c => c.key === key).length === 0) {
            this.colorLabelProviders.push(<{ key: string, label: string }> { key: key, label: colorLabelProvider.name });
          }
          /* Add to dispatchers */
          this.colorLabelProviderService.updateConfigurationNode(key, colorLabelProvider);
          /* Update if it's already a custom clp selected */
          const active = this.colorLabelProviderKey.getValue();
          if (!isNullOrUndefined(active) && active.indexOf('custom') !== -1) {
            this.redrawColorLabelProvider();
          }
        }
      }
    }
  }

  public setGlobalFactsheetListener(openFactsheet, closeGlobalFactsheet, scope) {
    this.dashboardScope = scope;
    this.openGlobalFactSheetfunction = openFactsheet;
    this.closeGlobalFactsheetfunction = closeGlobalFactsheet;
  }

  public closeGlobalFactsheet () {
    this.closeGlobalFactsheetfunction.call(this.dashboardScope);
  }

  public openGlobalFactSheet(treeNodes: TreeNode[]) {
    this.openGlobalFactSheetfunction.call(this.dashboardScope, treeNodes);
  }

  /* Create a mapping of the NodeFields from frontend to backend */
  public getFrontToBackMapping() {
    let fieldsMapping = Map<string, string>();
    const count = NodeFields.length;
    for (let i = 1; i < count; i++) {
      const currElmt = NodeFields[i];
      fieldsMapping = fieldsMapping.set(currElmt.value, currElmt.key);
    }
    return fieldsMapping;
  }

  protected storeTransfer(type: string, transfer: CoreTransfer) {
    if (this.lastTransfer === undefined) {
      this.lastTransfer = { create: undefined, update: undefined, delete: undefined };
    }
    this.lastTransfer[type] = transfer;
    this.lastTransferExpectations = this.getTransferExpectations();
  }

  protected isTransferCompleted(from: string) {
    if (this.lastTransfer === undefined) {
      return;
    }
    this.lastTransferExpectations[from]--;
    return this.lastTransferExpectations.nodeStructures <= 0 && this.lastTransferExpectations.nodeData <= 0 && this.lastTransferExpectations.relationships <= 0 && this.lastTransferExpectations.activities <= 0;
  }

  protected getTransferExpectations() {
    const expectations = { nodeStructures: 0, nodeData: 0, relationships: 0, activities: 0 };
    if (this.lastTransfer.create !== undefined) {
      if (this.lastTransfer.create.nodes !== undefined && this.lastTransfer.create.nodes.length > 0) {
        expectations.nodeStructures++;
        expectations.nodeData++;
      }
      if (this.lastTransfer.create.nodeStructures !== undefined && this.lastTransfer.create.nodeStructures.length > 0) {
        expectations.nodeStructures++;
      }
      if (this.lastTransfer.create.relationships !== undefined && this.lastTransfer.create.relationships.length > 0) {
        expectations.relationships++;
      }
      if (this.lastTransfer.create.activities !== undefined && this.lastTransfer.create.activities.length > 0) {
        expectations.activities++;
      }
    }
    if (this.lastTransfer.update !== undefined) {
      if (this.lastTransfer.update.nodes !== undefined && this.lastTransfer.update.nodes.length > 0) {
        expectations.nodeData++;
      }
      if (this.lastTransfer.update.nodeStructures !== undefined && this.lastTransfer.update.nodeStructures.length > 0) {
        expectations.nodeStructures++;
      }
      if (this.lastTransfer.update.relationships !== undefined && this.lastTransfer.update.relationships.length > 0) {
        expectations.relationships++;
      }
      if (this.lastTransfer.update.activities !== undefined && this.lastTransfer.update.activities.length > 0) {
        expectations.activities++;
      }
    }
    if (this.lastTransfer.delete !== undefined) {
      if (this.lastTransfer.delete.nodes !== undefined && this.lastTransfer.delete.nodes.length > 0) {
        expectations.nodeStructures++;
      }
      if (this.lastTransfer.delete.nodeStructures !== undefined && this.lastTransfer.delete.nodeStructures.length > 0) {
        expectations.nodeStructures++;
      }
      if (this.lastTransfer.delete.relationships !== undefined && this.lastTransfer.delete.relationships.length > 0) {
        expectations.relationships++;
      }
      if (this.lastTransfer.delete.activities !== undefined && this.lastTransfer.delete.activities.length > 0) {
        expectations.activities++;
      }
    }
    return expectations;
  }

  protected addTransferExpectations(transfer: CoreMultiTransfer) {
    if (transfer.create !== undefined) {
      if (transfer.create.nodes !== undefined && transfer.create.nodes.length > 0) {
        this.lastTransferExpectations.nodeStructures++;
        this.lastTransferExpectations.nodeData++;
      }
      if (transfer.create.nodeStructures !== undefined && transfer.create.nodeStructures.length > 0) {
        this.lastTransferExpectations.nodeStructures++;
      }
      if (transfer.create.relationships !== undefined && transfer.create.relationships.length > 0) {
        this.lastTransferExpectations.relationships++;
      }
      if (transfer.create.activities !== undefined && transfer.create.activities.length > 0) {
        this.lastTransferExpectations.activities++;
      }
    }
    if (transfer.update !== undefined) {
      if (transfer.update.nodes !== undefined && transfer.update.nodes.length > 0) {
        this.lastTransferExpectations.nodeData++;
      }
      if (transfer.update.nodeStructures !== undefined && transfer.update.nodeStructures.length > 0) {
        this.lastTransferExpectations.nodeStructures++;
      }
      if (transfer.update.relationships !== undefined && transfer.update.relationships.length > 0) {
        this.lastTransferExpectations.relationships++;
      }
      if (transfer.update.activities !== undefined && transfer.update.activities.length > 0) {
        this.lastTransferExpectations.activities++;
      }
    }
    if (transfer.delete !== undefined) {
      if (transfer.delete.nodes !== undefined && transfer.delete.nodes.length > 0) {
        this.lastTransferExpectations.nodeStructures++;
      }
      if (transfer.delete.nodeStructures !== undefined && transfer.delete.nodeStructures.length > 0) {
        this.lastTransferExpectations.nodeStructures++;
      }
      if (transfer.delete.relationships !== undefined && transfer.delete.relationships.length > 0) {
        this.lastTransferExpectations.relationships++;
      }
      if (transfer.delete.activities !== undefined && transfer.delete.activities.length > 0) {
        this.lastTransferExpectations.activities++;
      }
    }
  }

  protected clearTransfer() {}

  public getNodeStructuresFromOriginal(treeNode: TreeNode): TreeNode {
    if (treeNode.originalId !== undefined && treeNode.originalId !== null) {
      const original = this.treeNodes.getValue().get('' + treeNode.originalId);
      if (original !== undefined) {
        return original;
      }
    }
    return treeNode;
  }

}
