import { template } from './template/template';

export const environment = {
  production: true,
  debug: false,
  useGo: true,
  lite: true,
  fast: false,
  isMobile: false,
  isLocal: false,
  template: template,
  apiUrl: 'https://api.valueminer.eu',
  messagingUrl: 'https://app.valueminer.eu/messaging',
  messagingSocketNamespace: 'https://app.valueminer.eu',
  messagingSocketPath: '/socket.io',
  exportUrl: 'https://app.valueminer.eu:8100',
  oauthUrl: 'https://valueminer.eu',
  imageUrl: 'https://valueminer.eu/profile/?id=',
  fileupload: 'https://valueminer.eu',
  go: 'https://app.valueminer.eu:1337',
  goPrefix: {},
  goSuffix: {},
  app: '',
  s3: 'valueminer.local',
  aws: {
    access_key_id: 'AKIAVQ3RHJYALBWTHAN4',
    access_key_secret: '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',
    s3_bucket: 'valueminer.local',
    s3_region: 'eu-central-1',
  },
  auth: {
    authurl: 'https://valueminer.eu/authorize',
    tokenurl: 'https://valueminer.eu/token',
    logouturl: 'https://valueminer.eu/wp-login.php?action=logout',
    authorization: {},
    clientID: 'valueminer',
    mobileClientId: 'mobile',
    redirectURL: 'https://app.valueminer.eu',
    scopes: ['api', 'users']
  },
  socket: {
    url: 'https://app.valueminer.eu:1337',
    path: '/socket.io/'
  },
  design: {
    key: '',
    logo: './assets/svg/logo.green.svg',
    title: 'ValueMiner',
    titleIsImage: false,
    subtitle: '',
    welcomeText: ''
  },
  roleSelection: {
    norole: undefined
  },
  mobileDesign: {}
};
