import { TreeActivity, TreeNode } from '../../core/interface/core.interface';
import { NODES_TYPE_FIELD, NODES_TYPE_VALUE } from '../../shared/api/nodes/nodes.models';
import { FormService } from '../../components/form/service/form.service';
import { Injectable } from '@angular/core';

export class CalculationService {

  public formService: FormService;

  public calculateByConfigurationNode(configurationNode: TreeNode, element: TreeNode | TreeActivity) {
    /* Set the term */
    let term = configurationNode.form_default_value as string;
    /* Variables */
    let variables = {};

    /* Set the variables */
    const variableNodes = configurationNode.children;
    const count = variableNodes.length;
    for (let i = 0; i < count; i++) {

      /* Get the variable node */
      const variableNode = variableNodes[i];

      /* Get variables and terms */
      const variablesAndTerm = this.getVariablesAndTerm(variableNode, element, term);

      /* Merge the variables */
      variables = Object.assign(variables, variablesAndTerm.variable);

      /* Set the term */
      term = variablesAndTerm.term;
    }

    const result = new Function('return `${' + term + '}`;').call(variables);

    /* Do the calculation */
    return isNaN(result) || !isFinite(result) ? 0 : result;
  }

  private getVariablesAndTerm(variableNode: TreeNode, element: TreeNode | TreeActivity, term: string) {
    const variableChild = variableNode.children[0];
    const variable = {};
    switch (variableChild.nodeType) {
      case NODES_TYPE_FIELD:
      case NODES_TYPE_VALUE:
        variable[variableNode.formId] = this.formService.getValue(variableChild, element);
        break;
    }
    const reg = new RegExp(variableNode.formId, 'g');
    term = term.replace(reg, 'this.' + variableNode.formId);
    return { variable, term };
  }

}
