import { NgModule } from '@angular/core';

import { CoreService } from './service/core.service';
import { CoreUtilities } from './utilities/core.utilities';
import { ColorLabelProvider } from '../services/colorlabelprovider/colorlabelprovider.service';
import { SelectionService } from '../services/selection/selection.service';
import { CoreTransformer } from './transformer/core.transformer';
import { FormService } from '../components/form/service/form.service';
import { ExportService } from '../shared/utilities/export-service';
import { EisenhowerService } from '../components/eisenhower/service/eisenhower.service';
import { NotificationsService } from '../components/notifications/service/notifications.service';
import { GPTService } from '../services/gpt/gpt.service';
import { DistributionComponent } from '../components/form/entries/distribution/distribution.component';

@NgModule({
  providers: [
    CoreService,
    CoreUtilities,
    CoreTransformer,
    ColorLabelProvider,
    SelectionService,
    FormService,
    ExportService,
    EisenhowerService,
    NotificationsService,
    GPTService,
    DistributionComponent
  ],
  declarations: [],
  imports: [],
  exports: []
})

export class CoreModule {}
