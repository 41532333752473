import { ControlValueAccessor } from '@angular/forms';
import { EventEmitter, Input, OnChanges, Output } from '@angular/core';

export class EntryAbstract implements OnChanges, ControlValueAccessor {

  @Input() value;
  @Input() fieldDisabled = false;
  @Input() invalid = false;
  @Output() change = new EventEmitter<any>();
  @Output() changed = new EventEmitter<any>();

  private contents: string;

  public propagateChange = (_: any) => {};
  public writeValue(value: any) {
    if (value !== undefined && value !== 'DATASHEET.GENERAL.DIFFERS') {
      this.value = value;
    }
  }
  public registerOnChange(fn) {
    this.propagateChange = fn;
  }
  public registerOnTouched() {}

  public ngOnChanges(changes) {
    if (this.fieldDisabled) { return; }
    if (!!changes) { return; }
    if (changes.value.currentValue !== this.contents) {
      this.propagateChange(changes.value.currentValue);
    }
  }

}
